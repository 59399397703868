import React from "react";
import goodbyeImg from "../../assets/other/goodbye.png";

function Goodbye(props) {
  const name = props.match.params.name;

  return (
    <div
      style={{
        display: "flex",
        height: "100vh",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <img
        alt=""
        src={goodbyeImg}
        style={{ width: "250px", height: "250px", objectFit: "contain" }}
      />
      <h1>{`We're sad to see you go, ${name}...`}</h1>
      <p>But we were glad you were here in the first place.</p>
    </div>
  );
}

export default Goodbye;
