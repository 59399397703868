import React from 'react';
import PropTypes from 'prop-types';
import './LinkDiv.css';

function LinkDiv(props){
    return (
            <p className="shareLink">
                <a>
                    {props.link}
                </a>
            </p>
    );
}

LinkDiv.propTypes = {
    //the link shown 
    link: PropTypes.string.isRequired
}

export default LinkDiv;