import React, { useState, useRef } from "react";
import { Redirect } from "react-router-dom";
import { firestoreConnect } from "react-redux-firebase";
import { connect } from "react-redux";
import { compose } from "redux";
//import components
import AccountSettings from "./Account/AccountSettings";
import BillingSettings from "./Billing/BillingSettings";
//import icons
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import CreditCardIcon from "@material-ui/icons/CreditCard";
//import screens
import LoadingScreen from "../../screens/LoadingScreen/LoadingScreen";
//import styling
import "./SettingsContentContainer.css";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      style={{ marginLeft: "13vw", marginTop: "13vh" }}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: "flex",
    height: "89vh",
  },
  tabs: {
    //borderRight: `1px solid ${theme.palette.divider}`,
    backgroundColor: "white",
    position: "fixed",
    height: "91vh",
    top: "10%",
    borderTop: "15px solid white"
  },
  tab: {
    fontWeight: "600",
    fontFamily: "Epilogue",
    textTransform: "capitalize",
    height: "130px",
  },
}));

function VerticalTabs(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [state, setState] = useState(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  if (!props.auth.uid) return <Redirect to="/login" />;
  else if (!props.firestoreData) {
    return <LoadingScreen />;
  }

  return (
    <div className={classes.root}>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        className={classes.tabs}
        TabIndicatorProps={{ style: { background: "rgb(77, 90, 218)" } }}
      >
        <Tab
          className={classes.tab}
          icon={<AccountCircleIcon />}
          label="Account"
          {...a11yProps(0)}
        />
        <Tab
          className={classes.tab}
          icon={<CreditCardIcon />}
          label="Billing"
          {...a11yProps(1)}
        />
      </Tabs>
      {props.firestoreData && <TabPanel value={value} index={0}>
        <AccountSettings
          user={
            props.firestoreData.users
              ? props.firestoreData.users[props.auth.uid]
              : {}
          }
          hasSubscription={
            props.firestoreData.users
              ? props.firestoreData.users[props.auth.uid].hasSubscription
              : false
          }
          firestoreData={props.firestoreData}
          screenSize="big"
          renderParent={() => setState('')}
        />
      </TabPanel>}
      {props.auth.uid && (
        <TabPanel value={value} index={1}>
          <BillingSettings
            hasSubscription={
              props.firestoreData.users
                ? props.firestoreData.users[props.auth.uid].hasSubscription
                : false
            }
          />
        </TabPanel>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebaseReducer.auth,
    firestoreData: state.firestore.data,
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect((props) => {
    return [{ collection: "users", doc: props.auth.uid }];
  })
)(VerticalTabs);
