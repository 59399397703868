// this reducer is solely to store the meeting ID of the agenda that the admin wants to edit

const initState = null;

const meetingIdReducer = (state = initState, action) => {
    switch(action.type){
        case 'EDITMEETINGID_EDIT':
            return action.payload;
        case 'EDITMEETINGID_RESET':
            return initState;
        default:
            return state;
    }
}

export default meetingIdReducer;