export function switchObjectsInArray(arr, targetIndex, obj){
    const copy = [...arr];
    const copyWithoutObj = copy.filter(arrObj => {
        return arrObj.key !== obj.key;
    });
    copyWithoutObj.splice(targetIndex, 0, obj)
    const newState = copyWithoutObj.map((obj, index) => {
        return {
            key: index,
            title: obj.title,
            description: obj.description,
            duration: obj.duration,
            timestamp: obj.timestamp
        }
    });
    return newState;
}