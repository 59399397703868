import React from "react";
import "./Button.css";
import PropTypes from "prop-types";
import Lottie from "../Lottie/LottieAnimation";
import loadingAnimation from "../../assets/animations/buttonLoading.json";

class FullButton extends React.Component {
  constructor(props) {
    super(props);
    this.getStyles = this.getStyles.bind(this);
  }

  getStyles() {
    if(this.props.disabled) {
      return { backgroundColor: "lightGrey" };
    }
    if (!this.props.handleClick) {
      return { backgroundColor: "lightGrey" };
    } else if (this.props.isFullWidth) {
      return { width: "100%" };
    } else {
      return {};
    }
  }

  render() {
    if(this.props.loading) {
      return (
        <button
        className="fullButton promptButton"
        style={{minWidth: "150px", paddingTop:"5px", paddingBottom:"5px"}}
        onClick={() => {}}
        disabled={this.props.disabled}
      >
        <Lottie lotti={loadingAnimation} height={40} width={40}/>
      </button>
      )
    }
    return (
      <button
        className="fullButton promptButton"
        style={this.getStyles()}
        onClick={this.props.handleClick}
        disabled={this.props.disabled}
      >
        <div className="contentHolder">
          {this.props.srcBefore && (
            <img className="icon-before icon" src={this.props.srcBefore} />
          )}
          {this.props.txt ? this.props.txt : "Click here"}
          {this.props.srcAfter && (
            <img className="icon-after icon" src={this.props.srcAfter} />
          )}
        </div>
      </button>
    );
  }
}

FullButton.propTypes = {
  // the text in the button
  txt: PropTypes.string.isRequired,
  // the onClick function -- enter null if nothing
  handleClick: PropTypes.func.isRequired,
  // the link to the icon before text
  srcBefore: PropTypes.string,
  // the link to the icon after the text
  srcAfter: PropTypes.string,
  // if the button needs to take 100% of the width
  isFullWidth: PropTypes.bool,
  //true if button should be disabled
  disabled: PropTypes.bool,
};

export default FullButton;
