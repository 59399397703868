const initState = new Date();

const agendaDateReducer = (state = initState, action) => {
    switch(action.type){
        case 'AGENDA_DATE_EDIT':
            return action.payload;
        case 'AGENDA_DATE_RESET':
            return initState;
        default:
            return state;
    }
}

export default agendaDateReducer;