import React from "react";
import PropTypes from "prop-types"
//import styling
import "./PromptGridTile.css";
//import components
import Avatar from "../../Avatar/Avatar";
import Tag from "../../Tag/Tag";
//import utils
import { returnFirstName } from '../../../utils/MeetingUtils/meetingUtils';

function PromptGridTile(props) {

  return (
    <div className="promptGridTile">
      <Avatar fullName={props.fullName} src={props.src} />
      <p><b>{returnFirstName(props.fullName)}:</b>  {props.update}</p>
      {props.isNewComment && <Tag txt="New"/>}
    </div>
  );
}

PromptGridTile.propTypes = {
    fullName: PropTypes.string.isRequired,
    update: PropTypes.string.isRequired,
    src: PropTypes.string.isRequired,
    isNewComment: PropTypes.bool,
}

export default PromptGridTile;
