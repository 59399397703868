import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { firestoreConnect } from "react-redux-firebase";
import { connect } from "react-redux";
import { compose } from "redux";
import { makeStyles } from "@material-ui/core/styles";
//import components
import FullButton from "../buttons/FullButton";
import Avatar from "../Avatar/Avatar";
import DropDown from "./DropDown/DropDown";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Drawer from "../NavBar/Drawer/Drawer";

//import styling
import "./NavBar.css";
//import actions
import {
  agendaPointsReset,
  agendaTitleReset,
  agendaDescriptionReset,
  agendaDateReset,
} from "../../actions/newAgendaActions";
import { editMeetingIdReset } from "../../actions/editAgendaAction";
//import assets
import promptLogo from "../../assets/logos/Promptlogo.png";
import plusIcon from "../../assets/icons/plus.png";
const examplePic =
  "https://images.unsplash.com/photo-1514794749374-fb67509dbb7f?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1300&q=80";

const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginLeft: 5,
    marginRight: theme.spacing(2),
  },
}));

function NavBar(props) {
  const [open, setOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const meetingToEditID = useSelector((state) => state.editMeetingId);
  const dispatch = useDispatch();

  const toggleDrawer = (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawerOpen((old) => {
      return !old;
    });
  };

  const classes = useStyles();

  const openDropdown = () => {
    setOpen(!open);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  const history = useHistory();

  const routeChange = () => {
    let path = `create`;
    history.push(path);
  };

  const handleNewMeetingButtonClick = () => {
    // if a meeting to edit is still open in the redux store, first clear that
    if (meetingToEditID) {
      dispatch(agendaPointsReset());
      dispatch(agendaTitleReset());
      dispatch(agendaDescriptionReset());
      dispatch(agendaDateReset());
      dispatch(editMeetingIdReset());
    }

    routeChange();
  };

  const backHome = () => {
    let path = "/";
    history.push(path);
  };

  let hasSubscription;
  let userFullName = "";

  if (props.firestoreData) {
    if (props.firestoreData.users) {
      if (props.firestoreData.users[props.auth.uid]) {
        hasSubscription =
          props.firestoreData.users[`${props.auth.uid}`].hasSubscription;
        userFullName = props.firestoreData.users[`${props.auth.uid}`].userData
          ? props.firestoreData.users[`${props.auth.uid}`].userData.fullName
          : "";
      }
    }
  }

  return (
    <div>
      <nav>
        <IconButton
          edge="start"
          className={classes.menuButton}
          id="hideWhenLarge"
          color="inherit"
          aria-label="menu"
          onClick={toggleDrawer}
        >
          <MenuIcon style={{ color: "rgb(11, 11, 77)" }} />
        </IconButton>
        <a onClick={backHome} className="hideWhenSmall">
          <img
            className="logo"
            src={promptLogo}
            draggable="false"
            alt="prompt logo"
          />
        </a>
        <div className="flexboxAlignCenter hideWhenSmall">
          {props.auth.uid && (
            <Avatar
              fullName={
                props.auth.displayName ? props.auth.displayName : userFullName
              }
              src={props.auth.photoURL}
              handleClick={openDropdown}
              open={open}
              isNavItem={true}
            >
              <DropDown
                handleClickAway={handleClickAway}
                hasSubscription={hasSubscription}
              />
            </Avatar>
          )}
          {props.showNewMeetingButton && (
            <FullButton
              srcBefore={plusIcon}
              txt="New meeting"
              handleClick={handleNewMeetingButtonClick}
            />
          )}
        </div>
      </nav>
      <Drawer
        open={drawerOpen}
        handleClose={toggleDrawer}
        routeToCreator={routeChange}
        routeHome={backHome}
        hasSubscription={hasSubscription}
      />
    </div>
  );
}

NavBar.propTypes = {
  // determines whether the 'create meeting' button is shown or not
  showNewMeetingButton: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    auth: state.firebaseReducer.auth,
    firestoreData: state.firestore.data,
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect((props) => {
    return [{ collection: "users", doc: props.auth.uid }];
  })
)(NavBar);
