import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
//import styling
import "./FilePreview.css";
//import components
import LightMeetingButton from "../buttons/LightMeetingButton";
//import assets
import closeIcon from "../../assets/icons/close_grey.png";
//import utils
import { openInNewTab } from "../../utils/UpdateFilesUtils/UpdateFilesUtils";

//import assets
import ai from "../../assets/fileIcons/ai.png";
import css from "../../assets/fileIcons/css.png";
import csv from "../../assets/fileIcons/csv.png";
import doc from "../../assets/fileIcons/doc.png";
import docx from "../../assets/fileIcons/docx.png";
import eps from "../../assets/fileIcons/eps.png";
import exe from "../../assets/fileIcons/exe.png";
import gif from "../../assets/fileIcons/gif.png";
import html from "../../assets/fileIcons/html.png";
import jpg from "../../assets/fileIcons/jpg.png";
import js from "../../assets/fileIcons/js.png";
import mp3 from "../../assets/fileIcons/mp3.png";
import pdf from "../../assets/fileIcons/pdf.png";
import png from "../../assets/fileIcons/png.png";
import ppt from "../../assets/fileIcons/ppt.png";
import psd from "../../assets/fileIcons/psd.png";
import txt from "../../assets/fileIcons/txt.png";
import xls from "../../assets/fileIcons/xls.png";
import zip from "../../assets/fileIcons/zip.png";

function FilePreview(props) {
  const getExtensionIcon = (extension) => {
    switch (extension) {
      case "png":
        return png;
      case "pdf":
        return pdf;
      case "jpg":
        return jpg;
      case "jpeg":
        return jpg;
      case "ppt":
        return ppt;
      case "pptx":
        return ppt;
      case "mp3":
        return mp3;
      case "txt":
        return txt;
      case "psd":
        return psd;
      case "xls":
        return xls;
      case "xlsx":
        return xls;
      case "zip":
        return zip;
      case "doc":
        return doc;
      case "docx":
        return docx;
      case "ai":
        return ai;
      case "css":
        return css;
      case "csv":
        return csv;
      case "eps":
        return eps;
      case "exe":
        return exe;
      case "html":
        return html;
      case "gif":
        return gif;
      case "js":
        return js;
      default:
        return;
    }
  };

  const imgUrl = useRef();

  const getImgUrl = async () => {
    const link = await props.getImgUrl(props.updateFile);
    imgUrl.current = link;
    console.log(`imgUrl: ${imgUrl.current}`);
  };

  const isImage =
    props.extension === "jpg" ||
    props.extension === "jpeg" ||
    props.extension === "png";
  const showImagePreview = props.isInUpdate && isImage;

  useEffect(() => {
    if (isImage && showImagePreview) {
      getImgUrl();
    }
  }, []);

  if (showImagePreview) {
    return (
      <img
        alt=""
        draggable="false"
        src={imgUrl.current}
        style={{
          margin: "20px 0px 20px 10px",
          maxWidth: "25vw",
          maxHeight: "25vh",
          objectFit: "cover",
          cursor: "pointer",
        }}
        onClick={() => openInNewTab(imgUrl.current)}
      />
    );
  }

  return (
    <div className="filePreviewButton">
      <img alt="" src={getExtensionIcon(props.extension.toLowerCase())} />
      <LightMeetingButton
        txt={props.fileName}
        handleClick={props.handleClick}
      />
      <img
        className="icon"
        src={closeIcon}
        alt=""
        onClick={props.handleDelete}
      />
    </div>
  );
}

FilePreview.propTypes = {
  fileName: PropTypes.string.isRequired,
  extension: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
};

export default FilePreview;
