import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import "./FloatingMeetingSummary.css";
//import components
import FullButton from "../../components/buttons/FullButton";
import FlatButton from "../../components/buttons/FlatButton";
import SuccessSnackbar from "../../components/Snackbar/SuccessSnackbar";
//import assets
import shareIcon from "../../assets/icons/user_plus.png";
import refreshIcon from "../../assets/icons/refresh.png";
import refreshIconDisabled from "../../assets/icons/refresh_disabled.png";
//import actions
import { resetUpdates } from "../../actions/updatesActions";
import { editMeetingIdReset } from "../../actions/editAgendaAction";
import {
  deleteFileTagsReset,
  editUpdatesReset,
  editUpdateTagsReset,
} from "../../actions/editUpdatesActions";

function BottomMeetingSummary(props) {
  const agendaList = useSelector((state) => state.newAgenda);
  const agendaListIsEmpty =
    agendaList.length === 1 && agendaList[0].title === "";
  const meetingToEditID = useSelector((state) => state.editMeetingId);

  const history = useHistory();
  const dispatch = useDispatch();
  const [copied, setCopied] = useState(false);

  const routeBackToAgendaEdit = () => {
    let path = `/agenda/${meetingToEditID}`;
    history.push(path);
  };

  const handleCancel = () => {
    routeBackToAgendaEdit();
    dispatch(editMeetingIdReset());
  };

  const handleResetUpdates = () => {
    dispatch(resetUpdates());
    dispatch(deleteFileTagsReset());
    dispatch(editUpdatesReset());
    dispatch(editUpdateTagsReset());
  };

  const getFullButtonText = () => {
    if (props.isForUpdates) {
      return "Make read-only";
    } else if (meetingToEditID) {
      return "Confirm changes";
    } else {
      return "Create meeting";
    }
  };

  const getFlatButtonValues = () => {
    if (props.isForUpdates) {
      return {
        srcBefore: refreshIcon,
        srcBeforeDisabled: refreshIconDisabled,
        txt: "Reset",
        //change
        handleClick: handleResetUpdates,
        disabled: props.buttonDisabled,
      };
    } else if (meetingToEditID) {
      return {
        srcBefore: null,
        srcBeforeDisabled: null,
        txt: "Cancel",
        handleClick: handleCancel,
        disabled: false,
      };
    } else {
      return {
        srcBefore: refreshIcon,
        srcBeforeDisabled: refreshIconDisabled,
        txt: "Reset",
        handleClick: props.handleReset,
        disabled: agendaListIsEmpty,
      };
    }
  };

  const returnShareLink = () => {
    const mId = props.meetingID || meetingToEditID;
    const shareLink = "https://app.getprompt.io/j/" + mId + "/" + props.pwd;
    return shareLink;
  };

  const link = returnShareLink();

  const handleSnackbarClose = (e, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setCopied(false);
  };

  const showShareLink = props.isForUpdates;
  const dontShowButtons = !props.isAdmin && props.isForUpdates;

  return (
    <div className="bottomMeetingSummary">
      <div className="buttonDiv">
        <div>
          {showShareLink && (
            <FlatButton
              handleClick={() => {
                navigator.clipboard
                  .writeText(link)
                  .catch((err) => console.log(err));
                setCopied(true);
              }}
              srcBefore={shareIcon}
              txt="Share"
              disabled={!showShareLink}
              className="shareAgendaFlatButton"
            />
          )}
        </div>
        {!dontShowButtons && (
          <div>
            {!props.isForUpdates && (
              <FlatButton
                srcBefore={getFlatButtonValues().srcBefore}
                srcBeforeDisabled={getFlatButtonValues().srcBeforeDisabled}
                txt={getFlatButtonValues().txt}
                handleClick={getFlatButtonValues().handleClick}
                disabled={getFlatButtonValues().disabled}
              />
            )}
            <FullButton
              txt={getFullButtonText()}
              handleClick={props.handleSubmit}
              disabled={
                props.isForUpdates ? props.isFinished : agendaListIsEmpty
              }
            />
          </div>
        )}
      </div>
      <SuccessSnackbar
        msg="Link copied!"
        open={copied}
        handleClose={handleSnackbarClose}
      />
    </div>
  );
}

BottomMeetingSummary.propTypes = {
  // this boolean determines whether the functionality is for creating a meeting or for
  // adding updates
  isForUpdates: PropTypes.bool,
  // disable button
  buttonDisabled: PropTypes.bool,
};

export default BottomMeetingSummary;
