import React from "react";
import statusLottie from "../../assets/animations/status.json";
import Lottie from "../../components/Lottie/LottieAnimation";
import LockIcon from "@material-ui/icons/Lock";
import { makeStyles } from "@material-ui/core/styles";



const useStyles = makeStyles((theme) => ({
    root: {
      color: "rgb(11, 11, 77)",
      margin: "0px 5px 5px 8px",
      maxWidth: 20,
      maxHeight: 20
    },
  }));

export default function MeetingStatus(props) {
  const classes = useStyles();
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        marginRight: "3px",
        marginLeft: "-8px",
        marginBottom: "5px",
      }}
    >
      {props.isReadOnly ? (
        <LockIcon className={classes.root}/>
      ) : (
        <Lottie lotti={statusLottie} height={25} width={25} />
      )}
    </div>
  );
}
