const initState = {
    fullName: '',
    email: '',
    imgUrl: '',
    oldPassword: '',
    newPassword: '',
    repeatNewPassword: '',
    companyName: '',
    vatNumber: '',
    companyAddress: '',
  };
  
  const settingsInitStateReducer = (state = initState, action) => {
    switch (action.type) {
      case "SETTINGS_SET_INIT_STATE_REDUCER":
        return action.payload;
      default:
        return state;
    }
  };
  
  export default settingsInitStateReducer;
  