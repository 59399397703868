import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";

//import components
import InputField from "../../components/Input/InputField";
import NumberInputField from "../../components/Input/NumberInputField";
import FlatButton from "../../components/buttons/FlatButton";
import "./AgendaContainers.css";

//import actions
import {
  agendaAdd,
  agendaModifyTitle,
  agendaModifyDescription,
  agendaModifyDuration,
  agendaRemove,
} from "../../actions/newAgendaActions";

function InputContainer(props) {
  const agendaList = useSelector((state) => state.newAgenda);
  const dispatch = useDispatch();
  const id = props.id;
  const isNotEmpty =
    agendaList[id].title !== "" || agendaList[id].description !== "";
  const [isExpanded, setIsExpanded] = useState(isNotEmpty);
  const isFirstItem = id == 0;
  const needsAddButton = isNotEmpty && id === agendaList.length - 1;

  useEffect(() => {
    setIsExpanded(isNotEmpty);
  }, [isNotEmpty]);

  const handleInputChange = (val) => {
    const obj = {
      key: props.id,
      title: val,
    };

    if (val.length > 0) {
      // this is when we need to expand the container
      const isLastInArray = agendaList.length - 1 == obj.key;

      setIsExpanded(true);

      dispatch(agendaModifyTitle(obj));
      if (isLastInArray) {
        dispatch(agendaAdd());
      }
    } else {
      const isSecondToLastInArray = agendaList.length - 2 == obj.key;

      // this is when we need to contract the container
      dispatch(agendaModifyTitle(obj));
      setIsExpanded(false);

      if (agendaList.length > 1) {
        const nextObjectIsEmpty =
          agendaList[id + 1].title === "" &&
          agendaList[id + 1].description === "";
        if (isSecondToLastInArray && nextObjectIsEmpty) {
          dispatch(agendaRemove({ key: obj.key + 1 }));
        }
      }
    }
  };

  const handleDescriptionChange = (val) => {
    const obj = {
      key: props.id,
      description: val,
    };
    dispatch(agendaModifyDescription(obj));
  };

  const handleDurationChange = (val) => {
    const obj = {
      key: props.id,
      duration: val,
    };
    dispatch(agendaModifyDuration(obj));
  };

  const handleAdd = () => {
    dispatch(agendaAdd());
  };

  return (
    <div className="inputContainer">
      <InputField
        placeHolder={
          isFirstItem
            ? "Add your first agenda point..."
            : "Add your next agenda point..."
        }
        handleChange={handleInputChange}
        val={agendaList[id].title}
      />
      {isExpanded && (
        <div>
          <InputField
            placeHolder="Add a description"
            handleChange={handleDescriptionChange}
            val={agendaList[id].description}
          />
          <div className="minutesDiv">
            <p style={{ marginLeft: 10, marginRight: 20}}>
              Duration (minutes)
            </p>
            <div style={{maxWidth: "60%"}}>
            <NumberInputField
              handleChange={handleDurationChange}
              val={agendaList[id].duration}
              id={props.id}
            />
            </div>
          </div>
          <div id="addButtonForBigScreen">
            {needsAddButton && (
              <FlatButton txt="Add agenda item" handleClick={handleAdd} />
            )}
          </div>
          <div id="addButtonForSmallScreen">
            {needsAddButton && <FlatButton txt="Add" handleClick={handleAdd} />}
          </div>
        </div>
      )}
    </div>
  );
}

InputContainer.propTypes = {
  // id of the object in the redux store -- important for implementing dispatch
  id: PropTypes.number.isRequired,
};

export default InputContainer;
