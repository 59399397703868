import React from "react";
import NavBar from "../../../components/NavBar/NavBar";
import Skeleton from "@material-ui/lab/Skeleton";
//import containers
import BottomSummary from "../../../containers/FloatingMeetingSummary/BottomMeetingSummary";
//import styling
import "./AgendaPreview.css";

function AgendaPointSkeleton() {
  return (
    <div>
      <div>
        <Skeleton variant="text" width={250} height={40} />
        <Skeleton variant="text" width={300} />
      </div>
      <div style={{ height: "20px" }}></div>
      <div>
        <Skeleton variant="text" width={400} />
        <Skeleton variant="text" width={200} />
        <Skeleton variant="text" width={250} />
      </div>
    </div>
  );
}

function AgendaPreviewLoadingScreen() {
  return (
    <div className="agendaPreview">
      <NavBar />
      <div className="agendaPreviewDiv">
        <div>
          <div>
            <h5 style={{ marginTop: "0" }}>Give your updates</h5>
            <Skeleton variant="text" width={200} height={50} />
            <Skeleton variant="text" width={300} />
          </div>
          <div style={{ height: "40px" }}></div>
          <AgendaPointSkeleton />
          <div style={{ height: "40px" }}></div>
          <AgendaPointSkeleton />
          <div style={{ height: "40px" }}></div>
          <AgendaPointSkeleton />
        </div>
      </div>
      <BottomSummary
        isForUpdates={true}
        buttonDisabled={true}
        handleSubmit={() => {}}
      />
    </div>
  );
}

export default AgendaPreviewLoadingScreen;
