const initialState = [];

const deletedFileTagsReducer = (state = initialState, action) => {
    switch(action.type){
        case 'ADD_DELETE_TAG_TO_FILE':
            return [
                ...state,
                action.payload,
            ];
        case 'REMOVE_DELETE_TAG_FROM_FILE':
            return state.filter(obj => {
                return obj.timestamp !== action.payload.timestamp;
            });
        case 'DELETE_FILE_TAGS_RESET':
            return initialState;
        default:
            return state;
    }
}

export default deletedFileTagsReducer;