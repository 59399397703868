import { switchObjectsInArray } from '../../utils/newAgendaUtils';

const initialState = [{
    key : 0,
    title: '',
    description: '',
    duration: 5,
    timestamp: new Date()
}];

const newTemplateReducer = (state = initialState, action) => {
    switch(action.type){
        case 'TEMPLATE_ADD':
            return [...state, {
                key: state.length,
                title: '',
                description: '',
                duration: 5,
                timestamp: new Date()
            }];
        case 'TEMPLATE_MODIFY_TITLE':
            return state.map((agendaPointObj) => {
                if(agendaPointObj.key == action.payload.key) {
                    return {
                        key: agendaPointObj.key,
                        title: action.payload.title,
                        description: agendaPointObj.description,
                        duration: agendaPointObj.duration,
                        timestamp: agendaPointObj.timestamp
                    }
                } else {
                    return agendaPointObj;
                }
            });
        case 'TEMPLATE_MODIFY_DESCRIPTION':
            return state.map((agendaPointObj) => {
                if(agendaPointObj.key == action.payload.key) {
                    return {
                        key: agendaPointObj.key,
                        title: agendaPointObj.title,
                        description: action.payload.description,
                        duration: agendaPointObj.duration,
                        timestamp: agendaPointObj.timestamp
                    }
                } else {
                    return agendaPointObj;
                }
            });
        case 'TEMPLATE_MODIFY_DURATION':
            return state.map((agendaPointObj) => {
                if(agendaPointObj.key == action.payload.key) {
                    return {
                        key: agendaPointObj.key,
                        title: agendaPointObj.title,
                        description: agendaPointObj.description,
                        duration: Number(action.payload.duration),
                        timestamp: agendaPointObj.timestamp
                    }
                } else {
                    return agendaPointObj;
                }
            });  
        case 'TEMPLATE_REMOVE':
            return state.filter(obj => {
                return obj.key !== action.payload.key;
            }).map((obj, index) => {
                if(obj.key > action.payload.key) {
                    return {
                        key: obj.key - 1,
                        title: obj.title,
                        description: obj.description,
                        duration: obj.duration,
                        timestamp: obj.timestamp
                    };
                } else {
                    return obj;
                }
            });
        case 'SET_TOPICS': 
            return [...action.payload];
        case 'TEMPLATE_SWITCH':
            return switchObjectsInArray(state, action.payload.targetIndex, action.payload.obj);
        case 'TEMPLATE_RESET':
            return initialState;
        default:
            return state;
    }
}

export default newTemplateReducer;