import React from 'react';
import './Button.css';
import PropTypes from 'prop-types';

class LoginButton extends React.Component {
    constructor(props) {
        super(props);
        this.getStyles = this.getStyles.bind(this);
    }

    getStyles() {
        if(!this.props.handleClick){
            return {backgroundColor: 'lightGrey'};
        } else if (this.props.isFullWidth) {
            return {width: '100%'};
        } else {
            return {};
        }
    }

    render() {

        return (
            <button className="thirdPartyLoginButton promptButton" style={this.getStyles()} onClick={this.props.handleClick}>
                <div className="contentHolder">
                    {this.props.srcBefore && <img className="icon-before icon" src={this.props.srcBefore}/>}
                    {this.props.txt ? this.props.txt : "Click here"}
                    {this.props.srcAfter && <img className="icon-after icon" src={this.props.srcAfter}/>}
                </div>
            </button>
        );
    }
}

LoginButton.propTypes = {
    // the text in the button
    txt: PropTypes.string.isRequired,
    // the onClick function -- enter null if nothing
    handleClick: PropTypes.func.isRequired,
    // the link to the icon before text
    srcBefore: PropTypes.string,
    // the link to the icon after the text
    srcAfter: PropTypes.string,
    // if the button needs to take 100% of the width
    isFullWidth: PropTypes.bool,
}

export default LoginButton;