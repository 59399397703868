import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
//import components
import Input from "../../components/Input/InputField";
import Button from "../../components/buttons/FullButton";
import ThirdPartyButton from "../../components/buttons/ThirdPartyLoginButton";
import Checkbox from "@material-ui/core/Checkbox";
import ErrorSnackbar from "../../components/Snackbar/ErrorSnackbar";
//import styling
import "./LoginContainer.css";
//import actions
import {
  signUp,
  signInWithGoogle,
  signInWithGithub,
} from "../../actions/authActions";
//import assets
import googleLogo from "../../assets/logos/googleLogo.png";
import githubLogo from "../../assets/logos/githubLogo.png";
//import utils
import {
  returnFirstName,
  returnLastName,
} from "../../utils/MeetingUtils/meetingUtils";
import { generateInitials } from "../../utils/avatarUtils";
//import actions
import { resetAuthError } from "../../actions/authActions";

function TermsAndConditionsBox({ checked, handleChange, forgotCheckbox }) {
  return (
    <div style={{ display: "flex", alignItems: "flex-start", marginTop: 15 }}>
      <Checkbox
        checked={checked}
        onChange={handleChange}
        inputProps={{ "aria-label": "primary checkbox" }}
        color="primary"
      />
      <p
        style={{
          fontSize: 12,
          marginTop: 5,
          marginLeft: "auto",
          marginRight: "auto",
          lineHeight: "1.75em",
          maxWidth: "80%",
        }}
      >
        I have read and accepted Prompt's{" "}
        <a
          target="_blank"
          href="https://www.getprompt.io/legal/privacy"
          style={{ textDecoration: "none" }}
        >
          Privacy Policy
        </a>{" "}
        and{" "}
        <a
          target="_blank"
          href="https://www.getprompt.io/legal/terms-of-service"
          style={{ textDecoration: "none" }}
        >
          Terms of Use
        </a>
        .
      </p>
    </div>
  );
}

function SignupContainer({
  authError,
  signUp,
  signInWithGithub,
  signInWithGoogle,
}) {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [repeatedPassword, setRepeatedPassword] = useState("");
  const [fullName, setfullName] = useState("");
  const [err, setErr] = useState("");
  const [checked, setChecked] = useState(false);

  const handleCheckBoxChange = () => {
    setChecked((prev) => !prev);
  };

  const handleEmailChange = (val) => {
    setEmail(val);
  };

  const handlePasswordChange = (val) => {
    setPassword(val);
  };

  const handleRepeatedPasswordChange = (val) => {
    setRepeatedPassword(val);
  };

  const handleNameChange = (val) => {
    setfullName(val);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setErr("");

    if (!checked) {
      setErr(
        "Please agree to our privacy policy and terms and conditions before signing up"
      );
      return;
    }

    if (!fullName) {
      setErr("Signup failed: please provide your name");
    }

    if (returnFirstName(fullName) === returnLastName(fullName)) {
      setErr("Signup failed: please provide your full name");
      return;
    }

    if (password !== repeatedPassword) {
      setErr("Signup failed: passwords are not the same");
      return;
    }

    signUp({
      fullName,
      email,
      password,
      firstName: returnFirstName(fullName),
      lastName: returnLastName(fullName),
      initials: generateInitials(fullName),
    });
  };

  const handleGoogleSignin = (e) => {
    e.preventDefault();

    setErr("");

    if (!checked) {
      setErr(
        "Please agree to our privacy policy and terms and conditions before signing up"
      );
      return;
    }

    signInWithGoogle();
  };

  const handleGithubSignin = (e) => {
    e.preventDefault();

    setErr("");

    if (!checked) {
      setErr(
        "Please agree to our privacy policy and terms and conditions before signing up"
      );
      return;
    }

    signInWithGithub();
  };

  const handleSnackbarClose = (e, reason) => {
    if (reason === "clickaway") {
      return;
    }

    if (err) {
      setErr("");
    }

    if (authError) {
      dispatch(resetAuthError());
    }
  };

  return (
    <div className="loginContainer">
      <ErrorSnackbar open={err} msg={err} handleClose={handleSnackbarClose} />
      <ErrorSnackbar
        open={authError}
        msg={authError}
        handleClose={handleSnackbarClose}
      />
      <form className="loginContainerContent" onSubmit={handleSubmit}>
        <h1>Sign up</h1>

        <div>
          <Input
            placeHolder="Full name"
            handleChange={handleNameChange}
            val={fullName}
          />
          <Input
            inputType="email"
            placeHolder="Email address"
            handleChange={handleEmailChange}
            val={email}
          />
          <Input
            inputType="password"
            placeHolder="Password"
            handleChange={handlePasswordChange}
            val={password}
          />
          <Input
            inputType="password"
            placeHolder="Repeat password"
            handleChange={handleRepeatedPasswordChange}
            val={repeatedPassword}
          />
          <TermsAndConditionsBox
            checked={checked}
            handleChange={handleCheckBoxChange}
          />
          <Button txt="Sign up" handleClick={handleSubmit} isFullWidth={true} />
        </div>
        <p id="or">OR</p>
        <ThirdPartyButton
          srcBefore={googleLogo}
          txt="Continue with Google"
          isFullWidth={true}
          handleClick={handleGoogleSignin}
        />
        <ThirdPartyButton
          srcBefore={githubLogo}
          txt="Continue with GitHub"
          isFullWidth={true}
          handleClick={handleGithubSignin}
        />
      </form>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.authError,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    signUp: (newUser) => dispatch(signUp(newUser)),
    signInWithGithub: () => dispatch(signInWithGithub()),
    signInWithGoogle: () => dispatch(signInWithGoogle()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignupContainer);
