import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Popover from "@material-ui/core/Popover";
import { makeStyles } from "@material-ui/core/styles";
import "./Input.css";
//import assets
import editIcon from "../../assets/icons/edit.png";
import doneIcon from "../../assets/icons/circle_check.png";
const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1),
  },
  popover: {
    pointerEvents: "none",
    margin: 10,
  },
}));

function TitleInput(props) {
  const classes = useStyles();
  const [toggleInput, setToggleInput] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const onChange = async ({ target }) => {
    const name = target.value;

    if (props.handleChange) {
      props.handleChange(name);
    }
  };

  const onSubmit = () => {
    if (toggleInput) {
      setToggleInput(false);
    }
  };

  useEffect(() => {
    window.addEventListener("click", onSubmit);

    return document.body.removeEventListener("click", onSubmit);
  }, []);

  return (
    <div className="titleInput" onDoubleClick={() => setToggleInput(true)}>
      {!toggleInput && (
        <div className="agendaTitleDiv">
          <h1>{props.val ? props.val : "Robin's meeting"}</h1>
          <img
            src={editIcon}
            className="icon"
            onClick={() => {
              setToggleInput(true);
              handlePopoverClose();
            }}
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
          />
          {!toggleInput && (
            <Popover
              id={`mouse-over-popover`}
              className={classes.popover}
              classes={{
                paper: classes.paper,
              }}
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              onClose={handlePopoverClose}
              disableRestoreFocus
            >
              <p style={{ fontSize: "14px", color: "rgb(11, 11, 77)" }}>
                {props.isTemplateTitle
                  ? "Edit template title"
                  : "Edit agenda title"}
              </p>
            </Popover>
          )}
        </div>
      )}
      {toggleInput && (
        <div className="agendaTitleDiv">
          <input
            type="text"
            className="textInput"
            id="titleInputField"
            value={props.val}
            onChange={onChange}
            onSubmit={onSubmit}
            placeholder={props.placeHolder ? props.placeHolder : "Type here"}
          />
          <img
            src={doneIcon}
            className="icon"
            onClick={() => setToggleInput(false)}
          />
        </div>
      )}
    </div>
  );
}

TitleInput.propTypes = {
  // value passed into props, managed by container component
  val: PropTypes.string.isRequired,
  // the placeholder in the input field
  placeHolder: PropTypes.string,
  // function to handle change for e.g. updating parent or siblings
  handleChange: PropTypes.func,
};

export default TitleInput;
