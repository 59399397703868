const initState = {
  settingsSendError: null,
  settingsSendSuccess: null,
};

const settingsSendReducer = (state = initState, action) => {
  switch (action.type) {
    case "SETTINGS_SEND_SUCCESS":
      console.log("Agenda successfully created");
      console.log(action);
      return {
        ...state,
        settingsSendError: null,
        settingsSendSuccess: true,
      };
    case "RESET_SETTINGS_ERROR":
      return {
        ...state,
        settingsSendSuccess: false,
        settingsSendError: null,
      };
    case "SETTINGS_SEND_ERROR":
      return {
        ...state,
        settingsSendSuccess: false,
        settingsSendError: `Action failed. Error: ${action.err.message}`,
      };
    default:
      return state;
  }
};

export default settingsSendReducer;
