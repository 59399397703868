const initState = '';

const agendaDescriptionReducer = (state = initState, action) => {
    switch(action.type){
        case 'AGENDA_DESCRIPTION_EDIT':
            return action.payload;
        case 'AGENDA_DESCRIPTION_RESET':
            return initState;
        default:
            return state;
    }
}

export default agendaDescriptionReducer;