import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFirebase } from "react-redux-firebase";
import { useHistory, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import {
  rememberMeetingDetails,
  resetRememberedMeetingDetails,
} from "../../actions/meetingAccessActions";
import LoadingScreen from "../../screens/LoadingScreen/LoadingScreen";

function JoinContainer(props) {
  const firebase = useFirebase();
  const analytics = firebase.analytics();
  const { meetingID, pwd } = props.match.params;
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const joinUser = async () => {
    try {
      const link =
        "https://europe-west1-prompt-meetings-app.cloudfunctions.net/meetingAccessTokens/join";

      await fetch(link, {
        method: "POST",
        body: JSON.stringify({
          uid: props.auth.uid,
          meetingID,
          pwd,
        }),
      });

      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    joinUser();
    if (!props.auth.uid) {
      dispatch(rememberMeetingDetails(meetingID, pwd));
    } else {
      dispatch(resetRememberedMeetingDetails());
    }
  }, []);

  useEffect(() => {
    analytics.logEvent('joined_meeting');
  }, []);

  if (!props.auth.uid) return <Redirect to={`/login/${meetingID}/${pwd}`} />;

  if (!loading) return <Redirect to={`/agenda/${meetingID}`} />;
  return <LoadingScreen />;
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebaseReducer.auth,
  };
};

export default connect(mapStateToProps)(JoinContainer);
