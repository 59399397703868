//import utils
import {
  returnFirstName,
  returnLastName,
} from "../utils/MeetingUtils/meetingUtils";
import { generateInitials } from "../utils/avatarUtils";

export const signIn = (credentials) => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();

    firebase
      .auth()
      .signInWithEmailAndPassword(credentials.email, credentials.password)
      .then(() => {
        dispatch({ type: "LOGIN_SUCCESS" });
      })
      .catch((err) => {
        dispatch({ type: "LOGIN_ERROR", err });
      });
  };
};

export const signInWithGoogle = () => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    const provider = new firebase.auth.GoogleAuthProvider();

    firebase
      .auth()
      .signInWithPopup(provider)
      .then((res) => {
        return firestore
          .collection("users")
          .doc(res.user.uid)
          .set(
            {
              userData: {
                fullName: res.user.displayName,
                firstName: returnFirstName(res.user.displayName),
                lastName: returnLastName(res.user.displayName),
                initials: generateInitials(res.user.displayName),
                profilePicture: res.user.photoURL,
              },
            },
            { merge: true }
          );
      })
      .then(() => {
        dispatch({ type: "LOGIN_SUCCESS" });
      })
      .catch((err) => {
        dispatch({ type: "LOGIN_ERROR", err });
      });
  };
};

export const signInWithGithub = () => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    const provider = new firebase.auth.GithubAuthProvider();

    firebase
      .auth()
      .signInWithPopup(provider)
      .then((res) => {
        console.log(res);
        return firestore
          .collection("users")
          .doc(res.user.uid)
          .set(
            {
              userData: {
                fullName: res.user.displayName,
                firstName: returnFirstName(res.user.displayName),
                lastName: returnLastName(res.user.displayName),
                initials: generateInitials(res.user.displayName),
                profilePicture: res.user.photoURL,
              },
            },
            { merge: true }
          );
      })
      .then(() => {
        dispatch({ type: "LOGIN_SUCCESS" });
      })
      .catch((err) => {
        dispatch({ type: "LOGIN_ERROR", err });
      });
  };
};

export const signOut = () => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();

    firebase
      .auth()
      .signOut()
      .then(() => {
        dispatch({ type: "SIGNOUT_SUCCESS" });
      });
  };
};

export const signUp = (newUser) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();

    firebase
      .auth()
      .createUserWithEmailAndPassword(newUser.email, newUser.password)
      .then((res) => {
        return firestore
          .collection("users")
          .doc(res.user.uid)
          .set({
            userData: {
              fullName: newUser.fullName,
              firstName: newUser.firstName,
              lastName: newUser.lastName,
              initials: newUser.initials,
            },
            meetings: [],
          });
      })
      .then(() => {
        dispatch({ type: "SIGNUP_SUCCESS" });
      })
      .catch((err) => {
        dispatch({ type: "SIGNUP_ERROR", err });
      });
  };
};

export const resetPassword = (email) => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();

    firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        dispatch({ type: "RESET_PASSWORD_EMAIL_SUCCESS" });
      })
      .catch((err) => {
        dispatch({ type: "RESET_PASSWORD_EMAIL_ERROR", err });
      });
  };
};

export const resetAuthError = () => {
  return {
    type: "RESET_AUTH_ERROR",
  };
}
