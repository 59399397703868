import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, Redirect } from "react-router-dom";
import { useFirebase } from "react-redux-firebase";
import validator from "email-validator";
//import components
import Input from "../../../components/Input/InputField";
import Button from "../../../components/buttons/FullButton";
import WarningAlert from "../../../components/Alert/WarningAlert";
import ErrorAlert from "../../../components/Alert/ErrorAlert";
import Divider from "@material-ui/core/Divider";
import Dialog from "../../../components/Dialog/Dialog";
import SubmitComponent from "../../../components/Settings/SubmitComponent/SubmitComponent";
import Lottie from "../../../components/Lottie/LottieAnimation";
//import styling
import "./AccountSettings.css";
import "../SettingsContentContainer.css";
//import actions
import {
  settingsSetInitState,
  settingsSetFullName,
  settingsSetEmail,
  settingsSetImgUrl,
  settingsSetNewPassword,
  settingsSetRepeatNewPassword,
  settingsSetCompanyName,
  settingsSetVatNumber,
  settingsSetCompanyAddress,
  settingsSubmit,
  resetSettingsError,
  setInitStateReducer,
} from "../../../actions/settingsActions";
//import utils
import { returnFirstName } from "../../../utils/MeetingUtils/meetingUtils";
//import assets
import loadingAnimation from "../../../assets/animations/loading.json";

export default function AccountSettings(props) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const firebase = useFirebase();
  const updateComplete = useSelector(state => state.settingsSend.settingsSendSuccess);
  const [loading, setLoading] = useState(false);
  const [deletionErr, setDeletionErr] = useState("");
  const [submitErr, setSubmitErr] = useState("");
  const firebaseSubmitErr = useSelector(
    (state) => state.settingsSend.settingsSendError
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const auth = useSelector((state) => state.firebaseReducer.auth);
  const user = props.user;
  const companyData = user ? user.companyData : {};
  const loginProviderData = auth.providerData;
  const loginProvider = loginProviderData
    ? loginProviderData[0].providerId
    : null;

  const [image, setImage] = useState(null);

  const handleImgUrlChange = async (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    if(!file) {
      return;
    }
    // do not accept images over 300KB
    if(file.size > 300000) {
      alert('Maximum image size is 300KB');
      return;
    }
    setImage(file);
    console.log(`set image to ${file.name}...`);
    const tempLink = URL.createObjectURL(file);
    dispatch(settingsSetImgUrl(tempLink));
  };

  const resetImage = () => setImage(null);

  const setInitState = () => {
    let imgUrl = "";

    if (auth.photoURL) {
      imgUrl = auth.photoURL;
    } else if (user.userData) {
      if(user.userData.profilePicture) {
        imgUrl = user.userData.profilePicture;
      }
    }

    const initState = {
      fullName: auth.displayName ? auth.displayName : user.userData.fullName,
      email: auth.email ? auth.email : "",
      imgUrl,
      newPassword: "",
      repeatNewPassword: "",
      companyName: companyData ? companyData.companyName : "",
      vatNumber: companyData ? companyData.vatNumber : "",
      companyAddress: companyData ? companyData.companyAddress : "",
    };

    dispatch(settingsSetInitState(initState));

    // we dispatch the action below for comparison to see whether anything changed
    dispatch(setInitStateReducer(initState));
  };

  const deleteAccount = async () => {
    try {
      //close the dialog
      setDialogOpen(false);

      //start loading
      setLoading(true);

      //get first name for goodbye page
      const firstName = auth.displayName
        ? returnFirstName(auth.displayName)
        : user.userData.firstName;
      const path = `/goodbye/${firstName}`;
      const currentUser = firebase.auth().currentUser;

      await currentUser.delete();
      history.push(path);
    } catch (err) {
      setDeletionErr(err.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log('FIRED')
    if (props.user) {
      setInitState();
    }
  }, []);

  const handleFullNameChange = (val) => {
    dispatch(settingsSetFullName(val));
  };

  const handleEmailChange = (val) => {
    dispatch(settingsSetEmail(val));
  };

  const handleNewPasswordChange = (val) => {
    dispatch(settingsSetNewPassword(val));
  };

  const handleRepeatNewPasswordChange = (val) => {
    dispatch(settingsSetRepeatNewPassword(val));
  };

  const handleCompanyNameChange = (val) => {
    dispatch(settingsSetCompanyName(val));
  };

  const handleVatNumberChange = (val) => {
    dispatch(settingsSetVatNumber(val));
  };

  const handleCompanyAddressChange = (val) => {
    dispatch(settingsSetCompanyAddress(val));
  };

  const {
    fullName,
    email,
    imgUrl,
    newPassword,
    repeatNewPassword,
    companyName,
    vatNumber,
    companyAddress,
  } = useSelector((state) => state.settings);

  const settingsState = useSelector((state) => state.settings);
  const settingsInitState = useSelector((state) => state.settingsInitState);

  const disableSubmitButton =
    JSON.stringify(settingsState) === JSON.stringify(settingsInitState);

  const handleSubmit = async (e) => {
    // PLAN OF ACTION:
    // Get img data from local ObjectUrl
    e.preventDefault();
    setLoading(true);
    let img;
    if (imgUrl !== settingsInitState.imgUrl) {
      img = await fetch(imgUrl)
        .then((r) => r.blob())
        .then((blobFile) => new File([blobFile], "profilePicture"));
    }

    //TODO upload image to Firebase Storage if new and retreive link
    const newPasswordIsNotEmpty = newPassword.length > 0;
    const passwordsAreMatching = newPassword === repeatNewPassword;
    const passwordsHaveCorrectLength = newPassword.length > 6;
    console.log("img before if statement:");
    console.log(img);
    // handle profilePic change
    if (img) {
      console.log("Found img! " + img.name);
      console.log("img:");
      console.log(img);
      //first, upload the image to firebase
      const storageRef = firebase
        .storage()
        .ref(`profilePictures/${auth.uid}/${img.name}`);
      console.log(`uploading ${img.name}...`);
      const snapshot = await storageRef.put(img);
      console.log(snapshot);
      const newImgUrl = await snapshot.ref.getDownloadURL();
      console.log(`Got new imgUrl: ${newImgUrl}`);
      dispatch(settingsSetImgUrl(newImgUrl));
    }

    if (newPasswordIsNotEmpty) {
      if (!passwordsAreMatching) {
        setSubmitErr("Error: the new passwords are not matching.");
        return;
      } else if (!passwordsHaveCorrectLength) {
        setSubmitErr("Error: password should have at least 6 characters");
        setLoading(false);
        return;
      }
    }

    if (email.length > 0) {
      const emailIsValidEmail = validator.validate(email);
      if (!emailIsValidEmail) {
        setSubmitErr("Error: please enter a valid email address");
        setLoading(false);
        return;
      }
    }

    const oldFullName = auth.displayName
      ? auth.displayName
      : user.userData.fullName;
    let oldImgUrl = "";

    if (auth.photoURL) {
      oldImgUrl = auth.photoURL;
    } else if (user.userData.profilePicture) {
      oldImgUrl = user.userData.profilePicture;
    }

    dispatch(settingsSubmit(oldFullName, oldImgUrl));
    setTimeout(() => {
      resetImage();
      setLoading(false);
    }, 2000);
  };

  const firestoreData = props.firestoreData;
  const usersCollectionData = firestoreData.users;
  const userData = usersCollectionData ? firestoreData.users[auth.uid] : null;
  const hasSubscription = userData ? userData.hasSubscription : false;

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100vw",
          height: "100vh",
        }}
      >
        <Lottie lotti={loadingAnimation} height={150} width={150} />
      </div>
    );
  }

  return (
    <div className="settingsTab">
      <h2>Account</h2>
      <h4>Personal information</h4>
      {submitErr && (
        <ErrorAlert error={submitErr} close={() => setSubmitErr(null)} />
      )}
      {firebaseSubmitErr && (
        <ErrorAlert
          error={firebaseSubmitErr}
          close={() => dispatch(resetSettingsError())}
        />
      )}
      <div className="settingsInputSection">
        <p>Full name</p>
        <Input val={fullName} handleChange={handleFullNameChange} />
      </div>
      <div className="settingsInputSection">
        <p>Email address</p>
        <Input
          val={email}
          handleChange={handleEmailChange}
          type="email"
          readOnly={loginProvider === "google.com" ? true : false}
        />
      </div>
      <div className="settingsInputSection">
        <p>Your user ID</p>
        <Input
          val={auth.uid}
          readOnly={true}
        />
      </div>
      <div className="settingsInputSection">
        <p>Photo</p>
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            alt=""
            style={{ width: "100px", height: "100px", objectFit: "cover" }}
            src={
              imgUrl
                ? imgUrl
                : "https://rogroep.nl/wp-content/uploads/2020/09/blank-profile-picture-973460_640.png"
            }
          />
          <label htmlFor="file-upload" className="promptButton flatButton">
            Choose image
          </label>
          <input
            style={{ display: "none" }}
            id="file-upload"
            type="file"
            className="promptButton flatButton"
            onChange={handleImgUrlChange}
            accept=".png,.jpg,.jpeg"
          />
        </div>
      </div>
      {!loginProvider && (
        <div>
          <h4>Password</h4>
          <div className="settingsInputSection">
            <p>New password</p>
            <Input
              inputType="password"
              val={newPassword}
              handleChange={handleNewPasswordChange}
            />
          </div>
          <div className="settingsInputSection">
            <p>Repeat new password</p>
            <Input
              inputType="password"
              val={repeatNewPassword}
              handleChange={handleRepeatNewPasswordChange}
            />
          </div>
        </div>
      )}
      <h4>Company information</h4>
      <div className="settingsInputSection">
        <p>Company name</p>
        <Input val={companyName} handleChange={handleCompanyNameChange} />
      </div>
      <div className="settingsInputSection">
        <p>VAT number</p>
        <Input val={vatNumber} handleChange={handleVatNumberChange} />
      </div>
      <div className="settingsInputSection">
        <p>Company address</p>
        <Input val={companyAddress} handleChange={handleCompanyAddressChange} />
      </div>
      <Divider />
      <div id="settingsDangerZone">
        <h4 style={{ color: "darkRed", marginBottom: "30px" }}>
          ⚠️ Danger zone ⚠️
        </h4>
        {hasSubscription && (
          <WarningAlert warning="You have to cancel your plan before deleting your account. Your account can then be deleted after your trial or billing period is over." />
        )}
        {deletionErr && (
          <ErrorAlert error={deletionErr} close={() => setDeletionErr("")} />
        )}
        <Button
          txt="Delete account"
          handleClick={() => {
            setDialogOpen(true);
          }}
          disabled={hasSubscription}
        />
        <Dialog
          open={dialogOpen}
          dialogTitle="Are you sure?"
          dialogText="This is an irreversible action: once you click on 'delete account', all of your data will be deleted permanently."
          button1Text="Keep my account"
          button2Text="Delete my account"
          showLeftButton={true}
          handleClose={() => {
            setDialogOpen(false);
          }}
          handleClick={deleteAccount}
        />
      </div>
      <SubmitComponent
        screenSize={props.screenSize}
        handleCancel={setInitState}
        handleSubmit={(e) => handleSubmit(e)}
        disable={disableSubmitButton}
      />
    </div>
  );
}
