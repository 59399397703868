import React, { useState } from "react";
//import styling
import "./PromptGrid.css";
//import components
import GridTile from "../../components/in-meeting/PromptGridTile/PromptGridTile";
import LightButton from "../../components/buttons/LightMeetingButton";
//import utils
import { updates } from "../../utils/MeetingUtils/meetingData";
import { returnUserData } from "../../utils/MeetingUtils/meetingUtils";
//import assets
import circle_checked from "../../assets/icons/circle_check_filled_light.png";
import circle_unchecked from "../../assets/icons/circle_check_outline_light.png";

function PromptGrid(props) {
  const sortedUpdates = updates.sort(
    (a, b) => a.update.length - b.update.length
  );

  const [done, setDone] = useState(false);

  const handleClick = () => {
    setDone((old) => {
      return !old;
    });
  };

  return (
    <div id="promptGrid">
      <div id="doneButtonDiv">
        <LightButton
          txt={done ? "Marked as done!" : "Done reading?"}
          srcBefore={done ? circle_checked : circle_unchecked}
          handleClick={handleClick}
        />
      </div>
      {sortedUpdates.map((update, index) => {
        const user = returnUserData(update.userId);
        return (
          <GridTile
            key={`${update.userId}${index}`}
            fullName={user.fullName}
            update={update.update}
            src={user.src}
            isNewComment={update.isNew}
          />
        );
      })}
    </div>
  );
}

export default PromptGrid;
