import { fetchTemplates } from "../api/sanity/sanityAPI";

export const templatesFetch = () => {
  return async (dispatch) => {
    const data = await fetchTemplates();
    dispatch({ type: "TEMPLATES_FETCH", data });
  };
};

export const templateAdd = () => {
  return {
    type: "TEMPLATE_ADD",
  };
};

export const templateModifyTitle = (obj) => {
  return {
    type: "TEMPLATE_MODIFY_TITLE",
    payload: obj,
  };
};

export const templateModifyDescription = (obj) => {
  return {
    type: "TEMPLATE_MODIFY_DESCRIPTION",
    payload: obj,
  };
};

export const templateModifyDuration = (obj) => {
  return {
    type: "TEMPLATE_MODIFY_DURATION",
    payload: obj,
  };
};

export const templateRemove = (obj) => {
  return {
    type: "TEMPLATE_REMOVE",
    payload: obj,
  };
};

export const templateSwitch = (obj, targetIndex) => {
  return {
    type: "TEMPLATE_SWITCH",
    payload: {
      obj,
      targetIndex,
    },
  };
};

export const setTemplatePointsList = (arr) => {
  return {
    type: "SET_TEMPLATE_TOPICS",
    payload: arr,
  };
};

export const templateTitleEdit = (title) => {
  return {
    type: "TEMPLATE_TITLE_EDIT",
    payload: title,
  };
};

export const templateDescriptionEdit = (description) => {
  return {
    type: "TEMPLATE_DESCRIPTION_EDIT",
    payload: description,
  };
};

export const setTemplateImg = (imgUrl) => {
  return {
    type: "TEMPLATE_IMG_SET",
    payload: imgUrl,
  }
}

export const templateCreateSuccess = () => {
  return {
    type: "TEMPLATE_CREATE_SUCCESS"
  }
}

export const wipeTemplateCreateSuccess = () => {
  return {
    type: "WIPE_TEMPLATE_CREATE_SUCCESS"
  }
}

export const templateReset = (dispatch, getState) => {
  dispatch({ type: "TEMPLATE_RESET" });
  dispatch({ type: "TEMPLATE_TITLE_RESET" });
  dispatch({ type: "TEMPLATE_DESCRIPTION_RESET" });
  dispatch({ type: "TEMPLATE_DATE_RESET" });
};

export const templatePointsReset = () => {
  return {
    type: "TEMPLATE_RESET",
  }
}

export const templateTitleReset = () => {
  return {
    type: "TEMPLATE_TITLE_RESET",
  }
}

export const templateDescriptionReset = () => {
  return {
    type: "TEMPLATE_DESCRIPTION_RESET",
  }
}
