import React, { useState } from "react";
import Alert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import CloseIcon from "@material-ui/icons/Close";

//import styling
import "./Alert.css";

function WarningAlert(props) {
  const [open, setOpen] = useState(true);

  return (
    <div className="alert">
      <Collapse in={open}>
        <Alert
          variant="outlined"
          severity="warning"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpen(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {props.warning}
        </Alert>
      </Collapse>
    </div>
  );
}

export default WarningAlert;
