import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import Linkify from "react-linkify";
//import styling
import "./UpdatePageComponents.css";

//import material UI components
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FilePreview from "../../components/FilePreview/FilePreview";
import Divider from "@material-ui/core/Divider";
import FlatButton from "../../components/buttons/FlatButton";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import Avatar from "@material-ui/core/Avatar";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import Input from "@material-ui/core/Input";

//import utils
import { generateInitials } from "../../utils/avatarUtils";

//import actions
import {
  commentAdd,
  sendNewComment,
  commentModify,
  commentRemove,
  deleteComment,
  updateComment,
} from "../../actions/commentsActions";

//import icons
import ChatBubbleOutlineIcon from "@material-ui/icons/ChatBubbleOutline";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import DoneIcon from "@material-ui/icons/Done";

//import colors
import { deepOrange } from "@material-ui/core/colors";
import { updateRemove } from "../../actions/updatesActions";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    margin: "20px 0 20px 10px",
  },
  accordion: {
    backgroundColor: "rgba(255, 255, 255, 0.6)",
    boxShadow: "none",
    border: "0px solid lightGrey",
  },
  heading: {
    marginLeft: "15px",
    fontFamily: "Epilogue",
    fontSize: theme.typography.pxToRem(15),
    flexShrink: 0,
    color: theme.palette.text.hint,
  },
  secondaryHeading: {
    fontFamily: "Epilogue",
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  commentHeading: {
    fontFamily: "Epilogue",
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
    fontWeight: "500",
    margin: "15px 0 10px 10px",
  },
  commentName: {
    marginLeft: "10px",
    marginRight: "10px",
    fontFamily: "Epilogue",
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "10%",
    flexShrink: 0,
    color: theme.palette.text.hint,
  },
  commentText: {
    fontFamily: "Epilogue",
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  editCommentInput: {
    spacing: 1,
    margin: 0,
    minWidth: "25vw",
    fontFamily: "Epilogue",
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  editText: {
    fontFamily: "Epilogue",
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.secondary,
    fontWeight: "500",
  },
  deleteText: {
    fontFamily: "Epilogue",
    fontSize: theme.typography.pxToRem(14),
    color: "red",
    fontWeight: "500",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    fontSize: "13px",
    fontFamily: "Epilogue",
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  detailIcon: {
    width: "16px",
    height: "16px",
  },
  commentTextField: {
    padding: "2px 15px",
    display: "flex",
    alignItems: "center",
    minWidth: "100%",
    margin: "0 -15px -15px -15px",
    boxShadow: "none",
    borderRadius: "0px 0px 4px 4px",
  },
  commentSection: {
    maxHeight: "150px",
    margin: "10px 0 0 0",
    overflowY: "auto",
    paddingBottom: "40px",
  },
  avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    marginLeft: "10px",
  },
  letterAvatar: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
    width: theme.spacing(4),
    height: theme.spacing(4),
    marginLeft: "10px",
    fontSize: "14px",
  },
  details: {
    color: theme.palette.text.hint,
    display: "flex",
    marginLeft: "5px",
    marginBottom: "5px",
    width: "75px",
    justifyContent: "space-evenly",
  },
}));

function MoreButton(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {
    dispatch(deleteComment(props.comment, props.meetingID, props.topicID));
  };

  return (
    <div style={{ marginTop: "10px" }}>
      <IconButton className={classes.iconButton} onClick={handleClick}>
        <MoreVertIcon style={{ height: 20, width: 20 }} />
      </IconButton>
      <Menu
        id="simple-menu"
        keepMounted
        onClose={handleClose}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
      >
        <MenuItem onClick={props.handleEditToggle}>
          <EditIcon
            style={{ marginRight: 10, height: 18, width: 18, color: "grey" }}
          />{" "}
          <div className={classes.editText}>Edit</div>
        </MenuItem>
        <MenuItem onClick={handleDelete} color="secondary">
          <DeleteIcon
            style={{ marginRight: 10, height: 18, width: 18 }}
            color="secondary"
          />{" "}
          <div className={classes.deleteText}>Delete</div>
        </MenuItem>
      </Menu>
    </div>
  );
}

function Comment(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isMyComment = props.userID === props.currentUserID;
  const [isInEditMode, setIsInEditMode] = useState(false);
  const [editText, setEditText] = useState(props.comment);
  const showMenuButton = isMyComment && !isInEditMode && !props.isReadOnly;
  const showDoneButton = isInEditMode;

  const handleEditTextChange = (e) => {
    const newText = e.target.value;
    setEditText(newText);
  };

  const handleEditTextSubmit = (e) => {
    e.preventDefault();

    if (editText === props.comment) {
      console.log("No change has been made");
      setIsInEditMode(false);
      return;
    }

    dispatch(
      updateComment(
        { ...props.commentObj, comment: editText },
        props.meetingID,
        props.topicID
      )
    );
    setIsInEditMode(false);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "baseline",
      }}
    >
      <div
        style={{
          display: "flex",
          marginTop: "20px",
          justifyContent: "flex-start",
          alignItems: "baseline",
          lineHeight: "1.5em",
        }}
      >
        <div className={classes.commentName}>{props.firstName}</div>
        {!isInEditMode && (
          <Linkify>
            <div className={classes.commentText}>{props.comment}</div>
          </Linkify>
        )}
        {isInEditMode && (
          <form
            noValidate
            autoComplete="off"
            onSubmit={handleEditTextSubmit}
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Input
              placeholder="Type here"
              inputProps={{ "aria-label": "description" }}
              className={classes.editCommentInput}
              value={editText}
              onChange={handleEditTextChange}
              m="0"
            />
            {showDoneButton && (
              <IconButton className={classes.iconButton} type="submit">
                <DoneIcon style={{ height: 20, width: 20, color: "#14CC9E" }} />
              </IconButton>
            )}
          </form>
        )}
      </div>
      {showMenuButton && (
        <MoreButton
          comment={props.commentObj}
          meetingID={props.meetingID}
          topicID={props.topicID}
          handleEditToggle={() => setIsInEditMode(true)}
        />
      )}
    </div>
  );
}

function CommentSection(props) {
  const classes = useStyles();

  return <div className={classes.commentSection}>{props.children}</div>;
}

function CommentTextField(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const comments = useSelector((state) => state.comments);
  const thisComment = comments.find((c) => {
    return c.updateTimestamp === props.updateTimestamp;
  });

  const registerComment = (val) => {
    dispatch(
      commentAdd({
        userID: props.auth.uid,
        displayName: props.userData.fullName,
        updateTimestamp: props.updateTimestamp,
      })
    );
  };

  const handleChange = (val) => {
    if (!thisComment) {
      registerComment();
    }
    dispatch(
      commentModify({
        updateTimestamp: props.updateTimestamp,
        comment: val,
      })
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    dispatch(
      sendNewComment(
        thisComment.commentTimestamp,
        props.meetingID,
        props.topicID
      )
    ).then(() => {
      dispatch(commentRemove(thisComment.commentTimestamp));
    });
  };

  return (
    <Paper
      component="form"
      className={classes.commentTextField}
      onSubmit={handleSubmit}
    >
      <InputBase
        onChange={(e) => {
          handleChange(e.target.value);
        }}
        className={classes.input}
        value={thisComment ? thisComment.comment : ""}
        placeholder="Write your thoughts here..."
        inputProps={{ "aria-label": "search google maps" }}
      />
      <Divider className={classes.divider} orientation="vertical" />
      <IconButton
        type="submit"
        color="primary"
        className={classes.iconButton}
        aria-label="directions"
      >
        <ArrowUpwardIcon />
      </IconButton>
    </Paper>
  );
}

function Update(props) {
  const updateFile = props.updateFile;
  const [state, setState] = useState(null);
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const linkInUpdate = useRef();

  const comments = props.comments;

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? true : false);
  };

  function linkify(text) {
    const urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
    const copyText = text;
    linkInUpdate.current = copyText.match(urlRegex, (url) => {
      return url;
    });
    setState('done');
  }

  useEffect(() => {
    linkify(props.updateText);
  }, []);

  return (
    <div className={classes.root}>
      <Accordion
        className={classes.accordion}
        expanded={expanded}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div
              style={{
                display: "flex",
                marginTop: "20px",
                alignItems: "center",
              }}
            >
              {!props.imgUrl && (
                <Avatar className={classes.letterAvatar}>
                  {generateInitials(props.fullName)}
                </Avatar>
              )}
              {props.imgUrl && (
                <Avatar className={classes.avatar} src={props.imgUrl} />
              )}
              <div className={classes.heading}>{props.firstName}</div>
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Linkify>
                <Typography className={classes.secondaryHeading}>
                  {props.updateText}
                  {linkInUpdate.current && (
                <div id="linkPreviewHolder" style={{margin: "20px 0 0 0"}}>
                </div>
              )}
                </Typography>
                
              </Linkify>
            </div>
            <div className={classes.details}>
              <ChatBubbleOutlineIcon className={classes.detailIcon} />{" "}
              {comments ? comments.length : 0}{" "}
              <AttachFileIcon className={classes.detailIcon} />{" "}
              {updateFile ? 1 : 0}
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div
            style={{ display: "flex", flexDirection: "column", width: "100%" }}
          >
            <div style={{ marginBottom: "20px", width: "100%" }}>
              <Divider />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-start",
                flexWrap: "wrap",
              }}
            >
              <div>
                {updateFile && (
                  <FilePreview
                    updateFile={updateFile}
                    key={updateFile.fullPath}
                    extension={
                      updateFile.fileName
                        ? updateFile.fileName.split(".").pop()
                        : ""
                    }
                    fileName={
                      updateFile.fileName
                        ? updateFile.fileName
                        : updateFile.file.name
                    }
                    handleClick={
                      updateFile.fileName
                        ? () => props.getFileLink(updateFile)
                        : () =>
                            props.handleFileSave(
                              updateFile.file,
                              props.timestamp
                            )
                    }
                    isInUpdate={true}
                    getImgUrl={props.getImgUrl}
                  />
                )}
                {!updateFile && (
                  <div className={classes.commentHeading}>Comments</div>
                )}
              </div>
              {props.isMyUpdate && (
                <div className="editUpdateButtonDiv">
                  {!props.isReadOnly && (
                    <FlatButton txt="edit" handleClick={props.handleToggle} />
                  )}
                </div>
              )}
            </div>
            {updateFile && (
              <div className={classes.commentHeading}>Comments</div>
            )}
            <CommentSection>
              {comments.map((commentObj, index) => {
                const { firstName, comment, userID } = commentObj;
                return (
                  <Comment
                    key={index.toString()}
                    firstName={firstName}
                    comment={comment}
                    commentObj={commentObj}
                    userID={userID}
                    currentUserID={props.auth.uid}
                    topicID={props.topicID}
                    meetingID={props.meetingID}
                    isReadOnly={props.isReadOnly}
                  />
                );
              })}
            </CommentSection>
            {!props.isReadOnly && (
              <CommentTextField
                updateTimestamp={props.timestamp}
                auth={props.auth}
                topicID={props.topicID}
                meetingID={props.meetingID}
                userData={props.userData}
              />
            )}
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

Update.propTypes = {
  firstName: PropTypes.string.isRequired,
  updateText: PropTypes.string.isRequired,
  filePath: PropTypes.string,
  editEnabled: PropTypes.bool,
  handleToggle: PropTypes.func,
};

export default Update;
