import moment from 'moment';

export const getFormattedDate = (unformattedDate) => {
    const unformattedDateMill = unformattedDate * 1000;
    const date = new Date(unformattedDateMill);
    // getting the full year as string 
    const year = moment(date).format('YYYY');
    // getting the month (e.g. 'January')
    const rawMonth = moment(date).format('M');

    const months = [
        'JAN',
        'FEB',
        'MAR',
        'APR',
        'MAY',
        'JUN',
        'JUL',
        'AUG',
        'SEP',
        'OCT',
        'NOV',
        'DEC'
    ]
    const month = months[rawMonth - 1];
    // getting raw day string (e.g. 1)
    const rawDay = moment(date).format('D');
    // converting single char strings into double (e.g. '01')
    const day = rawDay.length === 1 ? `0${rawDay}` : rawDay;

    // return object with year, month, and day
    return {
        year,
        month,
        day,
    }
}

export const isWithinAWeek = (unformattedDate) => {
    const unformattedDateMill = unformattedDate * 1000;
    const date = new Date(unformattedDateMill);
    const dateNow = new Date();
    const isInSameYear = dateNow.getFullYear() === date.getFullYear();
    const isInSameMonth = dateNow.getMonth() === date.getMonth();
    if(isInSameMonth) {
    }
    const meetingDayIsAfterToday = date.getDate() - dateNow.getDate() >= 0;
    if(meetingDayIsAfterToday) {
    }
    const meetingIsWithin7Days = date.getDate() - dateNow.getDate() <= 7;
    if(meetingIsWithin7Days) {
    }
    if(isInSameYear && isInSameMonth && meetingDayIsAfterToday && meetingIsWithin7Days) {
        return true;
    } else {
        return false;
    }
}