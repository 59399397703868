import React from "react";
//import styling
import "./PromptGridTile.css";

function NoUpdatesGridTile(props) {

  return (
    <div className="noUpdatesTile">
      <p>No updates to show for this topic yet.</p>
    </div>
  );
}

export default NoUpdatesGridTile;
