export var firebaseConfig = {
    apiKey: "AIzaSyBkq_X9VqALCOVA8q4tOkM0q3ies-E423s",
    authDomain: "prompt-meetings-app.firebaseapp.com",
    databaseURL:
      "https://prompt-meetings-app-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "prompt-meetings-app",
    storageBucket: "prompt-meetings-app.appspot.com",
    messagingSenderId: "918065809955",
    appId: "1:918065809955:web:4964cbda41c0c49b76bf96",
    measurementId: "G-15Q7NJ2LKY",
};
  