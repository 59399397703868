import React, { useState } from "react";
import { useDispatch } from "react-redux";
//import assets
import canvas from "../../assets/templates/blank_canvas.png";
import coffee from "../../assets/templates/coffee.png";
import lightbulb from "../../assets/templates/brainstorm.png";
import rocket from "../../assets/templates/rocket.png";
import growth from "../../assets/templates/growth.png";
//import actions
import { setTemplateImg } from "../../actions/templateActions";
export default function TemplateImgContainer(props) {
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(coffee);
  const images = [coffee, canvas, lightbulb, rocket, growth];
  const coffeeUrl =
    "https://cdn.sanity.io/images/f6osn1fn/production/df2ce7833f5d96ece19dd0862021ee2321339473-487x284.png";
  const canvasUrl =
    "https://cdn.sanity.io/images/f6osn1fn/production/2776d42e372c60ea1f5a520bb68a131e1257874c-452x263.png";
  const lightbublUrl =
    "https://cdn.sanity.io/images/f6osn1fn/production/675f5945474853c6137f93af2018c0f7ead30fa1-452x263.png";
  const rocketUrl =
    "https://cdn.sanity.io/images/f6osn1fn/production/4c5ed178b6db864c02f7b7a50083a7985726e1d7-452x263.png";
  const growthUrl =
    "https://cdn.sanity.io/images/f6osn1fn/production/9d7c8ac53548b480a1c139d3e9255fd2f0fb6693-452x263.png";

  const getImgStyle = (img) => {
    return {
      cursor: "pointer",
      width: 50,
      minWidth: 50,
      height: 50,
      minHeight: 50,
      objectFit: "cover",
      borderRadius: "50%",
      border:
        selected === img
          ? "3px solid rgba(57, 69, 184, 1)"
          : "3px solid transparent",
      marginRight: 10,
      marginTop: 20,
    };
  };

  const syncGlobalState = (img) => {
    let imgUrl;
    switch (img) {
      case coffee:
        imgUrl = coffeeUrl;
        break;
      case canvas:
        imgUrl = canvasUrl;
        break;
      case lightbulb:
        imgUrl = lightbublUrl;
        break;
      case rocket:
        imgUrl = rocketUrl;
        break;
      case growth:
        imgUrl = growthUrl;
        break;
      default:
        imgUrl = coffeeUrl;
        break;
    }
    dispatch(setTemplateImg(imgUrl));
  };
  return (
    <div style={{ margin: "20px 0 40px 0" }}>
      <img
        src={selected}
        alt=""
        draggable="false"
        style={{ maxWidth: "95%", width: 300 }}
      />
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          maxWidth: "100%",
        }}
      >
        {images.map((img, i) => {
          return (
            <img
              draggable="false"
              style={getImgStyle(img)}
              src={img}
              key={`img${i.toString()}`}
              alt=""
              onClick={() => {
                setSelected(img);
                syncGlobalState(img);
              }}
            />
          );
        })}
      </div>
    </div>
  );
}
