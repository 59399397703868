import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
//import components
import Input from "../../components/Input/InputField";
import Button from "../../components/buttons/FullButton";
import ThirdPartyButton from "../../components/buttons/ThirdPartyLoginButton";
import ErrorSnackbar from "../../components/Snackbar/ErrorSnackbar";
//import styling
import "./LoginContainer.css";
//import assets
import googleLogo from "../../assets/logos/googleLogo.png";
import githubLogo from "../../assets/logos/githubLogo.png";
//import actions
import {
  signIn,
  signInWithGoogle,
  signInWithGithub,
} from "../../actions/authActions";
//import actions
import { resetAuthError } from "../../actions/authActions";

function LoginContainer(props) {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleEmailChange = (val) => {
    setEmail(val);
  };

  const handlePasswordChange = (val) => {
    setPassword(val);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    props.signIn({ email, password });
  };

  const handleGoogleSignin = (e) => {
    e.preventDefault();

    props.signInWithGoogle();
  };

  const handleGithubSignin = (e) => {
    e.preventDefault();

    props.signInWithGithub();
  };

  const history = useHistory();

  const routeChange = () => {
    let path = `forgot-password`;
    history.push(path);
  };

  const handleSnackbarClose = (e, reason) => {
    if (reason === "clickaway") {
      return;
    }

    dispatch(resetAuthError());
  };

  return (
    <div className="loginContainer">
      <ErrorSnackbar
        open={props.authError}
        msg={props.authError}
        handleClose={handleSnackbarClose}
      />
      <form className="loginContainerContent">
        <h1>Log in</h1>
        <div>
          <Input
            inputType="email"
            placeHolder="Email address"
            handleChange={handleEmailChange}
            val={email}
          />
          <Input
            inputType="password"
            placeHolder="Password"
            handleChange={handlePasswordChange}
            val={password}
          />
          <a id="forgotPassword" onClick={routeChange}>
            Forgot password?
          </a>
          <Button txt="Log in" handleClick={handleSubmit} isFullWidth={true} />
        </div>
        <p id="or">OR</p>
        <ThirdPartyButton
          srcBefore={googleLogo}
          txt="Continue with Google"
          isFullWidth={true}
          handleClick={handleGoogleSignin}
        />
        <ThirdPartyButton
          srcBefore={githubLogo}
          txt="Continue with GitHub"
          isFullWidth={true}
          handleClick={handleGithubSignin}
        />
        <p
          style={{
            fontSize: 12,
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: 20,
            lineHeight: "1.75em",
            textAlign: "center",
            maxWidth: "80%",
          }}
        >
          By continuing, you declare that you have read and accepted our{" "}
          <a
            target="_blank"
            href="https://www.getprompt.io/legal/privacy"
            style={{ textDecoration: "none" }}
          >
            Privacy Policy
          </a>{" "}
          and{" "}
          <a
            target="_blank"
            href="https://www.getprompt.io/legal/terms-of-service"
            style={{ textDecoration: "none" }}
          >
            Terms of Use
          </a>
          .
        </p>
      </form>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.authError,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    signIn: (creds) => dispatch(signIn(creds)),
    signInWithGoogle: () => dispatch(signInWithGoogle()),
    signInWithGithub: () => dispatch(signInWithGithub()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);
