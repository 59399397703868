const initState = {
    templates: [],
    err: null,
}

const sanityTemplatesReducer = (state = initState, action) => {
    switch(action.type){
        case 'TEMPLATES_FETCH':
            return action.data;
        default:
            return state;
    }
}

export default sanityTemplatesReducer;