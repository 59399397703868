import React from 'react';
import Button from '../../buttons/FullButton';
import FlatButton from '../../buttons/FlatButton';

export default function SubmitComponent({screenSize, handleCancel, handleSubmit, disable}) {
    return (
        <div style={{
            display: "flex",
            justifyContent: "flex-end",
            padding: "10px",
            borderRadius: "10px",
            borderColor: "transparent",
            position: "fixed",
            bottom: screenSize === "big" ? "2.5%" : "0%",
            right: screenSize === "big" ? "2%" : "0%",
            backgroundColor: screenSize === "small" ? "White" : "transparent",
            width: screenSize === "small" ? "100%" : "auto"
        }}>
            <FlatButton txt="Cancel" handleClick={handleCancel} disabled={disable} />
            <Button txt="Save changes" handleClick={handleSubmit} disabled={disable} />
        </div>
    );
}