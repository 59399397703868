import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import { firestoreConnect } from "react-redux-firebase";
import { connect } from "react-redux";
import { compose } from "redux";
//import icons
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import CreditCardIcon from "@material-ui/icons/CreditCard";
//import screens
import LoadingScreen from "../../screens/LoadingScreen/LoadingScreen";
//import components
import AccountSettings from "./Account/AccountSettings";
import BillingSettings from "./Billing/BillingSettings";
//import material stuff
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
  },
  appBar: {
    backgroundColor: "white",
    top: "11%",
    boxShadow: "none",
  },
  tab: {
    fontWeight: "600",
    fontFamily: "Epilogue",
    textTransform: "capitalize",
    minWidth: "200px",
    width: "50vw",
    maxWidth: "100%",
  },
}));

function ScrollableTabsButtonAuto(props) {
  const classes = useStyles();
  console.log("Rerender mother component");
  const [value, setValue] = React.useState(0);
  const [state, setState] = useState(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  if (!props.auth.uid) return <Redirect to="/login" />;
  else if (!props.firestoreData) {
    return <LoadingScreen />;
  }

  return (
    <div className={classes.root}>
      <AppBar position="sticky" className={classes.appBar}>
        <Tabs
          value={value}
          className={classes.tabs}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab
            className={classes.tab}
            icon={<AccountCircleIcon />}
            label="Account"
            {...a11yProps(0)}
          />
          <Tab
            className={classes.tab}
            icon={<CreditCardIcon />}
            label="Billing"
            {...a11yProps(1)}
          />
        </Tabs>
      </AppBar>
      {props.firestoreData && (
        <TabPanel value={value} index={0}>
          <AccountSettings
            screenSize="small"
            user={
              props.firestoreData.users
                ? props.firestoreData.users[props.auth.uid]
                : {}
            }
            hasSubscription={
              props.firestoreData.users
                ? props.firestoreData.users[props.auth.uid].hasSubscription
                : false
            }
            firestoreData={props.firestoreData}
            renderParent={() => setState("")}
          />
        </TabPanel>
      )}
      {props.auth.uid && (
        <TabPanel value={value} index={1}>
          <BillingSettings
            hasSubscription={
              props.firestoreData.users
                ? props.firestoreData.users[props.auth.uid].hasSubscription
                : false
            }
          />
        </TabPanel>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebaseReducer.auth,
    firestoreData: state.firestore.data,
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect((props) => {
    return [{ collection: "users", doc: props.auth.uid }];
  })
)(ScrollableTabsButtonAuto);
