import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { createMuiTheme } from '@material-ui/core/styles';

import "./Input.css";

import Slider from "@material-ui/core/Slider";

const theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 450,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
})

const useStyles = makeStyles( (theme) => ({
  root: {
    width: 300,
    marginLeft: 10,
    minWidth: "10px",
    maxWidth: "100%",
    marginRight: 0,
    [theme.breakpoints.down('sm')]: {
      maxWidth: "150px",
    },
  },
}));

function valuetext(value) {
  return `${value} minutes`;
}

function DiscreteSlider(props) {
  const classes = useStyles();

  const onChange = (e, value) => {
    const number = value;

    if (props.handleChange) {
      props.handleChange(number);
    }
  };

  return (
    <div className={classes.root}>
      <Slider
        key={`slider-${props.id}`}
        onChange={onChange}
        value={props.val}
        valueLabelDisplay="auto"
        getAriaValueText={valuetext}
        aria-labelledby="discrete-slider"
        step={1}
        min={1}
        max={20}
      />
    </div>
  );
}

function NumberInputField(props) {
  const onChange = ({ target }) => {
    const number = target.value;

    if (props.handleChange) {
      props.handleChange(number);
    }
  };

  return (
    <div className="inputField">
      <input
        type="number"
        className="numberInput"
        min="1"
        value={props.val}
        onChange={onChange}
        onSubmit={props.handleSubmit}
        placeholder={props.placeHolder ? props.placeHolder : "Type here"}
      />
    </div>
  );
}

NumberInputField.propTypes = {
  // value of the input field
  val: PropTypes.number.isRequired,
  // function to handle submit
  handleSubmit: PropTypes.func,
  // the placeholder in the input field
  placeHolder: PropTypes.string,
  // function to handle change for e.g. updating parent or siblings
  handleChange: PropTypes.func,
};

export default DiscreteSlider;
