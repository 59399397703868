import React from "react";
//import components
import Nav from "../../components/NavBar/NavBar";
import VerticalTabs from "../../containers/Settings/SettingsContentContainer";
import HorizontalTabs from "../../containers/Settings/MobileSettingsContentContainer";
//import styling
import "./Settings.css";

const WindowWidthContext = React.createContext(0);

function Settings() {
  return (
    <div id="settings">
      <Nav />
      <div id="largeScreenSettings">
        <VerticalTabs />    
      </div>
      <div id="smallScreenSettings">
        <HorizontalTabs />    
      </div>
    </div>

  );
}

export {WindowWidthContext, Settings};
