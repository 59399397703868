import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
  return (
    <MuiAlert elevation={6} variant="filled" {...props} severity="error" />
  );
}

export default function ErrorSnackbar({ open, handleClose, msg }) {
  return (
    <Snackbar open={open} onClose={handleClose}>
      <Alert onClose={handleClose}>{msg}</Alert>
    </Snackbar>
  );
}
