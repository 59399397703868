import { sanityConfig } from "../../config/sanityConfig";
const sanityClient = require("@sanity/client");

const client = sanityClient(sanityConfig);

export const fetchTemplates = async () => {
  const query = '*[_type == "template"]{title, description, "imageUrl": image.asset->url, topics}';
  const result = {
    templates: [],
    err: null,
  };
  try {
    result.templates = await client.fetch(query);
  } catch (err) {
    result.err = err;
    return result;
  }

  if (!result.templates) {
    result.err = new Error("No templates found.");
  }
  return result;
};
