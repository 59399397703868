const initState = {
    templateCreateSuccess: false,
    templateCreateError: null,
  };
  
  const templateCreateReducer = (state = initState, action) => {
    switch (action.type) {
      case "TEMPLATE_CREATE_SUCCESS":
        console.log("Template successfully created");
        return {
          ...state,
          templateCreateError: null,
          templateCreateSuccess: true,
        };
      case "WIPE_TEMPLATE_CREATE_SUCCESS":
        return initState;
      case "TEMPLATE_CREATE_ERROR":
        return {
          ...state,
          templateCreateError: `Action failed. Error: ${action.err.message}`,
          templateCreateSuccess: false,
        };
      default:
        return state;
    }
  };
  
  export default templateCreateReducer;
  