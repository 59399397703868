import React from 'react';
import PropTypes from 'prop-types';
//import components
import ToolBarInputField from './ToolBarInputField';
import LightButton from '../../buttons/LightMeetingButton';
import WhiteButton from '../../buttons/WhiteButton';
//import styling
import './FixedToolBar.css';
//import assets
import arrowLeft from '../../../assets/icons/long_left.png';
import arrowRight from '../../../assets/icons/long_right.png';

function FixedToolBar(props) {
    return (
        <div id="fixedToolBar">
            <div className="button" id="leftButton">
                <LightButton srcBefore={arrowLeft} txt="Last topic" handleClick={() => {}}/>
            </div>
            <ToolBarInputField />
            <div className="button" id="rightButton">
                <WhiteButton srcAfter={arrowRight} txt="Next topic" handleClick={() => {}}/>
            </div>
        </div>
    );
}

FixedToolBar.propTypes = {
    isAdmin: PropTypes.bool.isRequired,
}

export default FixedToolBar;