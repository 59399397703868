import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import './fab.css';


const useStyles = makeStyles((theme) => ({
  fab: {
    margin: theme.spacing(1),
    backgroundColor: "rgba(77, 90, 218, 1)",
    color: "white",
    textTransform: "none",
    fontFamily: "Epilogue"
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
    color: "white",
  },
}));

export default function FloatingActionButton({ handleClick, txt, showOnBigScreen, showCheckIcon, disabled }) {
  const classes = useStyles();

  return (
    <div className={showOnBigScreen ? "floatingBottomRight" : "floatingBottomRight smallScreenOnly"}>
      <Fab
        variant="extended"
        aria-label="add"
        className={classes.fab}
        onClick={handleClick}
        color="primary"
        disabled={disabled}
      >
        {!showCheckIcon && <AddIcon className={classes.extendedIcon} />}
        {showCheckIcon && <CheckCircleIcon className={classes.extendedIcon} />}
        {txt}
      </Fab>
    </div>
  );
}
