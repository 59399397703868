import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Fab from "@material-ui/core/Fab";
import Popover from "@material-ui/core/Popover";
import "./fab.css";
import PostAddIcon from "@material-ui/icons/PostAdd";

const useStyles = makeStyles((theme) => ({
  fab: {
    backgroundColor: "#8A94FF",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    color: "white",
    textTransform: "none",
    fontFamily: "Epilogue",
    "&:hover": {
      backgroundColor: "rgb(122, 131, 236)",
    },
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
    color: "white",
  },
  icon: {
    color: "white",
  },
  paper: {
    padding: theme.spacing(1),
  },
  popover: {
    pointerEvents: "none",
    margin: 10,
  },
}));

export default function FloatingActionButton({ handleClick, txt }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <div className="bigScreen">
        <Fab
          aria-label="add"
          className={classes.fab}
          onClick={handleClick}
          color="primary"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        >
          <PostAddIcon className={classes.icon} />
        </Fab>
        <Popover
          id={`mouse-over-popover`}
          className={classes.popover}
          classes={{
            paper: classes.paper,
          }}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <p style={{ fontSize: "14px", color: "rgb(11, 11, 77)" }}>
            Create custom template
          </p>
        </Popover>
      </div>
      <div className="smallScreen">
        <Fab
          variant="extended"
          aria-label="add"
          className={classes.fab}
          onClick={handleClick}
          color="primary"
        >
          <PostAddIcon className={classes.extendedIcon} />
          New template
        </Fab>
      </div>
    </div>
  );
}
