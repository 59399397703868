import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import LoadingAnimation from "../../components/Lottie/SmallLoadingAnimation";
//import icons
import AttachFileIcon from "@material-ui/icons/AttachFile";
import SendIcon from "@material-ui/icons/Send";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import DeleteIcon from "@material-ui/icons/Delete";
import SearchIcon from "@material-ui/icons/Search";
import DirectionsIcon from "@material-ui/icons/Directions";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    boxShadow: "none",
    margin: "20px 0 20px 10px",
  },
  input: {
    marginLeft: theme.spacing(1),
    fontSize: theme.typography.pxToRem(14),
    fontFamily: "Epilogue",
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

export default function UpdateInput({
  handleDelete,
  id,
  disabled,
  handleFileChange,
  placeholder,
  key,
  val,
  editedUpdateIsTheSame,
  handleChange,
  handleSubmit,
  isLoading,
}) {
  const classes = useStyles();

  return (
    <Paper
      component="form"
      className={classes.root}
      onSubmit={
        isLoading
          ? (e) => {
              e.preventDefault();
            }
          : handleSubmit
      }
    >
      <IconButton
        color="secondary"
        className={classes.iconButton}
        aria-label="directions"
        onClick={handleDelete}
        disabled={isLoading}
      >
        <DeleteIcon />
      </IconButton>
      <InputBase
        className={classes.input}
        placeholder="Type here..."
        inputProps={{ "aria-label": "search google maps" }}
        value={val}
        onChange={(e) => handleChange(e.target.value)}
        readOnly={isLoading}
      />
      {!isLoading && (
        <IconButton
          color="primary"
          type="submit"
          className={classes.iconButton}
          aria-label="search"
          disabled={val === ""}
        >
          {editedUpdateIsTheSame ? <CheckCircleIcon /> : <SendIcon />}
        </IconButton>
      )}
      {isLoading && <LoadingAnimation />}
      <Divider className={classes.divider} orientation="vertical" />
      <IconButton
        className={classes.iconButton}
        disabled={disabled || isLoading}
      >
        <label
          htmlFor={`selectFile-${id}`}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
          }}
        >
          <input
            type="file"
            id={`selectFile-${id}`}
            key={id}
            style={{ display: "none" }}
            disabled={disabled}
            onChange={handleFileChange}
            accept=".xls,.xlsx,.pptx,.png,.jpg,.jpeg,.pdf,.doc,.docx,.ai,.eps,.csv,.css,.html,.mp3,.txt,.zip,.gif,.exe,.ppt,.psd"
          />
          <AttachFileIcon style={{ width: 25, height: 23 }} />
        </label>
      </IconButton>
    </Paper>
  );
}

UpdateInput.propTypes = {
  handleDelete: PropTypes.func.isRequired,
};
