import React, { useState, useEffect } from "react";
import "./Timer.css";

function Timer(props) {
    const calculateTime = (minutes, seconds) => {
        let newSeconds;
        let newMinutes;
        if(minutes === 0 && seconds === 0) {
            return {
                newMinutes: 0,
                newSeconds: 0
            }
        }
        else if(seconds === 0) {
            newMinutes = minutes - 1;
            newSeconds = 59;
            return {
                newMinutes,
                newSeconds
            }
        } else {
            return {
                newMinutes: minutes,
                newSeconds: seconds - 1
            }
        }
    }

    const [time, setTime] = useState({
        minutes: props.minutes? Math.round(props.minutes) : 5,
        seconds: 0
    });

    useEffect(()=>{
        const timeout = setTimeout(()=>{
            const timeObj = calculateTime(time.minutes, time.seconds);
            setTime({
                minutes: timeObj.newMinutes,
                seconds: timeObj.newSeconds
            });
        }, 1000)

        return () => clearTimeout(timeout);
    });

    const convertTimeToString = (minutes, seconds) => {
        const minutesString = minutes.toString();
        const secondsString = seconds.toString();
        let longerSecondsString;
        if(secondsString.length < 2) {
            longerSecondsString = `0${secondsString}`;
        }

        if(longerSecondsString){
            return `${minutesString}:${longerSecondsString}`;
        }
        else {
            return `${minutesString}:${secondsString}`;
        }
    }
    

  return (
    <div id="timer">
      <h1>{time.minutes === 0 && time.seconds === 0 ? "Time's up!" : convertTimeToString(time.minutes, time.seconds)}</h1>
    </div>
  );
}

export default Timer;
