import React from 'react';
import loadingAnimation from '../../assets/animations/loadingWheel.json';
import Lottie from './LottieAnimation';

export default function Comp() {
    return (
        <div>
            <Lottie lotti={loadingAnimation} height={20} width={20}/>
        </div>
    )
}