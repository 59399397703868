import React from 'react';
import PropTypes from 'prop-types';
//import styling
import './Tag.css';

function Tag(props){
    return (
        <div className="tag">
            <p>{props.txt}</p>
        </div>
    );
}

Tag.propTypes = {
    txt: PropTypes.string.isRequired
}

export default Tag;