import React, { useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Droppable } from "react-beautiful-dnd";
import "./AgendaContainers.css";

//import components
import AgendaPoint from "./AgendaPoint";

function AgendaContainer() {
  const agendaList = useSelector((state) => state.newAgenda);
  const dispatch = useDispatch();

  return (
    <Droppable droppableId={"list"}>
      {provided => (
        <div
          className="agendaList"
          ref={provided.innerRef}
          {...provided.droppableProps}
        >
          {agendaList.map((agendaPoint, index) => {
            return (
              <AgendaPoint
                agendaPoint={agendaPoint}
                index={index}
                key={agendaPoint.timestamp.getTime().toString()}
              />
            );
          })}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
}

export default AgendaContainer;
