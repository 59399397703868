//import packages
import React, { useState } from "react";
import Alert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import CloseIcon from "@material-ui/icons/Close";
import { useSelector, useDispatch } from "react-redux";
//import styling
import "./Alert.css";
//import actions
import { editMeetingIdReset } from "../../actions/editAgendaAction";

function SuccessAlert(props) {
  const [open, setOpen] = useState(true);
  const dispatch = useDispatch();
  const meetingToEditID = useSelector((state) => state.editMeetingId);

  return (
    <div className="alert">
      <Collapse in={open}>
        <Alert
          variant="filled"
          severity="success"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpen(false);
                setTimeout(() => {
                  if (meetingToEditID) {
                    dispatch(editMeetingIdReset());
                  }
                }, 400);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {props.confirmation}
        </Alert>
      </Collapse>
    </div>
  );
}

export default SuccessAlert;
