import React from 'react';
import PropTypes from 'prop-types';

import './Input.css';
import PromptSwitch from '../Switch/Switch';

function InputField(props) {

    const onChange = ({ target }) => {
        const name = target.value;
        
        if(props.handleChange) {
            props.handleChange(name);
        }      
    }

    return (
        <div className="inputField">
            <input 
                type={props.inputType ? props.inputType : "text"}
                className="textInput" 
                value={props.val}
                onChange={onChange}
                onSubmit={props.handleSubmit} 
                placeholder={props.placeHolder ? props.placeHolder : "Type here"}
                readOnly={props.readOnly}
            />
        </div>
    );
}

InputField.propTypes = {
    // function to handle submit
    handleSubmit: PropTypes.func,
    // value passed into props, managed by container component
    val: PropTypes.string.isRequired,
    // the placeholder in the input field
    placeHolder: PropTypes.string,
    // function to handle change for e.g. updating parent or siblings
    handleChange: PropTypes.func,
    // by default, input type is text, but it can be set to another one as well
    inputType: PropTypes.string,
    // readOnly bool
    readOnly: PropTypes.bool,
}

export default InputField;