import React from "react";
import { useHistory } from "react-router-dom";
import "../404NotFound/NotFound.css";
//import components
import LottieAnimation from "../../components/Lottie/LottieAnimation";
import NavBar from "../../components/NavBar/NavBar";
import Button from "../../components/buttons/FullButton";
//import assets
import notfound from "../../assets/animations/not-found.json";

function NotFound() {
  const history = useHistory();

  const backHome = () => {
    let path = "/";
    history.push(path);
  };

  return (
    <div>
      <NavBar />
      <div className="centerColumn">
        <h1>Uh-oh... an error occurred!</h1>
        <LottieAnimation lotti={notfound} height={300} width={300} />
        <Button txt="Go back home" handleClick={backHome} />
      </div>
    </div>
  );
}

export default NotFound;
