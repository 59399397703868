const initialState = [];

const editUpdatesReducer = (state = initialState, action) => {
  switch (action.type) {
    case "EDIT_UPDATE_ADD":
      // expects entire update object
      return [...state, {...action.payload}];
    case "EDIT_UPDATETEXT_MODIFY":
      //TODO handle file edit/delete
      // expects a topicID, updateText, and timestamp
      return state.map((update) => {
        const isTargetUpdate =
          update.timestamp === action.payload.timestamp;
        if (isTargetUpdate) {
          return {
            ...update,
            updateText: action.payload.updateText,
          };
        } else {
          return update;
        }
      });

    case "EDIT_UPDATE_REMOVE":
      // this is for when the user deletes their update
      // expects a topicID and timestamp
      // compare editUpdateTags with this state later to see which ones have been deleted
      return state.filter((update) => {
        const isTargetUpdate =
          update.timestamp === action.payload.timestamp;
        return !isTargetUpdate;
      });
    case "EDIT_UPDATES_RESET":
      return initialState;
    default:
      return state;
  }
};

export default editUpdatesReducer;
