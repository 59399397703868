const initState = {
    deleteFilesError: null,
  };
  
  const deleteFilesReducer = (state = initState, action) => {
    switch (action.type) {
      case "DELETE_FILES_SUCCESS":
        console.log("Files successfully deleted");
        return state;
      case "DELETE_FILES_ERROR":
        return {
          ...state,
          deleteFilesError: `Action failed. Error: ${action.err.message}`,
        };
      default:
        return state;
    }
  };
  
  export default deleteFilesReducer;
  