import React, { useState } from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Divider from "@material-ui/core/Divider";
import NavBar from "../../components/NavBar/NavBar";
import { makeStyles } from "@material-ui/core/styles";
import ReactPlayer from "react-player"
import './VideoLib.css';

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    margin: "20px 0 20px 0px",
  },
  accordion: {
    backgroundColor: "rgba(255, 255, 255, 0.6)",
    boxShadow: "none",
    border: "0px solid lightGrey",
  },
  iconButton: {
    padding: 10,
  },
}));

function VideoSection(props) {
  const [state, setState] = useState(null);
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const comments = props.comments;

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? true : false);
  };

  return (
    <div className={classes.root}>
      <Accordion
        className={classes.accordion}
        expanded={expanded}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <h3>{props.title}</h3>
            <Divider />
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div style={{display:"flex", alignItems:"flex-start"}}>
          <div className="wrapper">
            <ReactPlayer url={props.videoSrc} controls className="player"/>
          </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default function VideoLib() {
  const embed1 =
    "https://player.vimeo.com/video/543590100";
  const embed2 =
    "https://player.vimeo.com/video/543621060";
  const embed3 =
    "https://player.vimeo.com/video/543631433";

  const videos = [
    {
      title: "Creating an agenda",
      link: embed1,
    },
    {
      title: "Sending updates and making a meeting read-only",
      link: embed2,
    },
    {
      title: "Using and creating templates",
      link: embed3,
    },
  ];
  return (
    <div>
      <NavBar />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginTop: "40px",
          width: "80vw",
          marginLeft: "auto",
          marginRight: "auto",
          marginBottom: "10vh",
        }}
      >
        <h1>Video guide</h1>
        {videos.map((video, index) => {
          return <VideoSection title={video.title} videoSrc={video.link} />;
        })}
      </div>
    </div>
  );
}
