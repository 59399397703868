import React, { useState } from "react";
import { useSelector, useDispatch, connect } from "react-redux";
import { firestoreConnect, useFirebase } from "react-redux-firebase";
import { compose } from "redux";
import { Redirect, useHistory } from "react-router-dom";
import {
  templateSwitch,
  templateTitleEdit,
  templateDescriptionEdit,
  templateCreateSuccess,
  templateReset,
} from "../../actions/templateActions";
import { createStripeCheckout } from "../../actions/paymentsActions";
import { DragDropContext } from "react-beautiful-dnd";

//import styling
import "../Agenda/AgendaCreator/AgendaCreator.css";
import "react-calendar/dist/Calendar.css";

//import components
import TemplateContainer from "../../containers/Template/TemplateContainer";
import TitleInput from "../../components/Input/TitleInput";
import NavBar from "../../components/NavBar/NavBar";
import Input from "../../components/Input/InputField";
import FloatingActionButton from "../../components/FloatingActionButton/ExtendedFloating";
import TemplateImgContainer from "../../containers/Template/SelectTemplateImgContainer";
import ErrorSnackbar from "../../components/Snackbar/ErrorSnackbar";

//import screens
import LoadingScreen from "../LoadingScreen/LoadingScreen";

function TemplateCreator({ auth, firestore }) {
  const firebase = useFirebase();
  const analytics = firebase.analytics();
  const history = useHistory();
  const templateList = useSelector((state) => state.newTemplate);
  const templateTitle = useSelector((state) => state.templateTitle);
  const templateDescription = useSelector((state) => state.templateDescription);
  const templateImg = useSelector((state) => state.templateImg);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const firestoreData = useSelector((state) => state.firestore.data.users);
  const userDoc = firestoreData ? firestoreData[auth.uid] : null;
  const userData = userDoc ? userDoc.userData : null;
  const userHasTemplates = userDoc ? userDoc.templates : false;
  const userTemplates = userHasTemplates ? userDoc.templates : [];
  const [err, setErr] = useState({});
  const isEmpty =
    templateList.length === 1 && templateList[0].title === "";

  const handleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const targetIndex = result.destination.index;
    const sourceTimeStamp = result.draggableId;
    const sourceObj = templateList.find(
      (obj) => obj.timestamp.getTime().toString() === sourceTimeStamp
    );
    dispatch(templateSwitch(sourceObj, targetIndex));
  };

  const handleTitleChange = (val) => {
    dispatch(templateTitleEdit(val));
  };

  const handleDescriptionChange = (val) => {
    dispatch(templateDescriptionEdit(val));
  };

  const profilePicture = auth.photoURL
    ? auth.photoURL
    : userData.profilePicture;

  const handleSubmit = async () => {
    setLoading(true);
    const newTemplate = {
      title: templateTitle,
      description: templateDescription ? templateDescription : "Your custom template",
      topics: templateList,
      imageUrl: templateImg,
    };
    try {
      await firestore
        .collection("users")
        .doc(auth.uid)
        .update({ templates: [...userTemplates, newTemplate] });
      dispatch(templateCreateSuccess());
      analytics.logEvent('template_created');
    } catch (e) {
      setLoading(false);
      setErr(e);
      console.log(e);
      return;
    }

    dispatch(templateReset);

    setTimeout(() => {
      history.push("/");
    }, 1500);

    //setLoading(false);
  };

  const handleReset = () => {
    dispatch(templateReset);
  };

  //redirect user if they're not logged in
  if (!auth.uid) return <Redirect to="/login" />;
  if (loading) return <LoadingScreen />;
  if (!firestoreData) return <LoadingScreen />;
  else {
    const hasSubscription = firestoreData[auth.uid].hasSubscription;

    // if the user does not have a subscription, then redirect them to the Stripe checkout
    if (!hasSubscription) {
      dispatch(createStripeCheckout());
      return <LoadingScreen />;
    }
    return (
      <div style={{maxWidth: "100vw"}}>
        <NavBar />
        <div className="agendaDiv">
          <div className="leftDiv">
            <h5 id="instructionTitle">Create your template</h5>
            <TitleInput
              val={templateTitle}
              handleChange={handleTitleChange}
              isTemplateTitle={true}
            />
            <TemplateImgContainer />
            <div id="meetingMetaDataSection">
              <Input
                placeHolder="Describe here what your template is about"
                handleChange={handleDescriptionChange}
                val={templateDescription}
              />
            </div>

            <br />
            <DragDropContext onDragEnd={handleDragEnd}>
              <TemplateContainer />
            </DragDropContext>
          </div>
          <FloatingActionButton
            txt="Create template"
            handleClick={handleSubmit}
            showOnBigScreen={true}
            showCheckIcon={true}
            disabled={isEmpty}
          />
        </div>
        <ErrorSnackbar
          msg={err.message}
          open={err.message ? true : false}
          handleClose={() => {
            setErr({});
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebaseReducer.auth,
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect((props) => {
    return [{ collection: "users", doc: props.auth.uid }];
  })
)(TemplateCreator);
