const initState = "New meeting";

const agendaTitleReducer = (state = initState, action) => {
    switch(action.type){
        case 'AGENDA_TITLE_EDIT':
            return action.payload;
        case 'AGENDA_TITLE_RESET':
            return initState;
        default:
            return state;
    }
}

export default agendaTitleReducer;