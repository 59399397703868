import React, { useState } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
//import styling
import "./MeetingPrompt.css";
//import components
import FixedToolBar from "../../components/in-meeting/FixedToolBar/FixedToolBar";
import Timer from "../../components/in-meeting/Timer/Timer";
import PromptGrid from "../../containers/MeetingPrompt/PromptGrid";
import NoUpdatesTile from "../../components/in-meeting/PromptGridTile/NoUpdatesGridTile";
// import utils
import { updates } from "../../utils/MeetingUtils/meetingData";

function MeetingPrompt({ auth }) {
  //redirect user if they're not logged in
  if(!auth.uid) return <Redirect to='/login' />

  return (
    <div className="meetingPromptWrapper">
      <div id="topicDetailsDiv">
        <Timer minutes={15} />
        <h1>Design</h1>
        <p>
          {updates.length > 0
            ? "Do you have any design updates? · 1 in 7 are done reading"
            : "Do you have any design updates?"}
        </p>
      </div>
      {updates.length > 0 ? <PromptGrid /> : <NoUpdatesTile />}
      <FixedToolBar />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebaseReducer.auth,
  }
}

export default connect(mapStateToProps)(MeetingPrompt);
