import React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { signOut } from "../../../actions/authActions";
import {
  createStripeCheckout,
  createPortalSession,
} from "../../../actions/paymentsActions";
import { red } from "@material-ui/core/colors";
//import styling
import "./DropDown.css";
//import components
import DropDownItem from "./DropDownItem";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
//import assets
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import SettingsIcon from "@material-ui/icons/Settings";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import BugReportIcon from "@material-ui/icons/BugReport";
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import RateReviewIcon from "@material-ui/icons/RateReview";

function DropDown(props) {
  const history = useHistory();

  const routeToSettings = () => {
    let path = "/settings";
    history.push(path);
  };

  const openFeatureRequestTypeform = () => {
    const url = "https://y6owmmpa1i1.typeform.com/to/BVKeJE24";
    window.open(url, "_blank").focus();
  };

  const openBugReportTypeform = () => {
    const url = "https://y6owmmpa1i1.typeform.com/to/z4Iy0pFC";
    window.open(url, "_blank").focus();
  };

  const hasSubscription = props.hasSubscription;
  return (
    <ClickAwayListener onClickAway={props.handleClickAway}>
      <div className="dropdown">
        {!hasSubscription && (
          <DropDownItem
            txt="Buy Prompt"
            handleClick={props.createStripeCheckout}
          >
            <CreditCardIcon />
          </DropDownItem>
        )}
        <DropDownItem
          txt="Request feature"
          handleClick={openFeatureRequestTypeform}
        >
          <RateReviewIcon />
        </DropDownItem>
        <DropDownItem txt="Report bug" handleClick={openBugReportTypeform}>
          <BugReportIcon />
        </DropDownItem>
        <DropDownItem txt="Video guide" handleClick={() => {history.push('/video-guide')}}>
          <VideoLibraryIcon />
        </DropDownItem>
        <DropDownItem txt="Settings" handleClick={routeToSettings}>
          <SettingsIcon />
        </DropDownItem>
        <DropDownItem txt="Log out" handleClick={props.signOut}>
          <ExitToAppIcon style={{ color: red[500] }} />
        </DropDownItem>
      </div>
    </ClickAwayListener>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    signOut: () => dispatch(signOut()),
    createStripeCheckout: () => dispatch(createStripeCheckout()),
    createPortalSession: () => dispatch(createPortalSession()),
  };
};

export default connect(null, mapDispatchToProps)(DropDown);
