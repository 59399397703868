import { joiningUsers } from './meetingData';

export const returnFirstName = (fullName) => {
    const nameArray = fullName.split(' ');
    const firstName = nameArray[0];
    return firstName;
}

export const returnLastName = (fullName) => {
    const nameArray = fullName.split(' ');
    const lastName = nameArray[nameArray.length - 1];
    return lastName;
}

export const returnUserData = (userId) => {
    for(let i = 0; i < joiningUsers.length; i++) {
        if(joiningUsers[i].id === userId) {
            const user = joiningUsers[i]
            return {
                fullName: user.fullName,
                src: user.imgUrl
            }
        }
    }
    throw new Error(`User ${userId} not found!`);
}