const initState = {
    agendaCreateError: null,
    newMeetingID: null,
  };
  
  const agendaCreateReducer = (state = initState, action) => {
    switch (action.type) {
      case "AGENDA_CREATE_SUCCESS":
        console.log("Agenda successfully created");
        console.log(action);
        return {
          ...state,
          authError: null,
          newMeetingID: action.meetingID,
        };
        case "DELETE_NEWMEETINGID":
          return {
            ...state,
            authError: null,
            newMeetingID: null,
          };
      case "AGENDA_CREATE_ERROR":
        return {
          ...state,
          agendaCreateError: `Action failed. Error: ${action.err.message}`,
        };
      default:
        return state;
    }
  };
  
  export default agendaCreateReducer;
  