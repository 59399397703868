export function generateInitials(fullName) {
  if (typeof fullName !== "string") {
    throw new Error(`Error 431: no string name provided. Instead: '${fullName}'`);
  }

  const nameArray = fullName.split(" ");

  let initialArray = [];
  for (let i = 0; i < nameArray.length; i++) {
    initialArray.push(nameArray[i][0]);
  }

  if (initialArray.length > 2) {
    return [initialArray[0], initialArray[initialArray.length - 1]].join("");
  }

  return initialArray.join("");
}
