const initialState = [];

const editUpdateTagsReducer = (state = initialState, action) => {
    switch(action.type){
        case 'ADD_EDIT_TAG_TO_UPDATE':
            return [
                ...state,
                action.payload,
            ];
        case 'REMOVE_EDIT_TAG_FROM_UPDATE':
            return state.filter(tag => {
                return tag.timestamp !== action.payload;
            });
        case 'EDIT_UPDATE_TAGS_RESET':
            return initialState;
        default:
            return state;
    }
}

export default editUpdateTagsReducer;