import React, { useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Droppable } from "react-beautiful-dnd";
import "../Agenda/AgendaContainers.css";

//import components
import TemplatePoint from "./TemplatePoint";

function TemplateContainer() {
  const templateList = useSelector((state) => state.newTemplate);
  const dispatch = useDispatch();

  return (
    <Droppable droppableId={"list"}>
      {provided => (
        <div
          className="agendaList"
          ref={provided.innerRef}
          {...provided.droppableProps}
        >
          {templateList.map((templatePoint, index) => {
            return (
              <TemplatePoint
                agendaPoint={templatePoint}
                index={index}
                key={templatePoint.timestamp.getTime().toString()}
              />
            );
          })}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
}

export default TemplateContainer;
