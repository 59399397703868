export const checkWhetherUpdatesAreEmpty = (state) => {
    const topicIsEmptyNotArray = [];

    state.forEach((topic) => {
        topicIsEmptyNotArray.push(checkWhetherTopicIsEmpty(topic.updates));
    })

    return topicIsEmptyNotArray.findIndex(x => x === true) < 0;
}

const checkWhetherTopicIsEmpty = (updates) => {
    const index = updates.findIndex((updateObj) => {
        return updateObj.updateText !== '';
    })

    if(index >= 0) {
        return true;
    } else {
        return false;
    }
}