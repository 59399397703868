import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import "./Skeletons.css";
import "../../containers/Dashboard/HorizontalTileSlider.css";

function SingleSkeleton() {
  return (
    <div className="tenmplateSkeleton">
      <Skeleton variant="rect" height={300} />
    </div>
  );
}

export default function MeetingTileSkeleton() {
  return (
    <div className="horizontalTileSlider">
      <SingleSkeleton />
      <SingleSkeleton />
      <SingleSkeleton />
      <SingleSkeleton />
    </div>
  );
}
