import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useFirestore } from "react-redux-firebase";
//import components
import Dialog from "../../components/Dialog/Dialog";
//imports assets
import promptBanner from "../../assets/logos/promptBanner2.png";
import example1 from "../../assets/videos/1-after-resize.mov";
import example2 from "../../assets/videos/2-after-resize.mov";
import example3 from "../../assets/videos/3-after-resize.mov";
//import utils
import { returnFirstName } from "../../utils/MeetingUtils/meetingUtils";

function IntroDialogContainer(props) {
  const hideOnboarding = useRef();
  hideOnboarding.current = true;
  const [dialogNo, setDialogNo] = useState(0);
  const [open, setOpen] = useState(false);
  const auth = useSelector((state) => state.firebaseReducer.auth);
  const name = auth ? auth.displayName : "";
  const firestore = useFirestore();
  const firestoreData = useSelector((state) => state.firestore.data.users);
  const currentUser =
    firestoreData && auth.uid ? firestoreData[auth.uid] : null;

  if (currentUser) {
    if(currentUser.meetings) {
        hideOnboarding.current = currentUser.seenOnboarding ? true : false;
    }
  }
  console.log(currentUser);
  console.log(`sawOnboarding: ${hideOnboarding.current}`);

  useEffect(() => {
    setOpen(!hideOnboarding.current);
  }, [hideOnboarding.current]);

  const handleOpen = () => {
    setOpen(true);
    setDialogNo(0);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const dialogData = [
    {
      title: name
        ? `Hi ${returnFirstName(name)}, welcome to Prompt! 👋`
        : "Hi there, welcome to Prompt! 👋",
      description:
        "In case you'd like to make your meetings more efficient, then you've come to the right place. I'll show you how to get started!",
      button1Test: null,
      imageUrl: promptBanner,
      showRobin: true,
    },
    {
      title: "1. Create and share your custom agenda 📝",
      description:
        "An effective meeting starts with an agenda. Easily create your own and share it with the people you're meeting!",
      button1Test: null,
      videoSrc: example1,
      showRobin: true,
    },
    {
      title: "2. Get everyone on the same page quickly 💬",
      description:
        "Share updates, questions, files, and ideas - before or during your meetings.",
      button1Text: null,
      videoSrc: example2,
      showRobin: true,
    },
    {
      title: "3. Make your meeting page read-only 🔒",
      description:
        "No ambiguity on 'who said what' - you can make your meeting page immutable after your meeting is over.",
      button1Text: null,
      videoSrc: example3,
      showRobin: true,
    },
  ];

  const lastDialog = dialogData.length - 1;

  const writeBoolean = () => {
    firestore.collection("users").doc(auth.uid).set(
      {
        seenOnboarding: true, 
      },
      { merge: true }
    );
  };

  const handleClick = () => {
    const newNo = dialogNo + 1;
    if (dialogNo !== lastDialog) {
      setDialogNo(newNo);
    } else {
      setOpen(false);
      writeBoolean();
    }
  };

  const {
    title,
    description,
    button1Text,
    imageUrl,
    videoSrc,
    showRobin,
  } = dialogData[dialogNo];

  return (
    <Dialog
      open={open}
      dialogTitle={title}
      dialogText={description}
      button1Text={button1Text}
      imageUrl={imageUrl}
      videoSrc={videoSrc}
      button2Text={dialogNo === lastDialog ? "Finish" : "Next"}
      showRobin={showRobin}
      handleOpen={handleOpen}
      handleClose={handleClose}
      handleClick={handleClick}
    />
  );
}

export default IntroDialogContainer;
