const initState = "https://cdn.sanity.io/images/f6osn1fn/production/df2ce7833f5d96ece19dd0862021ee2321339473-487x284.png";

const templateImgReducer = (state = initState, action) => {
    switch(action.type){
        case 'TEMPLATE_IMG_SET':
            return action.payload;
        case 'TEMPLATE_TITLE_RESET':
            return initState;
        default:
            return state;
    }
}

export default templateImgReducer;