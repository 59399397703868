const initState = {
    paymentsError: null,
  };
  
  const paymentsReducer = (state = initState, action) => {
    switch (action.type) {
      case "SESSION_CREATE_ERROR":
        console.log("Error in creating Stripe checkout session.");
        return {
          ...state,
          paymentsError: `Login failed. Error: ${action.err.message}`,
        };
      case "SESSION_CREATE_SUCCESS":
        console.log("Successfully created Stripe session!");
        return {
          ...state,
          paymentsError: null,
        };
      default:
        return state;
    }
  };
  
  export default paymentsReducer;