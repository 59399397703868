import React from "react";
//import components
import Avatar from "../../components/Avatar/Avatar";
//import styling
import "./Participants.css";
//import data
import { joiningUsers } from "../../utils/MeetingUtils/meetingData";

const ParticipantContainer = () => {
  const hasMoreThanFourParticipants = joiningUsers.length > 4;
  const extraUsers = joiningUsers.length - 4;
  return (
    <div id="participantContainer">
      {joiningUsers.map((user, index) => {
        if (index <= 3) {
          return (
            <Avatar key={user.id} fullName={user.fullName} src={user.imgUrl} />
          );
        }
      })}
      {hasMoreThanFourParticipants && <p id="extraUsers">+{extraUsers}</p>}
    </div>
  );
};

export default ParticipantContainer;
