import React, { useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Draggable } from "react-beautiful-dnd";
import "./AgendaContainers.css";

//import components
import InputContainer from "./InputContainer";

//import assets
import moveVertical from "../../assets/icons/move_vertical.png";
import trashIcon from "../../assets/icons/trash_full.png";

//import actions
import { agendaRemove } from "../../actions/newAgendaActions";

function AgendaPoint(props) {
  const agendaList = useSelector((state) => state.newAgenda);
  const hasMoreThanOneItem = agendaList.length > 1;
  const dispatch = useDispatch();

  const handleDelete = (index) => {
    const obj = {
      key: index,
    };

    dispatch(agendaRemove(obj));
  };

  return (
    <Draggable
      draggableId={props.agendaPoint.timestamp.getTime().toString()}
      index={props.index}
    >
      {(provided) => (
        <div
          className="agendaPoint"
          {...provided.draggableProps}
          ref={provided.innerRef}
        >
          <h2 className="agendaPointNo">{props.index + 1}.</h2>
          <InputContainer id={props.index} />
          {/* have to do it in this way to make sure dragHandle works */}
          <div className="agendaPointOptions">
            <div {...provided.dragHandleProps} className="iconHolder">
              <img draggable="false" className="icon" src={moveVertical} alt=""/>
            </div>
            {hasMoreThanOneItem && (
              <div onClick={() => handleDelete(props.index)} className="iconHolder">
                <img draggable="false" className="icon" src={trashIcon} alt=""/>
              </div>
            )}
          </div>
        </div>
      )}
    </Draggable>
  );
}

export default AgendaPoint;
