const initState = "New template";

const templateTitleReducer = (state = initState, action) => {
    switch(action.type){
        case 'TEMPLATE_TITLE_EDIT':
            return action.payload;
        case 'TEMPLATE_TITLE_RESET':
            return initState;
        default:
            return state;
    }
}

export default templateTitleReducer;