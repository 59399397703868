import React, { useState, useRef } from "react";
import { useSelector, useDispatch, connect } from "react-redux";
import { firestoreConnect, useFirebase } from "react-redux-firebase";
import { compose } from "redux";
import { Redirect } from "react-router-dom";
import {
  agendaSwitch,
  agendaTitleEdit,
  agendaDescriptionEdit,
  agendaDateEdit,
  agendaCreate,
  agendaReset,
} from "../../../actions/newAgendaActions";
import { createStripeCheckout } from "../../../actions/paymentsActions";
import { sendMeetingChanges } from "../../../actions/editAgendaAction";
import { DragDropContext } from "react-beautiful-dnd";
import { useHistory } from "react-router-dom";
import moment from "moment";

//import styling
import "./AgendaCreator.css";
import "react-calendar/dist/Calendar.css";

//import components
import AgendaContainer from "../../../containers/Agenda/AgendaContainer";
import FloatingMeetingSummary from "../../../containers/FloatingMeetingSummary/FloatingMeetingSummary";
import BottomMeetingSummary from "../../../containers/FloatingMeetingSummary/BottomMeetingSummary";
import TitleInput from "../../../components/Input/TitleInput";
import NavBar from "../../../components/NavBar/NavBar";
import FlatButton from "../../../components/buttons/FlatButton";
import Input from "../../../components/Input/InputField";
import WarningAlert from "../../../components/Alert/WarningAlert";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

//import utils
import DateFnsUtils from "@date-io/date-fns";
import "date-fns";

//import assets
import calendarIcon from "../../../assets/icons/calendar.png";

//import screens
import LoadingScreen from "../../LoadingScreen/LoadingScreen";
import { rememberMeetingDetails } from "../../../actions/meetingAccessActions";

function AgendaCreator({ auth, newMeetingID }) {
  //TODO: refactoring this page into another AgendaMetaData component causes weird bug --> find out why
  const firebase = useFirebase();
  const analytics = firebase.analytics();
  const agendaList = useSelector((state) => state.newAgenda);
  const agendaTitle = useSelector((state) => state.agendaTitle);
  const agendaDescription = useSelector((state) => state.agendaDescription);
  const agendaDate = useSelector((state) => state.agendaDate);
  const meetingToEditID = useSelector((state) => state.editMeetingId);
  const dispatch = useDispatch();
  const [calendarExpanded, setCalendarExpanded] = useState(false);
  const [loading, setLoading] = useState(false);
  const rememberedMeetingDetails = useSelector(
    (state) => state.rememberedMeetingDetails
  );
  const firestoreData = useSelector((state) => state.firestore.data.users);
  const userDoc = firestoreData ? firestoreData[auth.uid] : null;
  const userData = userDoc ? userDoc.userData : null;

  let datePickerDialog;

  const history = useHistory();

  const routeChange = (id) => {
    let path = `agenda/${id}`;
    history.push(path);
  };

  const handleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const targetIndex = result.destination.index;
    const sourceTimeStamp = result.draggableId;
    const sourceObj = agendaList.find(
      (obj) => obj.timestamp.getTime().toString() === sourceTimeStamp
    );
    dispatch(agendaSwitch(sourceObj, targetIndex));
  };

  const handleTitleChange = (val) => {
    dispatch(agendaTitleEdit(val));
  };

  const handleDescriptionChange = (val) => {
    dispatch(agendaDescriptionEdit(val));
  };

  const handleDateChange = (date) => {
    dispatch(agendaDateEdit(date));
    setCalendarExpanded(false);
  };

  const handleChangeDateButtonClick = () => {
    setCalendarExpanded((old) => {
      return !old;
    });
  };

  const authPhoto = auth ? auth.photoURL : null;
  const userPhoto = userData ? userData.profilePicture : "";
  const profilePicture = authPhoto ? authPhoto : userPhoto;

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await dispatch(
        agendaCreate({
          meetingTitle: agendaTitle,
          meetingDescription: agendaDescription,
          meetingDate: agendaDate,
          agendaPoints: agendaList,
          userID: auth.uid,
          profilePicture: profilePicture ? profilePicture : "",
          userName: auth.displayName ? auth.displayName : userData.fullName,
        })
      );
      analytics.logEvent('meeting_created');
    } catch (err) {
      console.log(err);
      return;
    }

    dispatch(agendaReset);

    const timeout = await setTimeout(() => {
      // waiting so I can wait for the newMeetingID
    }, 1500);

    //setLoading(false);
  };

  const handleAgendaChangesSubmit = () => {
    setLoading(true);
    dispatch(
      sendMeetingChanges({
        meetingTitle: agendaTitle,
        meetingDescription: agendaDescription,
        meetingDate: agendaDate,
        agendaPoints: agendaList,
      })
    );

    dispatch(agendaReset);
  };

  const handleReset = () => {
    dispatch(agendaReset);
  };

  //redirect user if they're not logged in
  if (!auth.uid) return <Redirect to="/login" />;
  if (newMeetingID) return <Redirect to={`/agenda/${newMeetingID}`} />;

  if (!firestoreData) return <LoadingScreen />;
  else {
    const hasSubscription = firestoreData[auth.uid].hasSubscription;

    // if the user does not have a subscription, then redirect them to the Stripe checkout
    if (!hasSubscription) {
      dispatch(createStripeCheckout());
      return <LoadingScreen />;
    }
    if (loading) {
      return <LoadingScreen />;
    }
    return (
      <div id="agendaCreator">
        <NavBar />
        <div className="agendaDiv">
          <div className="leftDiv">
            <h5 id="instructionTitle">
              {meetingToEditID ? "Edit your agenda" : "Create your agenda"}
            </h5>
            <TitleInput val={agendaTitle} handleChange={handleTitleChange} />
            <div id="meetingMetaDataSection">
              <div id="editableAgendaDateSection">
                <img className="icon" alt="" src={calendarIcon} />
                <p>
                  <span className="dontShowOnMobile">Meeting takes place on{" "}</span>
                  <b>{moment(agendaDate).format("LL")}</b>
                </p>
                <FlatButton
                  handleClick={handleChangeDateButtonClick}
                  txt={calendarExpanded ? "done" : "change"}
                />
              </div>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  open={calendarExpanded}
                  onClose={() => setCalendarExpanded(false)}
                  inputRef={(r) => (datePickerDialog = r)}
                  firstDayOfWeek={0}
                  onChange={(date) => handleDateChange(date)}
                  minDate={new Date()}
                  style={{ display: "none" }}
                  initialDate={agendaDate}
                />
              </MuiPickersUtilsProvider>
              <Input
                placeHolder="Describe here what your meeting is about"
                handleChange={handleDescriptionChange}
                val={agendaDescription}
              />
              {meetingToEditID && (
                <WarningAlert warning="Deleting agenda points may lead to a loss of updates" />
              )}
            </div>
            <br />
            <DragDropContext onDragEnd={handleDragEnd}>
              <AgendaContainer />
            </DragDropContext>
          </div>
          <div className="rightDiv">
            <FloatingMeetingSummary
              handleSubmit={
                meetingToEditID ? handleAgendaChangesSubmit : handleSubmit
              }
              handleReset={handleReset}
              pwd={rememberedMeetingDetails.pwd}
            />
          </div>
        </div>
        <BottomMeetingSummary
          handleSubmit={
            meetingToEditID ? handleAgendaChangesSubmit : handleSubmit
          }
          pwd={rememberedMeetingDetails.pwd}
          handleReset={handleReset}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebaseReducer.auth,
    newMeetingID: state.agendaCreate.newMeetingID,
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect((props) => {
    return [{ collection: "users", doc: props.auth.uid }];
  })
)(AgendaCreator);
