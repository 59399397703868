import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useFirebase } from "react-redux-firebase";
import "../404NotFound/NotFound.css";
//import components
import LottieAnimation from "../../components/Lottie/LottieAnimation";
import NavBar from "../../components/NavBar/NavBar";
import Button from "../../components/buttons/FullButton";
//import assets
import partyPopper from "../../assets/animations/party_popper.json";

function CheckoutSuccess() {
  const history = useHistory();
  const firebase = useFirebase();
  const analytics = firebase.analytics();

  useEffect(() => {
    analytics.logEvent('checkout_success');
  }, []);

  const backHome = () => {
    let path = "/";
    history.push(path);
  };

  return (
    <div>
      <NavBar />
      <div className="centerColumn">
        <h1>Welcome to Prompt!</h1>
        <LottieAnimation lotti={partyPopper} height={300} width={300} />
        <Button txt="Go back home" handleClick={backHome} />
      </div>
    </div>
  );
}

export default CheckoutSuccess;
