const initState = {
  fullName: '',
  email: '',
  imgUrl: '',
  oldPassword: '',
  newPassword: '',
  repeatNewPassword: '',
  companyName: '',
  vatNumber: '',
  companyAddress: '',
};

const settingsReducer = (state = initState, action) => {
  switch (action.type) {
    case "SETTINGS_SET_INIT_STATE":
      return action.payload;
    case "SETTINGS_SET_FULL_NAME":
      return {
        ...state,
        fullName: action.payload,
      };
    case "SETTINGS_SET_EMAIL":
      return {
        ...state,
        email: action.payload,
      };
    case "SETTINGS_SET_IMG_URL":
      return {
        ...state,
        imgUrl: action.payload,
      };
    case "SETTINGS_SET_OLD_PASSWORD":
      return {
        ...state,
        oldPassword: action.payload,
      };
    case "SETTINGS_SET_NEW_PASSWORD":
      return {
        ...state,
        newPassword: action.payload,
      };
    case "SETTINGS_SET_REPEAT_NEW_PASSWORD":
      return {
        ...state,
        repeatNewPassword: action.payload,
      };
    case "SETTINGS_SET_COMPANY_NAME":
      return {
        ...state,
        companyName: action.payload,
      };
    case "SETTINGS_SET_VAT_NUMBER":
      return {
        ...state,
        vatNumber: action.payload,
      };
    case "SETTINGS_SET_COMPANY_ADDRESS":
      return {
        ...state,
        companyAddress: action.payload,
      };
    default:
      return state;
  }
};

export default settingsReducer;
