export const topicAdd = (topicID) => {
    return {
      type: "TOPIC_ADD",
      payload: topicID,
    };
};

export const updateAdd = (obj) => {
    return {
      type: "UPDATE_ADD",
      // expects a uid, imgUrl, fullName, and a topicID
      payload: obj,
    };
};

export const updateModify = (obj) => {
    return {
      type: "UPDATE_MODIFY",
      // expects a topicID, updateText, and timestamp
      payload: obj,
    };
};

export const updateRemove = (obj) => {
    return {
      type: "UPDATE_REMOVE",
      // expects a topicID and timestamp
      payload: obj,
    };
};

export const sendNewUpdate = (updateTimestamp, meetingID, topicID) => {
  return async (dispatch, getState, { getFirebase }) => {
    // Get the comment instead
    const topic = getState().updates.find(t => {
        return t.topicID === topicID;
    });

    if(!topic) {
      console.log('No topic found.');
      return;
    }

    const update = topic.updates.find(u => {
      return u.timestamp === updateTimestamp;
    });

    if(!update) {
      console.log('Update not found.');
      return;
    }

    try {
      // initialize Firebase
      const firebase = getFirebase();
      const path = `meetings/${meetingID}/topics/${topicID}/updates/${updateTimestamp}`;
      const dbRef = firebase.database().ref(path);
      const res = await dbRef.set(update);
      dispatch({ type: "SENDING_UPDATES_SUCCESS", res });
    } catch (err) {
      console.log(`Error in sending update changes! Error: ${err.message}`)
      dispatch({ type: "SENDING_UPDATES_ERROR", err });
    }
  };
};

export const updateAnUpdate = (update, meetingID, topicID) => {
  return async (dispatch, getState, { getFirebase }) => {
    try {
      // initialize Firebase
      const firebase = getFirebase();
      const path = `meetings/${meetingID}/topics/${topicID}/updates/${update.timestamp}`;
      const dbRef = firebase.database().ref(path);
      const res = await dbRef.update({updateText: update.updateText});
      dispatch({ type: "SENDING_UPDATES_SUCCESS", res });
    } catch (err) {
      console.log(`Error in sending update changes! Error: ${err.message}`)
      dispatch({ type: "SENDING_UPDATES_ERROR", err });
    }
  };
};

export const deleteAnUpdate = (timestamp, meetingID, topicID) => {
  return async (dispatch, getState, { getFirebase }) => {
    try {
      // initialize Firebase
      const firebase = getFirebase();
      const path = `meetings/${meetingID}/topics/${topicID}/updates/${timestamp}`;
      const dbRef = firebase.database().ref(path);
      const res = await dbRef.remove();
      dispatch({ type: "SENDING_UPDATES_SUCCESS", res });
    } catch (err) {
      console.log(`Error in sending update changes! Error: ${err.message}`)
      dispatch({ type: "SENDING_UPDATES_ERROR", err });
    }
  };
};

export const updatesSend = (obj) => {
  return async (dispatch, getState, { getFirestore }) => {
    try {
      const firestore = getFirestore();

      const res = await firestore
        .collection("pendingUpdates")
        .add({
          userID: getState().firebaseReducer.auth.uid,
          meetingID: obj.meetingID,
          updates: obj.updates,
          documentTimestamp : new Date(),
        });
      dispatch({ type: "SENDING_UPDATES_SUCCESS", res });
    } catch (err) {
      dispatch({ type: "SENDING_UPDATES_ERROR", err });
    }
  };
};

export const resetUpdates = () => {
  return {
    type: "UPDATES_RESET",
  }
}