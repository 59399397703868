//import utils
import {
  returnFirstName,
  returnLastName,
} from "../utils/MeetingUtils/meetingUtils";
import { generateInitials } from "../utils/avatarUtils";

export const settingsSetInitState = (initState) => {
  return {
    type: "SETTINGS_SET_INIT_STATE",
    payload: initState,
  };
};

export const settingsSetFullName = (fullName) => {
  return {
    type: "SETTINGS_SET_FULL_NAME",
    payload: fullName,
  };
};

export const settingsSetEmail = (email) => {
  return {
    type: "SETTINGS_SET_EMAIL",
    payload: email,
  };
};

export const settingsSetImgUrl = (imgUrl) => {
  return {
    type: "SETTINGS_SET_IMG_URL",
    payload: imgUrl,
  };
};

export const settingsSetOldPassword = (password) => {
  return {
    type: "SETTINGS_SET_OLD_PASSWORD",
    payload: password,
  };
};

export const settingsSetNewPassword = (password) => {
  return {
    type: "SETTINGS_SET_NEW_PASSWORD",
    payload: password,
  };
};

export const settingsSetRepeatNewPassword = (password) => {
  return {
    type: "SETTINGS_SET_REPEAT_NEW_PASSWORD",
    payload: password,
  };
};

export const settingsSetCompanyName = (companyName) => {
  return {
    type: "SETTINGS_SET_COMPANY_NAME",
    payload: companyName,
  };
};

export const settingsSetVatNumber = (vatNumber) => {
  return {
    type: "SETTINGS_SET_VAT_NUMBER",
    payload: vatNumber,
  };
};

export const settingsSetCompanyAddress = (companyAddress) => {
  return {
    type: "SETTINGS_SET_COMPANY_ADDRESS",
    payload: companyAddress,
  };
};

export const resetSettingsError = () => {
  return {
    type: "RESET_SETTINGS_ERROR",
  }
}

export const setInitStateReducer = (initState) => {
  return {
    type: "SETTINGS_SET_INIT_STATE_REDUCER",
    payload: initState
  }
}

export const settingsSubmit = (oldFullName, oldImgUrl) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    try {
      // TODO:
      // upload new profile picture to Firebase Storage if it changed

      const {
        fullName,
        email,
        imgUrl,
        newPassword,
        repeatNewPassword,
        companyName,
        vatNumber,
        companyAddress,
      } = getState().settings;
      const firebase = getFirebase();
      const firestore = getFirestore();
      const uid = getState().firebaseReducer.auth.uid;

      await firestore
        .collection("users")
        .doc(uid)
        .update({
          userData: {
            fullName: fullName ? fullName : oldFullName,
            firstName: fullName
              ? returnFirstName(fullName)
              : returnFirstName(oldFullName),
            lastName: fullName
              ? returnLastName(fullName)
              : returnFirstName(oldFullName),
            initials: fullName
              ? generateInitials(fullName)
              : generateInitials(oldFullName),
            profilePicture: imgUrl ? imgUrl : oldImgUrl,
          },
          companyData: {
            companyName,
            vatNumber,
            companyAddress,
          },
        });
      console.log("User collection updated ✅");
      settingsSetFullName(fullName ? fullName : oldFullName);

      //get user
      const user = firebase.auth().currentUser;

      //update email
      if (email.length > 5) {
        await user.updateEmail(email);
        console.log("User's email is updated ✅");
      }

      const passwordsAreTheSame = newPassword === repeatNewPassword;
      const passwordsAreNotEmpty =
        (newPassword !== "") & (repeatNewPassword !== "");
      const passwordIsTheRightLength = newPassword.length >= 6;
      if (
        passwordsAreTheSame &&
        passwordsAreNotEmpty &&
        passwordIsTheRightLength
      ) {
        await user.updatePassword(newPassword);
        console.log("Password updated! ✅");
      }

      //update userProfile
      await user.updateProfile({
        displayName: fullName ? fullName : oldFullName,
        photoURL: imgUrl ? imgUrl : oldImgUrl,
      });
      console.log("fullName and photoURL auth updated! ✅");

      dispatch({ type: "SETTINGS_SEND_SUCCESS" });
    } catch (err) {
      dispatch({ type: "SETTINGS_SEND_ERROR", err });
    }
  };
};
