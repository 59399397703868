import React from "react";
import "./Button.css";
import PropTypes from "prop-types";

//TODO: make inactive color

class FlatButton extends React.Component {
  constructor(props) {
    super(props);
    this.getStyles = this.getStyles.bind(this);
  }

  getStyles() {
    if (this.props.disabled) {
      return { color: "lightGrey" };
    } else {
      return {};
    }
  }

  render() {
    return (
      <button
        className="flatButton promptButton"
        onClick={this.props.handleClick}
        disabled={this.props.disabled}
        style={this.getStyles()}
      >
        <div className="contentHolder">
          {this.props.srcBefore && (
            <img
              className="icon-before icon"
              src={
                this.props.disabled
                  ? this.props.srcBeforeDisabled
                  : this.props.srcBefore
              }
              alt=""
            />
          )}
          {this.props.txt ? this.props.txt : "Click here"}
          {this.props.srcAfter && (
            <img className="icon-after icon" src={this.props.srcAfter} />
          )}
        </div>
      </button>
    );
  }
}

FlatButton.propTypes = {
  // the text in the button
  txt: PropTypes.string.isRequired,
  // the onClick function -- enter null if nothing
  handleClick: PropTypes.func.isRequired,
  // the link to the icon before text
  srcBefore: PropTypes.string,
  // the link to the icon after the text
  srcAfter: PropTypes.string,
  //true if button should be disabled
  disabled: PropTypes.bool,
};

export default FlatButton;
