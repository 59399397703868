import React, { useEffect, useState, useRef } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { connect, useSelector, useDispatch } from "react-redux";
import { useFirebase, firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
//import styling
import "./Dashboard.css";
//import components
import TemplateTileContainer from "../../containers/Dashboard/TemplateTileContainer";
import MeetingTileContainer from "../../containers/Dashboard/MeetingTileContainer";
import NoMeetingsTile from "../../components/MeetingTile/NoMeetingsTile";
import NavBar from "../../components/NavBar/NavBar";
import Footer from "../../components/Footer/Footer";
import IntroDialogContainer from "../../containers/IntroDialogContainer/IntroDialogContainer";
import TemplateSkeleton from "../../components/Skeletons/TemplateSkeleton";
import MeetingTileSkeleton from "../../components/Skeletons/MeetingTileSkeleton";
import FloatingActionButton from "../../components/FloatingActionButton/ExtendedFloating";
import InlineFab from "../../components/FloatingActionButton/ExtendedInline";
import SuccessSnackbar from "../../components/Snackbar/SuccessSnackbar";
import ErrorSnackbar from "../../components/Snackbar/ErrorSnackbar";
//import utils
import { isWithinAWeek } from "../../utils/Dashboard/dashboardUtils";
//import screens
import LoadingScreen from "../LoadingScreen/LoadingScreen";
//import actions
import {
  templatesFetch,
  wipeTemplateCreateSuccess,
} from "../../actions/templateActions";

function Dashboard({ auth, firestore }) {
  const [copied, setCopied] = useState(false);
  const [err, setErr] = useState(null);
  const firebase = useFirebase();
  const dispatch = useDispatch();
  const history = useHistory();
  const firestoreData = useSelector((state) => state.firestore.data.users);
  const sanityTemplates = useSelector((state) => state.sanityTemplates);
  const templateCreateSuccess = useSelector(
    (state) => state.templateCreate.templateCreateSuccess
  );
  const [snackbarOpen, setSnackbarOpen] = useState(true);
  const filterMeetingData = (data) => {
    const returnArr = data.filter((meeting) => {
      return isWithinAWeek(meeting.date.seconds);
    });
    console.log(returnArr);
    return returnArr;
  };

  const fetchMeetingLink = async (meetingID) => {
    try {
      setErr(null);

      const userData = firestoreData ? firestoreData[auth.uid] : null;
      const userMeetings = userData ? userData.meetings : null;
      let meeting;

      if(userMeetings) {
        meeting  = userMeetings.find(meetingObj => {
          return meetingObj.meetingID === meetingID
        })
      }

      const pwd = meeting.pwd;
      console.log(`pwd: ${pwd}`);
      const shareLink = "https://app.getprompt.io/j/" + meetingID + "/" + pwd;
      navigator.clipboard.writeText(shareLink).catch((err) => console.log(err));

      // show copy confirmation
      setCopied(true);
    } catch (e) {
      setErr(e.message);
    }
  };

  const handleSnackbarClose = (e, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setCopied(false);
  };

  const fetchTemplates = () => {
    dispatch(templatesFetch());
  };

  useEffect(() => {
    fetchTemplates();
  }, []);

  //redirect user if they're not logged in
  if (!auth.uid) return <Redirect to="/login" />;

  const currentUser =
    firestoreData && auth.uid ? firestoreData[auth.uid] : null;
  console.log(currentUser);
  console.log(`firestoreData: ${firestoreData}`);
  const userTemplates = currentUser ? currentUser.templates : [];
  const meetings = firestoreData && currentUser ? currentUser.meetings : [];
  const hasMeetings = meetings ? meetings.length > 0 : false;
  const showMeetings = firestoreData && hasMeetings;
  const showEmptyMeetingState = firestoreData && !hasMeetings;
  const hasMeetingsComingWeek =
    meetings && filterMeetingData(meetings).length > 0;
  const hasCustomTemplates = false;

  return (
    <div className="dashboard">
      {<IntroDialogContainer />}
      <NavBar showNewMeetingButton={true} />
      <div className="dashboardBody">
        {hasCustomTemplates && (
          <div className="sectionTitleDiv">
            <h2>Your custom templates</h2>
            <p>
              An efficient meeting starts with an agenda; pick a template to
              plan your meeting.
            </p>
          </div>
        )}
        {hasCustomTemplates && (
          <div className="slider">
            <TemplateTileContainer />
          </div>
        )}
        <div className="sectionTitleDiv">
          <div
            style={{
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            <div>
              <h2>Start with an agenda template</h2>
              <p>
                An efficient meeting starts with an agenda; pick a template to
                plan your meeting.
              </p>
            </div>
            <InlineFab
              handleClick={() => {
                history.push("/new-template");
              }}
            />
          </div>
        </div>
        {sanityTemplates.templates && (
          <div className="slider">
            <TemplateTileContainer
              data={
                userTemplates
                  ? [...userTemplates, ...sanityTemplates.templates]
                  : sanityTemplates.templates
              }
            />
          </div>
        )}
        {!sanityTemplates.templates && (
          <div className="templateTileSkeletonDiv">
            <TemplateSkeleton />
          </div>
        )}
        {hasMeetingsComingWeek && <br />}
        {hasMeetingsComingWeek && (
          <div className="sectionTitleDiv">
            <h2>Upcoming meetings</h2>
            <p>Your meetings during the upcoming 7 days.</p>
          </div>
        )}
        {hasMeetingsComingWeek && (
          <div className="slider">
            <MeetingTileContainer
              onlyNextSevenDays={true}
              uid={auth.uid}
              meetings={meetings}
              handleShare={(meetingID) => fetchMeetingLink(meetingID)}
            />
          </div>
        )}
        <br />
        <br />
        <div className="sectionTitleDiv">
          <h2>Your meetings</h2>
          <p>Meetings you’ve created or been invited to.</p>
        </div>
        {!firestoreData && (
          <div className="templateTileSkeletonDiv">
            <MeetingTileSkeleton />
          </div>
        )}
        {showMeetings && (
          <div className="slider">
            <MeetingTileContainer
              uid={auth.uid}
              meetings={meetings}
              handleShare={(meetingID) => fetchMeetingLink(meetingID)}
            />
          </div>
        )}
        <br />
        <br />
        {showEmptyMeetingState && (
          <div className="noMeetingsTileGridDiv">
            <NoMeetingsTile />
          </div>
        )}
        <br />
        <br />
        <FloatingActionButton
          txt="New meeting"
          handleClick={() => {
            history.push("/create");
          }}
        />
      </div>
      <SuccessSnackbar
        msg="Link copied!"
        open={copied}
        handleClose={handleSnackbarClose}
      />
      <ErrorSnackbar msg={err} open={err} handleClose={() => setErr(null)} />
      {templateCreateSuccess && (
        <SuccessSnackbar
          msg="Template successfully created!"
          open={snackbarOpen}
          handleClose={() => {
            setSnackbarOpen(false);
            dispatch(wipeTemplateCreateSuccess());
          }}
        />
      )}
      <Footer />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebaseReducer.auth,
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect((props) => {
    return [{ collection: "users", doc: props.auth.uid }];
  })
)(Dashboard);
