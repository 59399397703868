import React, { useEffect, useState, useRef } from "react";
import NavBar from "../../../components/NavBar/NavBar";
import { connect, useSelector, useDispatch } from "react-redux";
import { firestoreConnect, firebaseConnect } from "react-redux-firebase";
import { Redirect } from "react-router-dom";
import { compose } from "redux";
//import containers
import UpdateContainer from "../../../containers/UpdateScreen/UpdateContainer";
import FloatingAgendaSummary from "../../../containers/FloatingMeetingSummary/FloatingMeetingSummary";
import BottomSummary from "../../../containers/FloatingMeetingSummary/BottomMeetingSummary";
import EditAgendaContainer from "../../../containers/UpdateScreen/EditAgendaContainer";
import SuccessAlert from "../../../components/Alert/SuccessAlert";
import Divider from "@material-ui/core/Divider";
import MeetingStatus from "../../../components/UpdatePageComponents/MeetingStatus";
import Dialog from "../../../components/Dialog/Dialog";
import GroupAvatars from "../../../components/Avatar/AvatarGroup";
//import screens
import LoadingScreen from "./AgendaPreviewLoadingScreen";
//import actions
import { deleteNewMeetingID } from "../../../actions/newAgendaActions";
import { updatesSend, resetUpdates } from "../../../actions/updatesActions";
import {
  sendEdits,
  deleteFiles,
  deleteFileTagsReset,
  editUpdatesReset,
  editUpdateTagsReset,
} from "../../../actions/editUpdatesActions";
import { addMeetingAccess } from "../../../actions/meetingAccessActions";
//import utils
import { checkWhetherUpdatesAreEmpty } from "../../../utils/UpdatesUtils/updatesUtils";
import { breakDownPath } from "../../../utils/UpdateFilesUtils/UpdateFilesUtils";
//import styling
import "./AgendaPreview.css";

function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return null;
}

function AgendaPreview({ match, auth, firebase, firestore }) {
  const [files, setFiles] = useState([]);
  const [state, setState] = useState(null);
  /* const exampleFilesData = [
    {
        topicID: 'GBjdhxSWekmfcmx-03403940920',
        updateTimestamp: '032493090',
        userID: 'f9u9vfDFDdcxcijxrSEsko',
        file: File(),
    },
]
 */

  //loading state
  const [isLoading, setIsLoading] = useState(true);

  //initialize dispatch
  const dispatch = useDispatch();

  //couple of meeting IDs stat we need in order to display the right things in the page
  const currentMeetingID = match.params.meetingID;
  const newMeetingID = useSelector((state) => state.updatesSend.newMeetingID);
  const newlyCreatedMeetingID = useSelector(
    (state) => state.agendaCreate.newMeetingID
  );
  const meetingToEditID = useSelector((state) => state.editMeetingId);

  //handle confirmation "changed recorded!"
  let showConfirmation = false;
  if (
    newMeetingID === currentMeetingID ||
    newlyCreatedMeetingID === currentMeetingID
  ) {
    showConfirmation = true;
  } else if (meetingToEditID === currentMeetingID) {
    showConfirmation = true;
  }

  //receive meeting data without updates yet
  const meetingData = useSelector((state) => state.firestore.data.meetings);

  //get user data
  const firestoreData = useSelector((state) => state.firestore.data.users);
  const userDoc = firestoreData ? firestoreData[auth.uid] : null;
  const userData = userDoc ? userDoc.userData : null;

  //get update data
  const firebaseData = useSelector(
    (state) => state.firebaseReducer.data.teammateUpdates
  );
  const updateData = firebaseData ? firebaseData.topics : null;
  const updateFiles = useRef();

  //these are the new updates that the user enters
  const newUpdates = useSelector((state) => state.updates);

  //get deleted files from when user is editing an update
  const deletedFileTags = useSelector((state) => state.deletedFileTags);

  //get array of edited update tags to see whether user started editing any updates
  const editUpdateTags = useSelector((state) => state.editUpdateTags);

  //sometimes, the 'submit' button needs to be disabled
  let disableSubmitButton = true;

  if (editUpdateTags.length !== 0) {
    disableSubmitButton = false;
  } else if (
    newUpdates.length !== 0 &&
    checkWhetherUpdatesAreEmpty(newUpdates) === false
  ) {
    disableSubmitButton = false;
  }

  //MEETING ACCESS
  // In order to provide secure access to the meeting data, we work with auth tokens
  const hasAccess = useRef();
  const hasNoAccess = useRef();

  const checkForAccess = async () => {
    if (hasAccess.current === true) return;
    try {
      const data = {
        uid: auth.uid,
        meetingID: currentMeetingID,
      };

      const url =
        "https://europe-west1-prompt-meetings-app.cloudfunctions.net/meetingAccessTokens/generate";

      // To check for access, we call a cloud function that checks whether the user has access or not
      await fetch(url, {
        method: "POST",
        body: JSON.stringify(data),
      });

      // Then we refresh the auth tokens to get access to the latest ones
      const idTokenResult = await firebase
        .auth()
        .currentUser.getIdTokenResult(true);
      // ... and lastly we store it in a ref
      if (idTokenResult.claims.meetingAccess) {
        idTokenResult.claims.meetingAccess === currentMeetingID
          ? (hasAccess.current = idTokenResult.claims.meetingAccess)
          : (hasNoAccess.current = currentMeetingID);
      } else if (!idTokenResult.claims.meetingAccess) {
        hasNoAccess.current = currentMeetingID;
      }
      if (hasAccess.current === currentMeetingID) {
        // the dispatch below is necessary for reloading the firestoreConnect to load updates
        dispatch(addMeetingAccess(currentMeetingID));
        await loadFiles();
      } else {
        updateFiles.current = [];
      }
    } catch (err) {
      console.log(`Error! ${err.message}`);
    }
  };

  //LOADING FILES
  //when the page is loaded, we need to load the files that go with this meeting's updates
  const loadFiles = async () => {
    console.log("Hi, I am inside the loadFiles function!");
    setState(null);
    //the storage folder structure is as follows:
    //meetingID/topicID/updateTimestamp/file
    //first, we create a storageRef instance for the meeting folder:
    try {
      const storageRef = firebase.storage().ref(currentMeetingID);
      //then, we get the folders within the current meeting (topic folders):
      const topicFolders = await storageRef.listAll();
      // in order to get the update folders, we need to collect a list of Promises.
      // we can't use 'await' inside of forEach, so we first collect the promises
      // and afterwards await Promise.all to get the update folders
      const updateFolderPromises = [];
      // add promises to updateFolderPromises array
      topicFolders.prefixes.forEach((topicFolderRef) => {
        updateFolderPromises.push(
          storageRef.child(topicFolderRef.name).listAll()
        );
      });

      // check whether the promise array has anything in it. If not: quit function.
      if (updateFolderPromises.length === 0) {
        console.log("Nothing to fetch.");
        updateFiles.current = [];
        setState("");
        return;
      }

      // now we can await the promises to resolve to get the update folders!
      const updateFolders = await Promise.all(updateFolderPromises);

      // now, we can repeat this to get the actual files within the update folders :)
      const filePromises = [];
      updateFolders.forEach((updateFolderRef) => {
        updateFolderRef.prefixes.forEach((updateFolderPrefix) => {
          filePromises.push(
            firebase.storage().ref(updateFolderPrefix.fullPath).listAll()
          );
        });
      });

      // check again for an empty promise array to see if there is anything to fetch
      if (filePromises.length === 0) {
        console.log("No files to fetch.");
        updateFiles.current = [];
        setState("");
        return;
      }

      // if it's not empty, we can get the files!
      console.log("Going to get the files now...");
      const fileItems = await Promise.all(filePromises);
      console.log("Got the files!");

      // Now we'll pour the files into the right format and return them
      const returnArray = [];
      fileItems.forEach((item) => {
        const selectedItem = item.items[0];
        if (!selectedItem) {
          return;
        }
        const pathObj = breakDownPath(selectedItem.fullPath);
        returnArray.push({
          topicID: pathObj.topicID,
          updateTimestamp: pathObj.updateTimestamp,
          fileName: selectedItem.name,
          fullPath: selectedItem.fullPath,
        });
      });
      console.log("returnArray:");
      console.log(returnArray);
      updateFiles.current = returnArray;
      console.log(`updateFiles in loadFiles function: ${updateFiles.current}`);
      setState("files done");
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    checkForAccess();
  }, []);

  // code below doesnt work at all --> needs to be replaced with something more robust
  // basically creates a race condition
  useEffect(() => {
    // we only load files when updateData changes after we loaded it the first time (otherwise we do it twice on first load)
    if (updateFiles.current !== null && updateFiles.current !== undefined) {
      console.log("loadfiles useEffect");
      loadFiles();
    }
  }, [updateData]);

  //handle loading
  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    return () => clearTimeout(timeout);
  }, []);

  //UPLOADING FILES - function to be called in handleSubmit
  //returns an array of promises
  const uploadFiles = () => {
    //if no files are to be uploaded, we quit the function straight away
    if (files.length === 0) {
      console.log("No files to upload.");
      return;
    }

    //don't want to return an array of promises if there is only 1 file to be uploaded
    if (files.length === 1) {
      console.log("Only one file to upload, uploading now...");
      const { file, topicID, updateTimestamp } = files[0];

      const path = `${currentMeetingID}/${topicID}/${updateTimestamp}/${file.name}`;
      const storageRef = firebase.storage().ref(path);

      return storageRef.put(file);
    }

    //when multiple files are waiting to be uploaded, we return an array of promises
    console.log("Multiple files to upload. Uploading now...");

    return Promise.all(
      files.map((fileObj) => {
        const { file, topicID, updateTimestamp } = fileObj;

        const path = `${currentMeetingID}/${topicID}/${updateTimestamp}/${file.name}`;
        const storageRef = firebase.storage().ref(path);

        return storageRef.put(file);
      })
    );
  };

  const [dialogOpen, setDialogOpen] = useState(false);

  const openDialog = () => {
    setDialogOpen(true);
  };

  const closeDialog = () => {
    setDialogOpen(false);
  };

  const makeReadOnly = async () => {
    try {
      setIsLoading(true);
      await firestore.collection("meetings").doc(currentMeetingID).update({
        hasFinished: true,
      });

      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      alert(err);
    }
  };

  // old submmitting updates function
  /* const handleSubmit = async () => {
    setIsLoading(true);

    try {
      dispatch(
        updatesSend({
          meetingID: currentMeetingID,
          updates: newUpdates,
        })
      );

      // send edited updates
      dispatch(sendEdits(currentMeetingID));
      dispatch(deleteFiles(currentMeetingID));

      // reset state for editing updates
      dispatch(deleteFileTagsReset());
      dispatch(editUpdatesReset());
      dispatch(editUpdateTagsReset());

      // function written to upload all the files in the current state
      // written above
      const returnedPromise = await uploadFiles();
      console.log(returnedPromise);
      // clear files state after function is done
      setFiles([]);

      // reset updates in Redux store
      dispatch(resetUpdates());

      // load files from Firebase Storage
      loadFiles();

      // add 2 seconds of loading just to be sure
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    } catch (err) {
      console.log(err);
      return <Redirect to="/error" />;
    }
  }; */

  // I use a redux state called 'newMeetingID' to track when I receive the ID from the newly created
  // meeting back from Firebase. Then I directly redirect the user to this page if it exists.
  // To avoid that the user wont be able to visit the 'create meeting' page anymore, I have to
  // Set the newMeetingID back to null once the client arrives on this page.
  dispatch(deleteNewMeetingID());

  // Since Redux can't handle files very well, we have to handle the file state for updates locally
  const handleFileStateChange = (file) => {
    setFiles((prev) => {
      return [...prev, file];
    });
  };

  //deleting file function -- passed down to child component
  //files state is handled in AgendaPreview.jsx
  const handleFileDelete = (topicID, updateTimestamp) => {
    setFiles((prev) => {
      return prev.filter((fileObj) => {
        const topicIDMatches = topicID === fileObj.topicID;
        const updateTimestampMatches =
          updateTimestamp === fileObj.updateTimestamp;
        const isTargetFile = topicIDMatches && updateTimestampMatches;
        return !isTargetFile;
      });
    });
  };

  //redirect user if they're not logged in
  if (!auth.uid) return <Redirect to="/login" />;
  if (hasNoAccess.current === currentMeetingID)
    return <Redirect to="/no-access" />;

  console.log(
    `meetingData: ${meetingData}, loading done:${!isLoading}, updateData: ${updateData}, updateFiles: ${
      updateFiles.current
    }, hasAccess.current=${hasAccess.current}`
  );
  const loadingComplete =
    meetingData &&
    !isLoading &&
    updateData &&
    updateFiles.current !== null &&
    updateFiles.current !== undefined &&
    hasAccess.current === currentMeetingID;

  if (loadingComplete) {
    const currentMeetingData = meetingData[`${currentMeetingID}`];
    const isAdmin = currentMeetingData.adminIDs.find((id) => {
      return id === auth.uid;
    });

    const agendaPoints = currentMeetingData.agendaPoints;
    const isReadOnly = currentMeetingData.hasFinished;
    const showEditAgendaButton = isAdmin && !isReadOnly;
    const meetingDuration =
      agendaPoints.length === 1
        ? agendaPoints[0].duration
        : [...agendaPoints]
            .map((obj) => {
              return obj.duration;
            })
            .reduce((curr, prev) => curr + prev);

    const participantData = currentMeetingData.participantData;
    const avatarRowData = [];

    if (participantData) {
      currentMeetingData.participantIDs.forEach((id) => {
        if (participantData[id]) {
          avatarRowData.push(participantData[id]);
        }
      });
    }

    return (
      <div className="agendaPreview">
        <ScrollToTopOnMount />
        <NavBar />
        <div className="agendaPreviewDiv">
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div>
              <div style={{ display: "flex", alignItems: "center", margin: 0 }}>
                <MeetingStatus isReadOnly={isReadOnly} />
                <h5 style={{ marginTop: "0", marginBottom: "5px" }}>
                  {isReadOnly ? "Read-only" : "Give your updates"}
                </h5>
              </div>
              <div style={{ display: "flex", alignItems: "center", margin: 0 }}>
                <h1 className="agendaPreviewTitle" style={{ marginBottom: 10 }}>
                  {currentMeetingData.meetingTitle}
                </h1>
              </div>
              <p style={{minWidth: "100%"}}>{currentMeetingData.meetingDescription}</p>
              <GroupAvatars data={avatarRowData} />
              {showConfirmation && (
                <SuccessAlert confirmation="Changes recorded!" />
              )}
              {showEditAgendaButton && (
                <EditAgendaContainer
                  meetingID={currentMeetingID}
                  data={currentMeetingData}
                />
              )}
            </div>
            {agendaPoints.map(
              ({ title, description, duration, timestamp }, index) => {
                const agendaTopicID = `${currentMeetingID}-${timestamp}`;
                const updatesObj = updateData[agendaTopicID]
                  ? { ...updateData[agendaTopicID].updates }
                  : null;
                if (updatesObj) {
                  delete updatesObj["0"];
                }

                // files added by the user that aren't submitted yet
                const topicFiles = files.filter((fileObj, index) => {
                  return fileObj.topicID === agendaTopicID;
                });
                // fetched files from firebase storage
                const fetchedTopicFiles = updateFiles.current.filter(
                  (fileObj) => {
                    const isDeleted = deletedFileTags.find((obj) => {
                      return obj.timestamp === fileObj.updateTimestamp;
                    });
                    return fileObj.topicID === agendaTopicID && !isDeleted;
                  }
                );
                return (
                  <div
                    key={"wrapperDiv" + agendaTopicID}
                    style={{ marginTop: "60px" }}
                  >
                    <Divider />
                    <div
                      className="agendaUpdateContainerWrapper"
                      key={"headerDiv" + agendaTopicID}
                    >
                      <h2 className="topicNoHeader" style={{ margin: "0" }}>
                        {index + 1}.
                      </h2>
                      <UpdateContainer
                        key={`updateContainer-${agendaTopicID}-${index.toString()}`}
                        title={title}
                        id={agendaTopicID}
                        description={
                          description
                            ? description
                            : "Provide your updates below"
                        }
                        duration={duration.toString()}
                        updates={updatesObj ? Object.values(updatesObj) : []}
                        handleFileStateChange={handleFileStateChange}
                        handleFileDelete={handleFileDelete}
                        files={topicFiles}
                        fetchedFiles={fetchedTopicFiles}
                        meetingID={currentMeetingID}
                        isReadOnly={isReadOnly}
                        userData={userData}
                      />
                    </div>
                  </div>
                );
              }
            )}
          </div>
          <FloatingAgendaSummary
            isForUpdates={true}
            meetingLength={agendaPoints.length}
            meetingDuration={meetingDuration}
            handleSubmit={openDialog}
            buttonDisabled={disableSubmitButton}
            meetingID={currentMeetingID}
            pwd={currentMeetingData.pwd}
            isAdmin={isAdmin}
            isFinished={currentMeetingData.hasFinished}
          />
        </div>
        <BottomSummary
          isForUpdates={true}
          buttonDisabled={disableSubmitButton}
          handleSubmit={openDialog}
          meetingID={currentMeetingID}
          pwd={currentMeetingData.pwd}
          isAdmin={isAdmin}
          isFinished={currentMeetingData.hasFinished}
        />
        <Dialog
          open={dialogOpen}
          dialogTitle="Has your meeting ended?"
          dialogText="Preserve it by disabling changes. This cannot be reversed later - no one will be able to add, edit, or delete anything in this meeting page."
          button1Text="Cancel"
          button2Text="Make read-only"
          handleOpen={openDialog}
          handleClose={closeDialog}
          handleClick={() => {
            makeReadOnly();
            closeDialog();
          }}
          showLeftButton={true}
        />
      </div>
    );
  } else {
    return (
      <div>
        <ScrollToTopOnMount />
        <LoadingScreen />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    auth: state.firebaseReducer.auth,
    meetingAccess: state.meetingAccess,
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect((props) => {
    return [
      { collection: "meetings", doc: props.match.params.meetingID },
      { collection: "users", doc: props.auth.uid },
    ];
  }),
  firebaseConnect((props) => {
    return [
      {
        path: `meetings/${props.meetingAccess.meetingAccess}`,
        storeAs: "teammateUpdates",
      },
    ];
  })
)(AgendaPreview);
