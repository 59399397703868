import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
//import components
import TemplateTile from "../../components/TemplateTile/TemplateTile";
//import utils
import { templatesShort } from "../../utils/Dashboard/dashboardData";
//import styling
import "./HorizontalTileSlider.css";
//import actions
import {
  agendaAdd,
  agendaModifyTitle,
  agendaModifyDescription,
  agendaModifyDuration,
  agendaTitleEdit,
  agendaDescriptionEdit,
  agendaPointsReset,
} from "../../actions/newAgendaActions";
import { editMeetingIdReset } from "../../actions/editAgendaAction";
//import assets
import calculator from "../../assets/templates/calculator.png";

function TemplateTileContainer(props) {
  const data = props.data ? props.data : templatesShort;
  const dispatch = useDispatch();

  const history = useHistory();

  const routeChange = () => {
    let path = `create`;
    history.push(path);
  };

  const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const addIndividualAgendaTopic = (topic, key) => {
    wait(key * 100).then(() => {
      dispatch(agendaAdd());

      dispatch(
        agendaModifyTitle({
          title: topic.title,
          key,
        })
      );

      dispatch(
        agendaModifyDescription({
          description: topic.description,
          key,
        })
      );

      dispatch(
        agendaModifyDuration({
          duration: topic.duration,
          key,
        })
      );
    });
  };

  const addAdgendaTopics = (formattedTopics) => {
    dispatch(
      agendaModifyTitle({
        title: formattedTopics[0].title,
        key: formattedTopics[0].key,
      })
    );

    dispatch(
      agendaModifyDescription({
        description: formattedTopics[0].description,
        key: formattedTopics[0].key,
      })
    );

    dispatch(
      agendaModifyDuration({
        duration: formattedTopics[0].duration,
        key: formattedTopics[0].key,
      })
    );

    for (let i = 1; i < formattedTopics.length; i++) {
      addIndividualAgendaTopic(formattedTopics[i], i);
    }
  };

  const fillAgenda = async ({ title, description, topics }) => {
    // clear agenda
    dispatch(agendaPointsReset());
    dispatch(editMeetingIdReset());
    // edit agenda metadata
    dispatch(agendaTitleEdit(title));
    dispatch(agendaDescriptionEdit(description));

    const formattedTopics = topics.map((topic, index) => {
      return {
        ...topic,
        key: index,
      };
    });

    // add topics
    addAdgendaTopics(formattedTopics);
    routeChange();
  };

  return (
    <div className="horizontalTileSlider">
      {data.map((templateObj, index) => {
        const templateData = templateObj;
        const templateImage = templateData.imageUrl
          ? templateData.imageUrl
          : calculator;
        return (
          <TemplateTile
            key={index.toString()}
            title={templateData.title}
            description={templateData.description}
            src={templateImage}
            handleClick={() => fillAgenda(templateData)}
          />
        );
      })}
    </div>
  );
}

export default TemplateTileContainer;
