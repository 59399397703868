import React from "react";
import "./Footer.css";
//import assets
import promptIcon from "../../assets/logos/prompt-icon-white.png";

function Footer() {
  return (
    <footer>
      <div>
        <img draggable="false" src={promptIcon} />
      </div>
      <div className="termsDiv">
        <p>
          <a
            target="_blank"
            style={{ textDecoration: "none", color: "#E5E5E5" }}
            href="https://www.getprompt.io/legal/privacy"
          >
            Privacy Policy
          </a>
        </p>
        <p>
          <b>·</b>
        </p>
        <p>
          <a
            target="_blank"
            style={{ textDecoration: "none", color: "#E5E5E5" }}
            href="https://www.getprompt.io/legal/terms-of-service"
          >
            Terms of Use
          </a>
        </p>
      </div>
      <div>
        <p>Made with️ ❤️ in The Netherlands © 2021 Prompt Apps</p>
      </div>
    </footer>
  );
}

export default Footer;
