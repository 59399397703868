import React from 'react';
import PropTypes from 'prop-types';
import './DropDown.css';

function DropDownItem(props) {
    return  (
        <div className="dropdownItem" onClick={props.handleClick}>
            {props.children}
            <p>{props.txt}</p>
        </div>
    );
}

DropDownItem.propTypes = {
    src: PropTypes.string.isRequired,
    txt: PropTypes.string.isRequired,
    handleClick: PropTypes.func.isRequired,
}

export default DropDownItem;