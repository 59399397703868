import React from "react";
import Avatar from "@material-ui/core/Avatar";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import Popover from "@material-ui/core/Popover";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
//import utils
import { generateInitials } from "../../utils/avatarUtils";
//import colors
import { deepOrange, deepPurple } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 25,
    marginBottom: 25,
    maxWidth: 100,
  },
  avatarHolder: {
    borderRadius: "50%",
  },
  avatar: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
  },
  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: theme.spacing(1),
  },
}));

const MAX_COUNT = 4;

export default function GroupAvatars(props) {
  const classes = useStyles();

  const people = props.data;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [groupAnchorEl, setGroupAnchorEl] = React.useState(null);
  const [openID, setOpenID] = React.useState(null);
  const [restOfNamesPopoverOpen, setRestOfNamesPopoverOpen] = React.useState(
    null
  );

  const handlePopoverOpen = (event, id) => {
    setAnchorEl(event.currentTarget);
    setOpenID(id);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setOpenID(null);
  };

  const handleRestOfNamesPopoverOpen = (event, id) => {
    setGroupAnchorEl(event.currentTarget);
    setRestOfNamesPopoverOpen(id);
  };

  const handleRestOfNamesPopoverClose = () => {
    setAnchorEl(null);
    setRestOfNamesPopoverOpen(null);
  };

  const getRestOfNAmesList = () => {
    const namesArray = [];
    for (let i = MAX_COUNT - 1; i < people.length; i++) {
      if (i >= 10) {
        people.length - 11 > 1
          ? namesArray.push(`... ${people.length - 10} others`)
          : namesArray.push(`${people[i].name}`);
        break;
      } else {
        people[i].isAdmin
          ? namesArray.push(`${people[i].name} (admin)`)
          : namesArray.push(`${people[i].name}`);
      }
    }

    return <span>{namesArray.join(",\n")}</span>;
  };

  const showRestOfNames = people.length > 4;

  const restOfNamesOpen =
    openID !== `avatar-0` &&
    openID !== `avatar-1` &&
    openID !== `avatar-2` &&
    restOfNamesPopoverOpen !== null &&
    showRestOfNames;

  return (
    <div>
      <AvatarGroup
        max={MAX_COUNT}
        className={classes.root}
        onMouseEnter={(e) => handleRestOfNamesPopoverOpen(e, "rest")}
        onMouseLeave={handleRestOfNamesPopoverClose}
      >
        {people.map((person, index) => {
          const id = `avatar-${index}`;
          const open = openID === `avatar-${index}`;
          return (
            <div className={classes.avatarHolder} key={`divAvatar${index}`}>
              <Avatar
                id={`avatar-${index}`}
                key={`avatar-${index}`}
                className={classes.avatar}
                aria-owns={open ? "mouse-over-popover" : undefined}
                aria-haspopup="true"
                onMouseEnter={(e) => handlePopoverOpen(e, id)}
                onMouseLeave={handlePopoverClose}
                alt={person.name}
                src={person.profilePicture}
              >
                {person.profilePic ? null : generateInitials(person.name)}
              </Avatar>
              <Popover
                id={`mouse-over-popover-${index}`}
                key={`popover${index}`}
                className={classes.popover}
                classes={{
                  paper: classes.paper,
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
              >
                <p style={{ fontSize: "14px", color: "rgb(11, 11, 77)" }}>
                  {person.isAdmin ? `${person.name} (admin)` : person.name}
                </p>
              </Popover>
            </div>
          );
        })}
      </AvatarGroup>
      <Popover
        id={`mouse-over-popover-rest`}
        key={`popover-rest`}
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={restOfNamesOpen}
        anchorEl={groupAnchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <p style={{ fontSize: "14px", color: "rgb(11, 11, 77)" }}>
          {getRestOfNAmesList()}
        </p>
      </Popover>
    </div>
  );
}
