import React from "react";
import PropTypes from "prop-types";
import "./MeetingTile.css";

import LightButton from "../buttons/LightButton";
import WhiteButton from "../buttons/WhiteButton";

class MeetingTile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      standardMode: true,
      adminBeforeMeetingMode: false,
      duringMeetingMode: false,
    };
  }

  handleClickJoin() {}

  determineView() {
    if (this.props.isAdmin && !this.props.hasStarted) {
      this.setState(() => {
        return {
          standardMode: false,
          adminBeforeMeetingMode: true,
        };
      });
    } else if (this.props.hasFinished) {
      this.setState(() => {
        return { standardMode: true };
      });
    } else if (this.props.hasStarted) {
      this.setState(() => {
        return {
          duringMeetingMode: true,
          standardMode: false,
        };
      });
    }
  }

  componentDidMount() {
    this.determineView();
  }

  render() {

    return (
      <div className="meetingTile">
        <div className="date">
          <h3 className="month">{this.props.month}</h3>
          <h1 className="day">{this.props.day}</h1>
        </div>
        <div id="titleDescriptionContainer">
          <h4 className="meetingTitle">{this.props.title}</h4>
          <p id="meetingDescription">{this.props.description}</p>
        </div>
        <div id="buttonHolder">
          <LightButton
            txt={this.state.standardMode ? "View minutes" : "View"}
            handleClick={this.props.handleViewClick}
          />
          {this.state.adminBeforeMeetingMode && (
            <WhiteButton txt="Share" handleClick={this.props.handleShare} />
          )}
          {this.state.duringMeetingMode && (
            <WhiteButton txt="Join" handleClick={this.handleClickJoin} />
          )}
        </div>
      </div>
    );
  }
}

MeetingTile.propTypes = {
  // the title of the tile
  title: PropTypes.string.isRequired,
  // the description of the meeting -- TODO determine max amount of characters!
  description: PropTypes.string.isRequired,
  // three letter month string (e.g. DEC)
  month: PropTypes.string.isRequired,
  // the day of the month in which this takes place
  day: PropTypes.string.isRequired,
  // bool that determines whether the meeting has started
  hasStarted: PropTypes.bool.isRequired,
  // bool that determines whether the meeting has finished
  hasFinished: PropTypes.bool.isRequired,
  // bool that determines whether user is admin
  isAdmin: PropTypes.bool.isRequired,
  // this function routes the user to the right page
  handleViewClick: PropTypes.func.isRequired,
};

export default MeetingTile;
