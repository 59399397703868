const initState = {
  meetingAccess: "",
};

const meetingAccessReducer = (state = initState, action) => {
  switch (action.type) {
    case "SET_MEETING_ACCESS":
      return {
        meetingAccess: action.payload,
      };
    default:
      return state;
  }
};

export default meetingAccessReducer;
