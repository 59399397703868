export const sendNewComment = (commentTimestamp, meetingID, topicID) => {
    return async (dispatch, getState, { getFirebase }) => {
      // Get the comment instead
      const comment = getState().comments.find(c => {
          return c.commentTimestamp === commentTimestamp;
      });
      try {
        // initialize Firebase
        const firebase = getFirebase();
        const path = `meetings/${meetingID}/topics/${topicID}/updates/${comment.updateTimestamp}/comments/${commentTimestamp}`;
        const dbRef = firebase.database().ref(path);
        const res = await dbRef.set(comment);
        dispatch({ type: "SENDING_COMMENT_SUCCESS", res });
      } catch (err) {
        console.log(`Error in sending update changes! Error: ${err.message}`)
        dispatch({ type: "SENDING_COMMENT_ERROR", err });
      }
    };
  };

  export const updateComment = (comment, meetingID, topicID) => {
    return async (dispatch, getState, { getFirebase }) => {
      try {
        // initialize Firebase
        const firebase = getFirebase();
        const path = `meetings/${meetingID}/topics/${topicID}/updates/${comment.updateTimestamp}/comments/${comment.commentTimestamp}`;
        const dbRef = firebase.database().ref(path);
        const res = await dbRef.update(comment);
        dispatch({ type: "SENDING_COMMENT_SUCCESS", res });
      } catch (err) {
        console.log(`Error in sending update changes! Error: ${err.message}`)
        dispatch({ type: "SENDING_COMMENT_ERROR", err });
      }
    };
  };

  export const deleteComment = (comment, meetingID, topicID) => {
    return async (dispatch, getState, { getFirebase }) => {
      try {
        // initialize Firebase
        const firebase = getFirebase();
        const path = `meetings/${meetingID}/topics/${topicID}/updates/${comment.updateTimestamp}/comments/${comment.commentTimestamp}`;
        const dbRef = firebase.database().ref(path);
        const res = await dbRef.remove();
        dispatch({ type: "SENDING_COMMENT_SUCCESS", res });
      } catch (err) {
        console.log(`Error in sending update changes! Error: ${err.message}`)
        dispatch({ type: "SENDING_COMMENT_ERROR", err });
      }
    };
  };

  export const commentAdd = (obj) => {
    return {
      type: "COMMENT_ADD",
      // expects a userID, displayName, and updateTimestamp
      payload: obj,
    };
};

export const commentModify = (obj) => {
    return {
      type: "COMMENT_MODIFY",
      // expects an updateTimestamp and a comment
      payload: obj,
    };
};

export const commentRemove = (timestamp) => {
    return {
      type: "COMMENT_REMOVE",
      // expects a commentTimestamp
      payload: timestamp,
    };
};

export const commentsReset = () => {
  return {
    type: "COMMENTS_RESET",
  };
};