import { combineReducers } from 'redux';

//import reducers
import newAgendaReducer from './newAgenda/newAgendaReducer';
import agendaTitleReducer from './newAgenda/agendaTitleReducer';
import agendaDateReducer from './newAgenda/agendaDateReducer';
import agendaDescriptionReducer from './newAgenda/agendaDescriptionReducer';
import agendaCreateReducer from './newAgenda/agendaCreateReducer';
import { firebaseReducer } from 'react-redux-firebase';
import { firestoreReducer } from 'redux-firestore';
import authReducer from './authReducer';
import updatesReducer from './updates/updatesReducer';
import updatesSendReducer from './updates/updatesSendReducer';
import sanityTemplatesReducer from './templates/sanityTemplatesReducer';
import editUpdateTagsReducer from './updates/editUpdateTagsReducer';
import editUpdatesReducer from './updates/editUpdatesReducer';
import deleteFilesReducer from './updates/deleteFilesReducer';
import editMeetingIdReducer from './editAgenda/meetingIdReducer';
import paymentsRedcuer from './payments/paymentsReducer';
import deletedFileTagsReducer from './updates/deletedFileTagsReducer';
import settingsReducer from './settings/settingsReducer';
import settingsSendReducer from './settings/settingsSendReducer';
import settingsInitStateRedcuer from './settings/settingsInitStateRedcuer';
import meetingAccessReducer from './meetingAccessReducer';
import commentsSendReducer from './comments/commentsSendReducer';
import commentsReducer from './comments/commentsReducer';
import rememberMeetingDetailsReducer from './rememberMeetingDetailsReducer';
import newTemplateReducer from './templates/newTemplateReducer';
import templateCreateReducer from './templates/templateCreateReducer';
import templateDescriptionReducer from './templates/templateDescriptionReducer';
import templateTitleReducer from './templates/templateTitleReducer';
import templateImgReducer from './templates/templateImgReducer';
import { deleteFiles } from '../actions/editUpdatesActions';

export const allReducers = combineReducers({
    // new agenda reducers
    newAgenda: newAgendaReducer,
    agendaTitle: agendaTitleReducer,
    agendaDescription: agendaDescriptionReducer,
    agendaDate: agendaDateReducer,
    agendaCreate: agendaCreateReducer,
    // firebase 
    firebaseReducer,
    firestore: firestoreReducer,
    auth: authReducer,
    // updates
    updates: updatesReducer,
    updatesSend: updatesSendReducer,
    // edit meetings
    editMeetingId: editMeetingIdReducer,
    editUpdateTags: editUpdateTagsReducer,
    editUpdates: editUpdatesReducer,
    // settings
    settings: settingsReducer,
    settingsSend: settingsSendReducer,
    settingsInitState: settingsInitStateRedcuer,
    // files
    deletedFileTags: deletedFileTagsReducer,
    deleteFilesReducer: deleteFilesReducer,
    // payments
    payments: paymentsRedcuer,
    meetingAccess: meetingAccessReducer,
    // comments
    commentsSendReducer,
    comments: commentsReducer,
    // meeting access
    rememberedMeetingDetails: rememberMeetingDetailsReducer,
    //template reducers
    sanityTemplates: sanityTemplatesReducer,
    newTemplate: newTemplateReducer,
    templateCreate: templateCreateReducer,
    templateDescription: templateDescriptionReducer,
    templateTitle: templateTitleReducer,
    templateImg: templateImgReducer,
});