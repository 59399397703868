export const agendaAdd = () => {
  return {
    type: "AGENDA_ADD",
  };
};

export const agendaModifyTitle = (obj) => {
  return {
    type: "AGENDA_MODIFY_TITLE",
    payload: obj,
  };
};

export const agendaModifyDescription = (obj) => {
  return {
    type: "AGENDA_MODIFY_DESCRIPTION",
    payload: obj,
  };
};

export const agendaModifyDuration = (obj) => {
  return {
    type: "AGENDA_MODIFY_DURATION",
    payload: obj,
  };
};

export const agendaRemove = (obj) => {
  return {
    type: "AGENDA_REMOVE",
    payload: obj,
  };
};

export const agendaSwitch = (obj, targetIndex) => {
  return {
    type: "AGENDA_SWITCH",
    payload: {
      obj,
      targetIndex,
    },
  };
};

export const setAgendaPointsList = (arr) => {
  return {
    type: "SET_TOPICS",
    payload: arr,
  };
};

export const agendaTitleEdit = (title) => {
  return {
    type: "AGENDA_TITLE_EDIT",
    payload: title,
  };
};

export const agendaDateEdit = (date) => {
  return {
    type: "AGENDA_DATE_EDIT",
    payload: date,
  };
};

export const agendaDescriptionEdit = (description) => {
  return {
    type: "AGENDA_DESCRIPTION_EDIT",
    payload: description,
  };
};

export const agendaCreate = (obj) => {
  return async (dispatch, getState, { getFirestore }) => {
    try {
      const firestore = getFirestore();

      const newMeeting = await firestore
        .collection("meetings")
        .add({
          meetingTitle: obj.meetingTitle,
          meetingDescription: obj.meetingDescription
            ? obj.meetingDescription
            : "Make sure to enter your updates before the meeting!",
          meetingDate: obj.meetingDate,
          agendaPoints: obj.agendaPoints
            .map((point) => {
              return {
                title: point.title,
                description: point.description,
                duration: point.duration,
                key: point.key,
                timestamp: point.timestamp.getTime().toString(),
              };
            })
            .filter((point) => {
              return point.title !== "";
            }),
          participantIDs: [obj.userID],
          adminIDs: [obj.userID],
          participantData: {
            [`${obj.userID}`] : {
              isAdmin: true,
              profilePicture: obj.profilePicture,
              name: obj.userName,
            },
          },
          hasStarted: false,
          hasFinished: false,
        });

        const meetingID = newMeeting['d_']['S_'].path.segments[1]
      dispatch({ type: "AGENDA_CREATE_SUCCESS", meetingID });
    } catch (err) {
      dispatch({ type: "AGENDA_CREATE_ERROR", err });
    }
  };
};

export const deleteNewMeetingID = () => {
  return {
    type: "DELETE_NEWMEETINGID"
  }
}

export const agendaReset = (dispatch, getState) => {
  dispatch({ type: "AGENDA_RESET" });
  dispatch({ type: "AGENDA_TITLE_RESET" });
  dispatch({ type: "AGENDA_DESCRIPTION_RESET" });
  dispatch({ type: "AGENDA_DATE_RESET" });
};

export const agendaPointsReset = () => {
  return {
    type: "AGENDA_RESET",
  }
}

export const agendaTitleReset = () => {
  return {
    type: "AGENDA_TITLE_RESET",
  }
}

export const agendaDescriptionReset = () => {
  return {
    type: "AGENDA_DESCRIPTION_RESET",
  }
}

export const agendaDateReset = () => {
  return {
    type: "AGENDA_DATE_RESET",
  }
}
