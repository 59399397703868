import React, { useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
//import components
import MeetingTile from "../../components/MeetingTile/MeetingTile";
import LottieAnimation from "../../components/Lottie/LottieAnimation";
//import assets
import loadingAnimation from "../../assets/animations/loading.json";
//import { meetings } from "../../utils/Dashboard/dashboardData";
import {
  getFormattedDate,
  isWithinAWeek,
} from "../../utils/Dashboard/dashboardUtils";
//import styling
import "./HorizontalTileSlider.css";

function MeetingTileContainer(props) {
  const meetings = props.meetings;

  const filterMeetingData = (data) => {
    return data.filter((meeting) => {
      return isWithinAWeek(meeting.date.seconds);
    });
  };

  const handleLinkShare = (e, meetingID) => {
    console.log(meetingID);
    props.handleShare(meetingID);
  }

  const history = useHistory();

  const routeChange = (meetingID) => {
    let path = `/agenda/${meetingID}`;
    history.push(path);
  };

  if (meetings) {
    const meetingData = props.onlyNextSevenDays
      ? filterMeetingData(meetings)
      : meetings;

    const sortedMeetings =
      meetings.length > 1
        ? [...meetingData].sort((a, b) => b.date.seconds - a.date.seconds)
        : meetingData;
    return (
      <div className="horizontalTileSlider">
        {[...sortedMeetings]
          .sort((a, b) => b.date.seconds - a.date.seconds)
          .map((meetingObj, index) => {
            const meetingData = meetingObj;
            const formattedDate = getFormattedDate(meetingData.date);
            return (
              <MeetingTile
                handleShare={(e) => handleLinkShare(e, meetingData.meetingID)}
                meetingID={meetingData.meetingID}
                handleViewClick={() => routeChange(meetingData.meetingID)}
                key={meetingData.meetingID}
                title={meetingData.title}
                description={meetingData.description}
                month={formattedDate.month}
                day={formattedDate.day}
                hasStarted={meetingData.hasStarted}
                hasFinished={meetingData.hasFinished}
                isAdmin={meetingData.isAdmin}
              />
            );
          })}
      </div>
    );
  } else {
    return (
      <div>
        <LottieAnimation lotti={loadingAnimation} height={150} width={150} />
      </div>
    );
  }
}

MeetingTileContainer.propTypes = {
  onlyNextSevenDays: PropTypes.bool,
  // the user ID
  uid: PropTypes.string.isRequired,
};

export default MeetingTileContainer;
