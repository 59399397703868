const initState = {
    updatesSendError: null,
    newMeetingID: null,
  };
  
  const updatesSendReducer = (state = initState, action) => {
    switch (action.type) {
      case "SENDING_UPDATES_SUCCESS":
        console.log("Updates successfully sent");
        return state;
      case "AGENDA_CREATE_ERROR":
        return {
          ...state,
          updatesSendError: `Action failed. Error: ${action.err.message}`,
        };
      default:
        return state;
    }
  };
  
  export default updatesSendReducer;
  