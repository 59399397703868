const initState = {
  authError: null,
};

const authReducer = (state = initState, action) => {
  switch (action.type) {
    case "LOGIN_ERROR":
      console.log("login error");
      return {
        ...state,
        authError: `Login failed. Error: ${action.err.message}`,
      };
    case "LOGIN_SUCCESS":
      console.log("login success");
      return {
        ...state,
        authError: null,
      };
    case "SIGNOUT_SUCCESS":
      console.log("signout success");
      return state;
    case "SIGNUP_SUCCESS":
      console.log("signup success");
      return {
        ...state,
        authError: null,
      };
    case "SIGNUP_ERROR":
      console.log("signup error");
      return {
        ...state,
        authError: `Signup failed. Error: ${action.err.message}`,
      };
    case "RESET_PASSWORD_EMAIL_SUCCESS":
      console.log("Reset password email success");
      return {
        ...state,
        authError: null,
      };
    case "RESET_PASSWORD_EMAIL_ERROR":
      console.log("Reset password error");
      return {
        ...state,
        authError: `Action failed. Error: ${action.err.message}`,
      };
    case "RESET_AUTH_ERROR":
      return initState;
    default:
      return state;
  }
};

export default authReducer;
