import React from 'react';
import './Button.css';
import PropTypes from 'prop-types';

class LightButton extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const inactiveStyle = {backgroundColor: 'lightGrey'};

        return (
            <button className="lightButton promptButton" style={this.props.handleClick ? {} : inactiveStyle} onClick={this.props.handleClick}>
                <div className="contentHolder">
                    {this.props.srcBefore && <img className="icon-before icon" src={this.props.srcBefore}/>}
                    {this.props.txt ? this.props.txt : "Click here"}
                    {this.props.srcAfter && <img className="icon-after icon" src={this.props.srcAfter}/>}
                </div>
            </button>
        );
    }
}

LightButton.propTypes = {
    // the text in the button
    txt: PropTypes.string.isRequired,
    // the onClick function -- enter null if nothing
    handleClick: PropTypes.func.isRequired,
    // the link to the icon before text
    srcBefore: PropTypes.string,
    // the link to the icon after the text
    srcAfter: PropTypes.string
}

export default LightButton;