import React from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import FullButton from "../buttons/FullButton";
import FlatButton from "../buttons/FlatButton";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import "./Dialog.css";
//import assets
import example1 from "../../assets/videos/1-after-resize.mov";
import example2 from "../../assets/videos/2-after-resize.mov";
import example3 from "../../assets/videos/3-after-resize.mov";

export default function ResponsiveDialog(props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const robin =
    "https://res-1.cloudinary.com/crunchbase-production/image/upload/c_thumb,h_256,w_256,f_auto,g_faces,z_0.7,q_auto:eco/lrmjxzty5hgrocpctcmc";

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        {props.showRobin && (
          <div className="personDialog">
            <img src={robin} alt="Prompt founder Robin Salimans" />
            <p>
              <b>Robin</b> from Prompt
            </p>
          </div>
        )}
        {props.imageUrl && <img src={props.imageUrl} alt="" id="dialogImg" />}
        
        {props.videoSrc === example1 && (
          <video loop autoPlay className="top" id="dialogVid">
            <source src={example1} />
          </video>
        )}
        {props.videoSrc === example2 && (
          <video loop autoPlay id="dialogVid">
            <source src={example2} />
          </video>
        )}
        {props.videoSrc === example3 && (
          <video loop autoPlay id="dialogVid">
            <source src={example3} />
          </video>
        )}
        {props.dialogTitle && (
          <DialogTitle id="responsive-dialog-title">
            {props.dialogTitle}
          </DialogTitle>
        )}
        <DialogContent>
          <DialogContentText>{props.dialogText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          {props.showLeftButton && (
            <FlatButton
              handleClick={props.handleClose}
              txt={props.button1Text ? props.button1Text : "Close"}
            ></FlatButton>
          )}
          <FullButton
            handleClick={props.handleClick}
            txt={props.button2Text ? props.button2Text : "Close"}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
}

ResponsiveDialog.propTypes = {
  dialogTitle: PropTypes.string,
  dialogText: PropTypes.string.isRequired,
  button1Text: PropTypes.string,
  button2Text: PropTypes.string.isRequired,
  handleOpen: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleClick: PropTypes.func,
  imageUrl: PropTypes.string,
  videoSrc: PropTypes.string,
  showLeftButton: PropTypes.bool,
  showRobin: PropTypes.bool,
};
