import React, { useEffect } from "react";
import { Redirect } from "react-router-dom"
import { useFirebase } from "react-redux-firebase";

function CheckoutFailed() {
  const firebase = useFirebase();
  const analytics = firebase.analytics();

  useEffect(() => {
    analytics.logEvent('checkout_failed');
  }, []);

  return <Redirect to="/"/>
}

export default CheckoutFailed;
