import React from 'react-redux';
import PropTypes from 'prop-types';
import './MeetingFact.css';

function MeetingFact(props) {
    return(
        <div className="meetingFactDiv">
            <div className="iconDiv">
                <img src={props.src} />
            </div>
            <div className="factDiv">
                <p className="fact">{props.fact}</p>
                <p id="factTitle">{props.title}</p>
            </div>
        </div>
    );
}

MeetingFact.propTypes = {
    src: PropTypes.string.isRequired,
    fact: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
}

export default MeetingFact;