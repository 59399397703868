import React from 'react';
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
//import compnents
import WhiteButton from '../../components/buttons/WhiteButton';
import ParticipantContainer from '../../containers/ParticipantContainer/ParticipantContainer'
//import styling
import './MeetingPrompt.css';

function StartMeetingScreen({ auth }) {
    //redirect user if they're not logged in
    if(!auth.uid) return <Redirect to='/login' />

    return (
        <div className="meetingPromptWrapper">
           <div id="meetingDetailsDiv">
                <h1>Robin's Meeting</h1>
                <p>20 minutes · 3 topics · 7 participants</p>
                <ParticipantContainer />
           </div>
           <WhiteButton txt="Start meeting" handleClick={() => {}}/>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
      auth: state.firebaseReducer.auth,
    }
  }  

export default connect(mapStateToProps)(StartMeetingScreen);