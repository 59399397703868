import React, { useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
//import components
import Input from "../../components/Input/InputField";
import Button from "../../components/buttons/FullButton";
//import styling
import "./LoginContainer.css";
//import actions
import { signIn, resetPassword } from "../../actions/authActions";

function ForgotPassword(props) {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [err, setErr] = useState("");

  const handleEmailChange = (val) => {
    setEmail(val);
  };

  const handleSubmit = async (e) => {
    setMessage("");
    setErr("");
    e.preventDefault();
    try {
        await props.resetPassword(email);
        setMessage("Success! Check your email for further instructions. Also check your spam!");
    } catch(err) {
        setErr(err);
    }
    
  };

  const history = useHistory();

  const routeChange = () => {
    let path = `login`;
    history.push(path);
  };

  return (
    <div className="loginContainer" style={{ minHeight: "30vh" }}>
      <div className="errorMessageHolder">
        {props.authError && <p className="errorMessage">{props.authError}</p>}
        {err && <p className="errorMessage">{err}</p>}
        {!props.authError && <span className="successMessage">{message}</span>}
      </div>
      <form className="loginContainerContent">
        <h1>Reset password</h1>
        <div>
          <Input
            inputType="email"
            placeHolder="Email address"
            handleChange={handleEmailChange}
            val={email}
          />
          <Button
            txt="Reset password"
            handleClick={handleSubmit}
            isFullWidth={true}
          />
          <a id="forgotPassword" onClick={routeChange}>
            Log in
          </a>
        </div>
      </form>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.authError,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    signIn: (creds) => dispatch(signIn(creds)),
    resetPassword: (email) => dispatch(resetPassword(email)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
