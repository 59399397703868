import React from 'react';
import PropTypes from 'prop-types';
//import styling
import './Input.css';
//import assets
import sendIcon from '../../assets/icons/short_up.png'

function MeetingInputField(props) {

    const onChange = ({ target }) => {
        const name = target.value;
        
        if(props.handleChange) {
            props.handleChange(name);
        }      
    }
    const inactiveStyle = {backgroundColor: 'lightGrey'};

    return (
        <div className="meetingInputField">
            <input 
                type="text"
                className="meetingTextInput" 
                value={props.val}
                onChange={onChange}
                onSubmit={props.handleSubmit} 
                placeholder={props.placeHolder ? props.placeHolder : "Type here"}
            />
            <button className="submitCommentButton" style={props.val ? {} : inactiveStyle}>
                <img className="icon" src={sendIcon}/>
            </button>
        </div>
    );
}

MeetingInputField.propTypes = {
    // function to handle submit
    handleSubmit: PropTypes.func,
    // value passed into props, managed by container component
    val: PropTypes.string.isRequired,
    // the placeholder in the input field
    placeHolder: PropTypes.string,
    // function to handle change for e.g. updating parent or siblings
    handleChange: PropTypes.func,
}

export default MeetingInputField;