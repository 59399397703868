import React from 'react';
import './Button.css';
import PropTypes from 'prop-types';

class IconButton extends React.Component {

    render () {
        return(
        <button className="iconButton" onClick={this.props.handleClick}>
            <div className="singleIcon">
                <img className="icon" src={this.props.src} alt=""/>
            </div>
        </button>
        );
        
    }
}

export default IconButton;