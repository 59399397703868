import React from "react";
import { useSelector } from "react-redux";
import { useHistory, Redirect } from "react-router-dom";
import { connect } from "react-redux";
//import components
import NavBar from "../../components/NavBar/NavBar";
import Footer from "../../components/Footer/Footer";
import SignupContainer from "../../containers/Login/SignupContainer";
//import styling
import "./LoginPage.css";

function SignupPage({ auth }) {
  const history = useHistory();

  const routeChange = () => {
    let path = `login`;
    history.push(path);
  };

  const rememberedMeetingDetails = useSelector(
    (state) => state.rememberedMeetingDetails
  );
  const { meetingID, pwd } = rememberedMeetingDetails;

  //redirect user if they're not logged in
  if (auth.uid && meetingID && pwd) {
    return <Redirect to={`/j/${meetingID}/${pwd}`} />;
  }
  if (auth.uid) return <Redirect to="/" />;

  return (
    <div className="loginPage">
      <NavBar />
      <div id="loginBlock">
        <SignupContainer />
        <p id="dontHaveAccountYet">
          Already have an account? <a onClick={routeChange}>Log in</a>
        </p>
      </div>
      <Footer />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebaseReducer.auth,
  };
};

export default connect(mapStateToProps)(SignupPage);
