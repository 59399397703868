import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(
  "pk_live_51IZhVvIpbXg1ObU4CDTzJJooftA61SHlIRbECwSKb18O89k61FYrK32szM06SZbBgireGlT6Fcuveqebz2vcu8Dh00LFVJT9rh"
);

export const createStripeCheckout = () => {
  return async (dispatch, getState) => {
    try {
      const uid = getState().firebaseReducer.auth.uid;

      const firestoreData = getState().firestore.data;
      if (!firestoreData) {
        throw new Error("Firestore data not fetched yet");
      }

      const profile = firestoreData.users[uid];

      if (!profile) {
        throw new Error("User profile not found within Firestore data");
      }

      const customerData = profile.customerData;

      const monthlyFetchLink = customerData
        ? "https://europe-west1-prompt-meetings-app.cloudfunctions.net/payments/create-checkout-session-without-trial"
        : "https://europe-west1-prompt-meetings-app.cloudfunctions.net/payments/create-checkout-session";

      const httpRequestMonthly = () =>
        fetch(
          monthlyFetchLink,
          {
            method: "POST",
            body: JSON.stringify({
              priceId: "price_1Il9zVIpbXg1ObU4NIH57vtn",
              uid: uid,
            }),
          }
        );

      //initialize Stripe
      const stripe = await stripePromise;
      //make httpRequest
      const res = await httpRequestMonthly();
      //format data
      const data = await res.json();
      const sessionId = data["sessionId"];
      //redirect to checkout
      await stripe.redirectToCheckout({ sessionId: sessionId });
      //dispatch if success
      dispatch({ type: "SESSION_CREATE_SUCCESS" });
    } catch (err) {
      dispatch({ type: "SESSION_CREATE_ERROR", err });
    }
  };
};

export const createPortalSession = (customerID) => {
  return async (dispatch, getState) => {
    try {
      const httpRequestPortal = (id) =>
        fetch(
          "https://europe-west1-prompt-meetings-app.cloudfunctions.net/payments/customer-portal",
          {
            method: "POST",
            body: JSON.stringify({ customerID: id }),
          }
        );
      //get user data
      const uid = getState().firebaseReducer.auth.uid;
      const firestoreData = getState().firestore.data;
      if (!firestoreData) {
        throw new Error("Firestore data not fetched yet");
      }

      const profile = firestoreData.users[uid];

      if (!profile) {
        throw new Error("User profile not found within Firestore data");
      }

      const customerData = profile.customerData;

      if (!customerData) {
        throw new Error("No customer data found within user profile");
      }

      const customerID = customerData.stripeId;
      //make httpRequest
      const res = await httpRequestPortal(customerID);
      //format data
      const data = await res.json();
      const url = data["url"];
      //redirect to checkout
      window.location.replace(url);
      //dispatch if success
      dispatch({ type: "SESSION_CREATE_SUCCESS" });
    } catch (err) {
      dispatch({ type: "SESSION_CREATE_ERROR", err });
    }
  };
};
