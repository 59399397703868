import { returnFirstName } from '../../utils/MeetingUtils/meetingUtils';

const initialState = [];

const updatesReducer = (state = initialState, action) => {
    switch(action.type){
        // expects a topicID
        case 'TOPIC_ADD':
            return [...state, 
            {
                topicID: action.payload,
                updates: [],
            }];
        case 'UPDATE_ADD':
            // expects a uid, imgUrl, fullName, and a topicID
            return state.map((topic, index) => {
                const now = new Date();
                if(topic.topicID === action.payload.topicID) {
                    return {
                        topicID: topic.topicID,
                        updates: [...topic.updates, 
                        {
                            userID: action.payload.uid,
                            imgUrl: action.payload.imgUrl,
                            firstName: returnFirstName(action.payload.fullName),
                            fullName: action.payload.fullName,
                            updateText: '',
                            isSentDuringMeeting: false,
                            timestamp: now.getTime().toString()
                        }]
                    }
                } else {
                    return topic;
                }
            })    
        case 'UPDATE_MODIFY':
            // expects a topicID, updateText, and timestamp
            return state.map((topic, index) => {
                if(topic.topicID === action.payload.topicID) {
                    return {
                        topicID: topic.topicID,
                        updates: topic.updates.map((update, index) => {
                            if(update.timestamp === action.payload.timestamp) {
                                return {
                                    ...update, 
                                    updateText: action.payload.updateText,
                                }
                            } else {
                                return update
                            }
                        })
                    }
                } else {
                    return topic;
                }
            })

        case 'UPDATE_REMOVE':
            // expects a topicID and timestamp
            return state.map((topic, index) => {
                if(topic.topicID === action.payload.topicID) {
                    return {
                        topicID: topic.topicID,
                        updates: topic.updates.filter((update, index) => {
                            return update.timestamp !== action.payload.timestamp;
                        })
                    }
                } else {
                    return topic;
                }
            }) 
        case 'UPDATES_RESET':
            return initialState;
        default:
            return state;
    }
}

export default updatesReducer;