import { breakDownPath } from "../utils/UpdateFilesUtils/UpdateFilesUtils";

export const addEditTagToUpdate = (obj) => {
  return {
    type: "ADD_EDIT_TAG_TO_UPDATE",
    payload: obj,
  };
};

export const removeEditTagFromUpdate = (timestamp) => {
  return {
    type: "REMOVE_EDIT_TAG_FROM_UPDATE",
    payload: timestamp,
  };
};

export const editUpdateTagsReset = () => {
  return {
    type: "EDIT_UPDATE_TAGS_RESET",
  };
};

export const editUpdatesAdd = (update) => {
  // expects entire update object and topicID
  return {
    type: "EDIT_UPDATE_ADD",
    payload: update,
  };
};

export const editUpdateTextModify = (obj) => {
  // expects entire update object
  return {
    type: "EDIT_UPDATETEXT_MODIFY",
    payload: obj,
  };
};

export const editUpdateRemove = (obj) => {
  // this is for when the user deletes their update
  // expects a timestamp
  // compare editUpdateTags with this state later to see which ones have been deleted
  return {
    type: "EDIT_UPDATE_REMOVE",
    payload: obj,
  };
};

export const editUpdatesReset = () => {
  return {
    type: "EDIT_UPDATES_RESET",
  };
};

export const addDeleteTagToFile = (obj) => {
  return {
    type: "ADD_DELETE_TAG_TO_FILE",
    payload: obj,
  };
};

export const removeDeleteTagFromFile = (timestamp) => {
  return {
    type: "REMOVE_DELETE_TAG_FROM_FILE",
    payload: timestamp,
  };
};

export const deleteFileTagsReset = () => {
  return {
    type: "DELETE_FILE_TAGS_RESET",
  };
};

export const sendEdits = (meetingID) => {
  return async (dispatch, getState, { getFirestore }) => {
    const editUpdateTags = getState().editUpdateTags;
    const editUpdates = getState().editUpdates;
    if (editUpdateTags.length === 0) {
      console.log("No update edits to process. Exiting function.");
      return;
    }
    try {
      // initialize Firestore
      const firestore = getFirestore();
      const promiseArray = [];
      // we will loop over the update edit tags
      // if it is still in the edit update changes state, then process changes
      // otherwise, delete update
      editUpdateTags.forEach((tag) => {
        const stillExists = editUpdates.find((update) => {
          return update.timestamp === tag.timestamp;
        });
        if (stillExists) {
          if(stillExists.meetingID !== meetingID) {
            console.log('This update does not belong to this meeting. Skipping this one.');
            return;
          }
          promiseArray.push(
            firestore.collection("pendingUpdateChanges").add({
              update: { ...stillExists },
              action: "MODIFY",
              userID: getState().firebaseReducer.auth.uid,
            })
          );
        } else {
          // get topicID
          promiseArray.push(
            firestore.collection("pendingUpdateChanges").add({
              update: { timestamp: tag.timestamp, meetingID, topicID: tag.topicID,},
              action: "DELETE",
              userID: getState().firebaseReducer.auth.uid,
            })
          );
        }
      });
      const res = await Promise.all(promiseArray);
      dispatch({ type: "SENDING_UPDATES_SUCCESS", res });
    } catch (err) {
      console.log(`Error in sending update changes! Error: ${err.message}`)
      dispatch({ type: "SENDING_UPDATES_ERROR", err });
    }
  };
};

export const deleteFiles = (meetingID) => {
  return async (dispatch, getState, { getFirebase }) => {
    try {
      const filesToDelete = getState().deletedFileTags;
      const firebase = getFirebase();
      const promiseArray = [];

      // first, check whether there are no files to delete
      if (!filesToDelete) {
        console.log("No files to delete this time. Exiting function.");
        return;
      }
      // loop over filesToDelete --> they include a fullPath that we can use to delete the file in Firebase Storage
      filesToDelete.forEach((fileToDelete) => {
        const fileMeetingID = breakDownPath(fileToDelete.fullPath).meetingID;
        const isInCorrectMeeting = fileMeetingID === meetingID

        if(!isInCorrectMeeting) {
          console.log(`File with timestamp ${fileToDelete.timestamp} is not in the correct meeting. Skipping it.`)
          return;
        }

        promiseArray.push(
          firebase.storage().ref(fileToDelete.fullPath).delete()
        );
      });

      await Promise.all(promiseArray);
      dispatch({ type: "DELETE_FILES_SUCCESS" });
    } catch (err) {
      console.log(`Error in deleting files! Error: ${err.message}`);
      dispatch({ type: "DELETE_FILES_ERROR", err})
    }
  };
};
