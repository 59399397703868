import React, { useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
//import screens
import Login from "./screens/LoginPage/LoginPage";
import Signup from "./screens/LoginPage/SignupPage";
import ForgotPassword from "./screens/LoginPage/ForgotPasswordPage";
import Dashboard from "./screens/Dashboard/Dashboard";
import AgendaCreator from "./screens/Agenda/AgendaCreator/AgendaCreator";
import StartMeetingScreen from "./screens/MeetingPrompt/StartMeetingScreen";
import MeetingPrompt from "./screens/MeetingPrompt/MeetingPrompt";
import NotFound from "./screens/404NotFound/NotFound";
import AgendaPreview from "./screens/Agenda/AgendaPreview/AgendaPreview";
import ErrorPage from "./screens/ErrorPage/ErrorPage";
import CheckoutFailed from "./screens/CheckoutRedirect/CheckoutFailed";
import CheckoutSuccess from "./screens/CheckoutRedirect/CheckoutSuccess";
import Goodbye from "./screens/Goodbye/Goodbye";
import NoAccess from "./screens/403NoAccess/NoAccess";
import { Settings } from "./screens/Settings/Settings";
import JoinContainer from "./containers/JoinContainer/JoinContainer";
import TemplateCreator from "./screens/TemplateCreator/TemplateCreator";
import VideoLib from "./screens/VideoLib/VideoLib";
//import styling
import "./App.css";
//import components
import WarningSnackbar from "./components/Snackbar/WarningSnackbar";
//import packages
import { Offline } from "react-detect-offline";

function PromptApp() {
  const [open, setOpen] = useState(true);
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Router>
      <div className="App">
        <Offline>
          <WarningSnackbar
            msg="Your connection is unstable or offline"
            open={open}
            handleClose={handleClose}
          />
        </Offline>
      </div>
      <Switch>
        <Route path="/" exact component={Dashboard} />
        <Route path="/create" component={AgendaCreator} />
        <Route path="/start" component={StartMeetingScreen} />
        <Route path="/topic" component={MeetingPrompt} />
        <Route path="/login" component={Login} />
        <Route path="/signup" component={Signup} />
        <Route path="/forgot-password" component={ForgotPassword} />
        <Route path="/agenda/:meetingID" exact component={AgendaPreview} />
        <Route path="/agenda/edit/:meetingID" component={AgendaCreator} />
        <Route path="/error" component={ErrorPage} />
        <Route path="/subscription_failed" component={CheckoutFailed} />
        <Route path="/subscription_success" component={CheckoutSuccess} />
        <Route path="/settings" component={Settings} />
        <Route path="/no-access" component={NoAccess} />
        <Route path="/goodbye/:name" exact component={Goodbye} />
        <Route path="/j/:meetingID/:pwd" exact component={JoinContainer} />
        <Route path="/new-template" component={TemplateCreator} />
        <Route path="/video-guide" component={VideoLib} />
        <Route component={NotFound} />
      </Switch>
    </Router>
  );
}

export default PromptApp;
