import calculator from '../../assets/templates/calculator.png'
import coffee from '../../assets/templates/coffee.png'


export const meetings = [
    {
        id: 'meeting10',
        title: "Daily Standup",
        description: "The cross-team weekly update meeting",
        date: new Date(2021, 3, 10, 12, 30, 0, 0),
        hasStarted: false,
        hasFinished: false,
        isAdmin: true,
        data: {}
    },
    {
        id: 'meeting5',
        title: "Finance Team Meeting",
        description: "An update on product dev, veeeery long meeting description to test it",
        date: new Date(2021, 2, 24, 12, 30, 0, 0),
        hasStarted: true,
        hasFinished: false,
        isAdmin: false,
        data: {}
    },
    {
        id: 'meeting15',
        title: "Prompt team meeting with a special guest",
        description: "An update with the Prompt team",
        date: new Date(2021, 2, 10, 12, 30, 0, 0),
        hasStarted: false,
        hasFinished: false,
        isAdmin: true,
        data: {}
    },
    {
        id: 'meeting12',
        title: "Product update",
        description: "An update on product dev",
        date: new Date(2021, 2, 7, 12, 30, 0, 0),
        hasStarted: true,
        hasFinished: true,
        isAdmin: true,
        data: {}
    },
    {
        id: 'meeting9',
        title: "Team-wide update",
        description: "The cross-team weekly update meeting",
        date: new Date(2021, 2, 1, 12, 30, 0, 0),
        hasStarted: true,
        hasFinished: true,
        isAdmin: true,
        data: {}
    },
    {
        id: 'meeting3',
        title: "Daily standup",
        description: "The update meeting on today's process",
        date: new Date(2021, 1, 24, 12, 30, 0, 0),
        hasStarted: true,
        hasFinished: true,
        isAdmin: false,
        data: {}
    },
    {
        id: 'meeting2',
        title: "Daily standup",
        description: "The update meeting on today's process",
        date: new Date(2021, 1, 21, 12, 30, 0, 0),
        hasStarted: true,
        hasFinished: true,
        isAdmin: false,
        data: {}
    },
    {
        id: 'meeting11',
        title: "Team-wide update",
        description: "The cross-team weekly update meeting",
        date: new Date(2021, 1, 18, 12, 30, 0, 0),
        hasStarted: true,
        hasFinished: true,
        isAdmin: true,
        data: {}
    },
]

export const templates = [
    {
        id: 'template10',
        title: "Daily Standup",
        description: "The cross-team weekly update meeting",
        imageUrl: {coffee},
        data: {}
    },
    {
        id: 'template5',
        title: "Finance Team Meeting",
        description: "An update on product dev",
        imageUrl: {calculator},
        data: {}
    },
    {
        id: 'template9',
        title: "Team-wide update",
        description: "The cross-team weekly update meeting",
        imageUrl: {coffee},
        data: {}
    },
    {
        id: 'template3',
        title: "Daily standup",
        description: "The update meeting on today's process",
        imageUrl: {coffee},
        data: {}
    },
    {
        id: 'template2',
        title: "Daily standup",
        description: "The update meeting on today's process",
        imageUrl: {calculator},
        data: {}
    },
    {
        id: 'template11',
        title: "Team-wide update",
        description: "The cross-team weekly update meeting",
        imageUrl: {calculator},
        data: {}
    },
    {
        id: 'template12',
        title: "Product update",
        description: "An update on product dev",
        imageUrl: {coffee},
        data: {}
    },
]

export const templatesShort = [
    {
        id: 'template10',
        title: "Daily Standup",
        description: "The cross-team weekly update meeting veerryy long description because I need to test it",
        imageUrl: {coffee},
        data: {}
    },
    {
        id: 'template5',
        title: "Finance Team Meeting",
        description: "An update on product dev",
        imageUrl: {calculator},
        data: {}
    },
    {
        id: 'template9',
        title: "Team-wide update",
        description: "The cross-team weekly update meeting",
        imageUrl: {coffee},
        data: {}
    }
]