const initState = {
    commentSendError: null,
  };
  
  const commentSendReducer = (state = initState, action) => {
    switch (action.type) {
      case "SENDING_COMMENT_SUCCESS":
        console.log("Comment successfully sent");
        return {
            ...state,
            commentSendError: null,
        };
      case "SENDING_COMMENT_ERROR":
        return {
          ...state,
          updatesSendError: `Action failed. Error: ${action.err.message}`,
        };
      default:
        return state;
    }
  };
  
  export default commentSendReducer;
  