import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import moment from "moment"
//import styling
import "./UpdateContainer.css";
//import components
import FlatButton from "../../components/buttons/FlatButton";
//import actions
import {
  setAgendaPointsList,
  agendaTitleEdit,
  agendaDescriptionEdit,
  agendaPointsReset,
  agendaDateEdit,
} from "../../actions/newAgendaActions";

import {
  editMeetingIdEdit,
  editMeetingIdReset,
} from "../../actions/editAgendaAction";
import { rememberMeetingDetails } from "../../actions/meetingAccessActions";
//import assets
import editIcon from "../../assets/icons/edit_purple.png";

function EditAgendaContainer(props) {
  const dispatch = useDispatch();
  const history = useHistory();

  const routeChange = () => {
    let path = `edit/${props.meetingID}`;
    history.push(path);
  };

  const fillAgenda = async ({
    meetingTitle,
    meetingDate,
    meetingDescription,
    agendaPoints,
  }) => {
    const formattedDate =  new Date(meetingDate.seconds * 1000)
    // clear agenda
    dispatch(agendaPointsReset());
    dispatch(editMeetingIdReset());
    // edit agenda metadata
    dispatch(agendaTitleEdit(meetingTitle));
    dispatch(agendaDescriptionEdit(meetingDescription));
    dispatch(agendaDateEdit(formattedDate));
    dispatch(editMeetingIdEdit(props.meetingID));
    // add topics
    const formattedAgendaPoints = agendaPoints.map((agendaPoint, index) => {
        return {
            ...agendaPoint,
            timestamp: new Date(parseInt(agendaPoint.timestamp)),
        }
    })
    dispatch(setAgendaPointsList(formattedAgendaPoints));
    dispatch(rememberMeetingDetails(props.meetingID, props.data.pwd))
    routeChange();
  };

  const handleClick = () => {
    console.log(props.data);
    fillAgenda(props.data);
  };

  return (
    <div className="editAgendaContainerDiv">
      <FlatButton
        srcBefore={editIcon}
        txt="Edit agenda"
        handleClick={handleClick}
      />
    </div>
  );
}

EditAgendaContainer.propTypes = {
  meetingID: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
};

export default EditAgendaContainer;
