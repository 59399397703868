//import packages
import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { connect, useSelector, useDispatch } from "react-redux";
import { getFirebase } from "react-redux-firebase";
import FileSaver from "file-saver";
//import styling
import "./UpdateContainer.css";
//import components
import FlatButton from "../../components/buttons/FlatButton";
import Update from "../../components/UpdatePageComponents/Update";
import FilePreview from "../../components/FilePreview/FilePreview";
import ErrorAlert from "../../components/Alert/ErrorAlert";
import UpdateInput from "../../components/UpdatePageComponents/UpdateInput";
//import assets
import plusIcon from "../../assets/icons/plus-purple.png";
// import actions
import {
  topicAdd,
  updateAdd,
  updateModify,
  updateRemove,
  sendNewUpdate,
  updateAnUpdate,
  deleteAnUpdate,
} from "../../actions/updatesActions";
// import utils
import { openInNewTab } from "../../utils/UpdateFilesUtils/UpdateFilesUtils";
import {
  addEditTagToUpdate,
  editUpdatesAdd,
  editUpdateTextModify,
  addDeleteTagToFile,
  removeEditTagFromUpdate,
} from "../../actions/editUpdatesActions";

function UpdateContainer({
  title,
  description,
  duration,
  updates,
  id,
  auth,
  handleFileStateChange,
  files,
  fetchedFiles,
  isReadOnly,
  handleFileDelete,
  meetingID,
  userData
}) {
  const firebase = getFirebase();
  const dispatch = useDispatch();
  const [err, setErr] = useState({
    message: "",
    timestamp: "",
  });
  // updateTimestamps of updates that are in loading mode
  const [uploading, setUploading] = useState([]);
  const newUpdatesState = useSelector((state) => state.updates);
  const topicID = useRef({});
  const timestamp = useRef({});
  // timestamps of updates that the user toggled to edit mode
  const updatesToEdit = useSelector((state) => state.editUpdateTags);
  // and in this state the new edited update objects are stored
  const editedUpdates = useSelector((state) => state.editUpdates);

  const addUpdateToLoadingArray = (timestamp) => {
    setUploading((prev) => {
      return [...prev, timestamp];
    });
  };

  const deleteUpdateFromLoadingArray = (timestamp) => {
    setUploading((prev) => {
      return prev.filter((ts) => {
        return ts !== timestamp;
      });
    });
  };

  const handleAdd = () => {
    if (!newUpdatesState) {
      dispatch(topicAdd(id));
    }

    const topicIsIncludedInState = newUpdatesState.find((obj) => {
      return obj.topicID === id;
    });

    if (!topicIsIncludedInState) {
      dispatch(topicAdd(id));
    }

    const topicIndex = newUpdatesState.findIndex((obj) => {
      return obj.topicID === id;
    });

    dispatch(
      updateAdd({
        uid: auth.uid,
        imgUrl: auth.photoURL ? auth.photoURL : null,
        fullName: auth.displayName ? auth.displayName : userData.fullName,
        topicID: id,
      })
    );
  };

  const handleChange = (val, timestamp) => {
    dispatch(
      updateModify({
        updateText: val,
        topicID: id,
        timestamp,
      })
    );
  };

  const handleSingleUpdateSubmit = async (
    e,
    text,
    timestamp,
    meetingID,
    topicID,
    updateFile
  ) => {
    e.preventDefault();
    if (text === "") {
      console.log("Text is empty");
      return;
    }

    addUpdateToLoadingArray(timestamp);
    try {
      if (updateFile) {
        const { file, topicID, updateTimestamp } = updateFile;

        const path = `${meetingID}/${topicID}/${updateTimestamp}/${file.name}`;
        const storageRef = firebase.storage().ref(path);

        await storageRef.put(file);
      }

      dispatch(sendNewUpdate(timestamp, meetingID, topicID));
      dispatch(updateRemove({ topicID: id, timestamp }));
      deleteUpdateFromLoadingArray(timestamp);
    } catch (err) {
      deleteUpdateFromLoadingArray(timestamp);
      console.log(err.message);
      setErr({
        message: err.message,
        timestamp,
      });
      return;
    }
  };

  const handlePermanentFileDeletion = async (file, timestamp) => {
    try {
      const storageRef = firebase.storage().ref(file.fullPath);

      await storageRef.delete();
    } catch (err) {
      const message = err.message.includes("storage/object-not-found")
        ? "Error 404: The file was not found in the database."
        : err.message;
      setErr({
        message,
        timestamp,
      });
    }
  };

  const handleSingleUpdateDelete = async (timestamp, file) => {
    try {
      addUpdateToLoadingArray(timestamp);
      if (file) {
        if (file.fileName) {
          const storageRef = firebase.storage().ref(file.fullPath);
          await storageRef.delete();
        }
      }

      dispatch(deleteAnUpdate(timestamp, meetingID, id));
      dispatch(removeEditTagFromUpdate(timestamp));
    } catch (err) {
      deleteUpdateFromLoadingArray(timestamp);
      setErr({
        message: err.message,
        timestamp: timestamp,
      });
    }
  };

  const handleSingleUpdateUpdate = async (e, update, oldText) => {
    e.preventDefault();

    console.log('in function!');

    addUpdateToLoadingArray(update.timestamp);

    try {
      console.log('in try section!');
      console.log(files);
      const newFile = files.find((f) => {
        return f.updateTimestamp === update.timestamp;
      });

      if (newFile) {
        console.log('Found a file to upload!');
        const { file, topicID, updateTimestamp } = newFile;

        const path = `${meetingID}/${topicID}/${updateTimestamp}`;
        const storageRef = firebase.storage().ref(path);

        await storageRef.child(`${file.name}`).put(file);
      }

      if (update.updateText === oldText) {
        dispatch(removeEditTagFromUpdate(update.timestamp));
        deleteUpdateFromLoadingArray(update.timestamp);
        return;
      }

      dispatch(updateAnUpdate(update, meetingID, id));
      dispatch(removeEditTagFromUpdate(update.timestamp));
      deleteUpdateFromLoadingArray(update.timestamp);
    } catch (err) {
      deleteUpdateFromLoadingArray(timestamp);
      setErr({
        message: err.message,
        timestamp,
      });
    }
  };

  const handleUpdateTextEdit = (val, timestamp) => {
    dispatch(
      editUpdateTextModify({
        updateText: val,
        timestamp,
      })
    );
  };

  const handleDelete = (timestamp) => {
    // call higher order deleteFile function
    handleFileDelete(id, timestamp);
    // delete update from store
    dispatch(
      updateRemove({
        topicID: id,
        timestamp,
      })
    );
  };

  const handleFileChange = (e, updateTimestamp, topicID) => {
    setErr({
      message: "",
      timestamp: "",
    });

    if (e.target.files.length === 0) {
      console.log("No files found.");
      return;
    }
    const file = e.target.files[0];
    console.log(file);
    const uid = auth.uid;

    // We only accept files up to 1MB in size:
    if (file.size > 1000000) {
      setErr({
        message: "Error: file exceeds the maximum size of 1MB",
        timestamp: updateTimestamp,
      });
      return;
    }

    const fileObj = {
      topicID,
      updateTimestamp,
      userID: uid,
      file,
    };
    handleFileStateChange(fileObj);
  };

  const handleFileSave = (file, updateTimestamp) => {
    setErr({
      message: "",
      timestamp: "",
    });
    try {
      FileSaver.saveAs(file, file.name);
    } catch (err) {
      setErr({
        message: err.message,
        timestamp: updateTimestamp,
      });
    }
  };

  const handleUpdateEditToggle = (update) => {
    dispatch(addEditTagToUpdate({ timestamp: update.timestamp, topicID: id }));
    dispatch(editUpdatesAdd({ ...update, topicID: id, meetingID }));
  };

  const getFileLink = async (fileObj) => {
    setErr({
      message: "",
      timestamp: "",
    });

    try {
      // go the the place within the Firebase storage
      const fileStorageRef = firebase.storage().ref(fileObj.fullPath);

      const downloadLink = await fileStorageRef.getDownloadURL();

      openInNewTab(downloadLink);
    } catch (err) {
      setErr({
        message: err.message,
        timestamp: fileObj.updateTimestamp,
      });
    }
  };

  const getImgUrl = async (fileObj) => {
    setErr({
      message: "",
      timestamp: "",
    });

    try {
      // go the the place within the Firebase storage
      const fileStorageRef = firebase
        .storage()
        .ref(`${meetingID}/${fileObj.topicID}/${fileObj.updateTimestamp}`)
        .child(fileObj.fileName);

      const downloadLink = await fileStorageRef.getDownloadURL();

      return downloadLink;
    } catch (err) {
      setErr({
        message: err.message,
        timestamp: fileObj.updateTimestamp,
      });
    }
  };

  const topicUpdateState = newUpdatesState.find((obj) => {
    return obj.topicID === id;
  });

  return (
    <div className="updateContainer" key={"div1" + id}>
      <h2 className="agendaPointNo">{title}</h2>
      <div key={"div2" + id}>
        <p className="agendaPointDescription">
          {description} · {duration} minutes
        </p>
        {updates.map((update, index) => {
          const isInLoadingMode = uploading.find((ts) => {
            return ts === update.timestamp;
          });
          const fetchedFile = fetchedFiles.find((obj) => {
            return obj.updateTimestamp === update.timestamp;
          });

          const updateFile = fetchedFile
            ? fetchedFile
            : files.find((obj) => {
                return obj.updateTimestamp === update.timestamp;
              });
          const isAbleToEdit = auth.uid === update.userID;
          const isInEditMode = isReadOnly
            ? false
            : updatesToEdit.find((tag) => {
                return tag.timestamp === update.timestamp;
              });
          const editedUpdate = editedUpdates.find((editedUpdate) => {
            return editedUpdate.timestamp === update.timestamp;
          });
          const showEditedUpdate = isInEditMode && editedUpdate && !isReadOnly;
          const showEditedFile = isInEditMode && updateFile && !isReadOnly;
          const comments = update.comments
            ? Object.values(update.comments)
            : [];
          return (
            <div
              className={
                isInEditMode ? "editFetchedUpdateDiv" : "fetchedUpdateDiv"
              }
              key={`fetchedUpdateDiv${index.toString()}`}
            >
              {!isInEditMode && (
                <Update
                  imgUrl={update.imgUrl}
                  key={update.timestamp}
                  firstName={update.firstName}
                  updateText={update.updateText}
                  editEnabled={isAbleToEdit}
                  handleToggle={() => handleUpdateEditToggle(update)}
                  updateFile={updateFile}
                  getFileLink={getFileLink}
                  getImgUrl={getImgUrl}
                  handleFileSave={handleFileSave}
                  timestamp={update.timestamp}
                  fullName={update.fullName}
                  auth={auth}
                  topicID={id}
                  meetingID={meetingID}
                  comments={comments}
                  isMyUpdate={update.userID === auth.uid}
                  isReadOnly={isReadOnly}
                  userData={userData}
                />
              )}
              {showEditedUpdate && (
                <UpdateInput
                  key={`input-${update.timestamp}-${index.toString()}`}
                  val={editedUpdate.updateText}
                  handleChange={(val) =>
                    handleUpdateTextEdit(val, update.timestamp)
                  }
                  editedUpdateIsTheSame={
                    update.updateText === editedUpdate.updateText
                  }
                  handleSubmit={(e) =>
                    handleSingleUpdateUpdate(e, editedUpdate, update.updateText)
                  }
                  placeHolder={
                    updateFile
                      ? "Type a description of your file here"
                      : "Type your update here"
                  }
                  handleDelete={() =>
                    handleSingleUpdateDelete(update.timestamp, updateFile)
                  }
                  isLoading={isInLoadingMode}
                  key={`button-${update.timestamp}-${index.toString()}`}
                  id={`${update.timestamp}-${index.toString()}`}
                  disabled={updateFile ? true : false}
                  handleFileChange={(e) =>
                    handleFileChange(e, update.timestamp, id)
                  }
                />
              )}
              {showEditedFile && (
                <FilePreview
                  key={updateFile.fullPath}
                  extension={
                    updateFile.fileName
                      ? updateFile.fileName.split(".").pop()
                      : updateFile.file.name.split(".").pop()
                  }
                  fileName={
                    updateFile.fileName
                      ? updateFile.fileName
                      : updateFile.file.name
                  }
                  handleClick={
                    updateFile.fileName
                      ? () => getFileLink(updateFile)
                      : () => handleFileSave(updateFile.file, update.timestamp)
                  }
                  handleDelete={
                    updateFile.fileName
                      ? () => {
                          handlePermanentFileDeletion(
                            updateFile,
                            update.timestamp
                          );
                          dispatch(
                            addDeleteTagToFile({
                              timestamp: update.timestamp,
                              fullPath: updateFile.fullPath,
                            })
                          );
                        }
                      : () => handleFileDelete(id, update.timestamp)
                  }
                />
              )}
              {err.timestamp === update.timestamp && (
                <ErrorAlert
                  error={err.message}
                  close={() => {
                    setErr({
                      message: "",
                      timestamp: "",
                    });
                  }}
                />
              )}
            </div>
          );
        })}
        {topicUpdateState &&
          topicUpdateState.updates.map((update, index) => {
            topicID.current[index] = id;
            timestamp.current[index] = update.timestamp;
            const updateFile = files.find((file) => {
              return file.updateTimestamp === update.timestamp;
            });
            const showUpdateFile = updateFile && !isReadOnly;
            const isInLoadingMode = uploading.find((ts) => {
              return ts === update.timestamp;
            });
            return (
              <div key={"div3" + id + index.toString()}>
                {!isReadOnly && (
                  <UpdateInput
                    key={`input-${update.timestamp}-${index.toString()}`}
                    val={update.updateText}
                    isLoading={isInLoadingMode}
                    handleChange={(val) => handleChange(val, update.timestamp)}
                    handleSubmit={(e) =>
                      handleSingleUpdateSubmit(
                        e,
                        update.updateText,
                        update.timestamp,
                        meetingID,
                        id,
                        updateFile
                      )
                    }
                    placeHolder={
                      updateFile
                        ? "Type a description of your file here"
                        : "Type your update here"
                    }
                    handleDelete={() => handleDelete(update.timestamp)}
                    key={`button-${update.timestamp}-${index.toString()}`}
                    id={`${update.timestamp}-${index.toString()}`}
                    disabled={updateFile ? true : false}
                    handleFileChange={(e) =>
                      handleFileChange(e, update.timestamp, id)
                    }
                  />
                )}
                {showUpdateFile && (
                  <FilePreview
                    key={`fileButton-${update.timestamp}-${index.toString()}`}
                    extension={updateFile.file.name.split(".").pop()}
                    fileName={updateFile.file.name}
                    handleClick={() =>
                      handleFileSave(updateFile.file, update.timestamp)
                    }
                    handleDelete={() => handleFileDelete(id, update.timestamp)}
                  />
                )}
                {err.timestamp === update.timestamp && (
                  <ErrorAlert
                    error={err.message}
                    close={() => {
                      setErr({
                        message: "",
                        timestamp: "",
                      });
                    }}
                  />
                )}
              </div>
            );
          })}
        {!isReadOnly && (
          <FlatButton
            key={`addupdate${id}`}
            txt="Add update"
            srcBefore={plusIcon}
            handleClick={handleAdd}
          />
        )}
      </div>
    </div>
  );
}

UpdateContainer.propTypes = {
  // the title of the agendaPoint
  title: PropTypes.string.isRequired,
  // the description of the agendaPoint
  description: PropTypes.string.isRequired,
  // the duration of the agendaPoint
  duration: PropTypes.string.isRequired,
  // the updates, array of objects
  updates: PropTypes.array.isRequired,
  // function to add or change a file
  handleFileStateChange: PropTypes.func.isRequired,
  // function to delete a file
  handleFileDelete: PropTypes.func.isRequired,
  // the files for the topic that belong to the updates (files that arent submitted yet)
  files: PropTypes.array.isRequired,
  // the files for the topic that belong to the updates (loaded from Firebase Storage)
  fetchedFiles: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => {
  return {
    auth: state.firebaseReducer.auth,
  };
};

export default connect(mapStateToProps)(UpdateContainer);
