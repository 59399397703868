import React from "react";
import PropTypes from "prop-types";
import MuiAvatar from "@material-ui/core/Avatar";
import { makeStyles } from "@material-ui/core/styles";
//import styling
import "./Avatar.css";
//import utils
import { generateInitials } from "../../utils/avatarUtils";
//import colors
import { deepOrange } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  letterAvatar: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
    width: 50,
    height: 50,
    marginLeft: "10px",
  },
}));

function Avatar(props) {
  const classes = useStyles();
  const style = {
    width: "50px",
    height: "50px",
  };

  if (props.src) {
    return (
      <div onClick={props.handleClick}>
        <img
          id="avatar"
          src={props.src}
          draggable="false"
          style={props.isNavItem ? style : {}}
        />
        {props.open && props.children}
      </div>
    );
  } else {
    const initials = generateInitials(props.fullName);
    return (
      <div onClick={props.handleClick}>
        <MuiAvatar className={classes.letterAvatar}>
          {initials}
        </MuiAvatar>
        {props.open && props.children}
      </div>
    );
  }
}

Avatar.propTypes = {
  fullName: PropTypes.string.isRequired,
  src: PropTypes.string,
  handleClick: PropTypes.func,
  // if open is set to true, the children will appear
  open: PropTypes.bool,
  // setting this to true will make the avatar a little larger (handy for the navbar Avatar)
  isNavItem: PropTypes.bool,
};

export default Avatar;
