const initState = {
    meetingID: "",
    pwd: ""
  };
  
  const rememberMeetingDetailsReducer = (state = initState, action) => {
    switch (action.type) {
      case "REMEMBER_MEETING_DETAILS":
        return action.payload;
      case "RESET_REMEMBER_MEETING_DETAILS":
        return initState;
      default:
        return state;
    }
  };
  
  export default rememberMeetingDetailsReducer;
  