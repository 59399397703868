export const joiningUsers = [
    {
        id: "user1",
        fullName: "Robin Salimans",
        imgUrl: "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixid=MXwxMjA3fDB8MHxzZWFyY2h8MXx8YXZhdGFyfGVufDB8fDB8&ixlib=rb-1.2.1&auto=format&fit=crop&w=900&q=60",
    },
    {
        id: "user2",
        fullName: "Petra Rudisarova",
        imgUrl: "https://images.unsplash.com/photo-1607746882042-944635dfe10e?ixid=MXwxMjA3fDB8MHxzZWFyY2h8NXx8YXZhdGFyfGVufDB8fDB8&ixlib=rb-1.2.1&auto=format&fit=crop&w=900&q=60",
    },
    {
        id: "user3",
        fullName: "Dion Salimans",
        imgUrl: "https://images.unsplash.com/photo-1528763380143-65b3ac89a3ff?ixid=MXwxMjA3fDB8MHxzZWFyY2h8Mjl8fGF2YXRhcnxlbnwwfHwwfA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=900&q=60",
    },
    {
        id: "user4",
        fullName: "Barack Obama",
        imgUrl: null,
    },
    {
        id: "user5",
        fullName: "Joseph Robinette Biden",
        imgUrl: null,
    },
    {
        id: "user6",
        fullName: "Ronan Mark Liedmeier",
        imgUrl: null,
    },
    {
        id: "user7",
        fullName: "Ramses Kamanda",
        imgUrl: null,
    }
];

export const updates = [
    {
        userId: "user1",
        update: "Finished the first version of Prompt’s design",
        isNew: true,
    },
    {
        userId: "user2",
        update: "Here is another update that belongs to design which is a little bit longer, Lorem ipsum dolor sit amet because some people can’t stop talking",
        isNew: false,
    },
    {
        userId: "user3",
        update: "Worked on the Sanity CMS system and set up most of it",
        isNew: false,
    },
    {
        userId: "user4",
        update: "I updated certain components in the Prompt design to make it more consistent across different pages",
        isNew: true,
    },
    {
        userId: "user5",
        update: "Quick question: are we able to add something to the design? I heard from a customer that they would like a commenting feature.",
        isNew: false,
    },
]