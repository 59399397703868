export const addMeetingAccess = (meetingID) => {
  return {
    type: "SET_MEETING_ACCESS",
    payload: meetingID,
  };
};

export const rememberMeetingDetails = (meetingID, pwd) => {
  return {
    type: "REMEMBER_MEETING_DETAILS",
    payload: {
      meetingID,
      pwd,
    },
  };
};

export const resetRememberedMeetingDetails = () => {
  return {
    type: "RESET_REMEMBER_MEETING_DETAILS",
  };
};
