import React from 'react';
import './TemplateTile.css';
import PropTypes from 'prop-types';

class TemplateTile extends React.Component {

    render() {
        return (
            <div className="templateTile" onClick={this.props.handleClick}>
                <div>
                    <img src={this.props.src} alt=""/>
                </div>
                {this.props.title ? <h3>{this.props.title}</h3> : <h3>Daily Standup</h3>}
                {this.props.description && <p id="templateDescription">{this.props.description}</p>}
            </div>
        );
    }
}

TemplateTile.propTypes = {
    // the title
    title: PropTypes.string.isRequired,
    // the description
    description: PropTypes.string.isRequired,
    // the onClick function -- enter null if nothing
    handleClick: PropTypes.func.isRequired,
    // the link to the Template banner
    src: PropTypes.string.isRequired,
}

export default TemplateTile;