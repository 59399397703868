import React from 'react';
import LottieAnimation from '../../components/Lottie/LottieAnimation';
import loadingAnimation from '../../assets/animations/loading.json';
import './LoadingScreen.css';

function LoadingScreen() {
    return (
        <div className="fullScreenAllCenter">
            <LottieAnimation lotti={loadingAnimation} height={150} width={150}/>
        </div>
    );
}

export default LoadingScreen;