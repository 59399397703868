// these actions are solely to enable the user to edit and
// update an agenda of a meeting that already exists

export const editMeetingIdEdit = (editMeetingId) => {
  return {
    type: "EDITMEETINGID_EDIT",
    payload: editMeetingId,
  };
};

export const editMeetingIdReset = () => {
  return {
    type: "EDITMEETINGID_RESET",
  };
};

//expects a meetingTitle, meetingDescription, meetingDate, and agendaPoints
export const sendMeetingChanges = (obj) => {
  return async (dispatch, getState, { getFirestore }) => {
    try {
      const firestore = getFirestore();
      const meetingID = getState().editMeetingId
      await firestore.collection("pendingAgendaChanges").doc(meetingID).set({
        meetingTitle: obj.meetingTitle,
        meetingDescription: obj.meetingDescription
          ? obj.meetingDescription
          : "Make sure to enter your updates before the meeting!",
        meetingDate: obj.meetingDate,
        agendaPoints: obj.agendaPoints
          .map((point) => {
            return {
              title: point.title,
              description: point.description,
              duration: point.duration,
              key: point.key,
              timestamp: point.timestamp.getTime().toString(),
            };
          })
          .filter((point) => {
            return point.title !== "";
          }),
        requestingUserID: getState().firebaseReducer.auth.uid,
      });
      dispatch({ type: "AGENDA_CREATE_SUCCESS", meetingID });
    } catch (err) {
      console.log(err);
    }
  };
};
