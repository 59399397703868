import React, { useState } from "react";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { red } from "@material-ui/core/colors";
//import components
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
//import icons
import SettingsIcon from "@material-ui/icons/Settings";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import CreateIcon from "@material-ui/icons/Create";
import HomeIcon from "@material-ui/icons/Home";
import BugReportIcon from "@material-ui/icons/BugReport";
import RateReviewIcon from "@material-ui/icons/RateReview";
import VideoLibraryIcon from "@material-ui/icons/VideoLibrary";
//import actions
import { signOut } from "../../../actions/authActions";
import { createStripeCheckout } from "../../../actions/paymentsActions";

const useStyles = makeStyles({
  list: {
    width: 250,
    fontFamily: "Epilogue",
  },
  fullList: {
    width: "auto",
  },
});

function TemporaryDrawer(props) {
  const history = useHistory();

  const routeToSettings = () => {
    let path = "/settings";
    history.push(path);
  };

  const classes = useStyles();
  const location = useLocation();
  const path = location.pathname;

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      onClick={props.handleClose}
      onKeyDown={props.handleClose}
    >
      <List>
        {["Dashboard", "New meeting"].map((text, index) => {
          let icon;
          if (text === "New meeting") {
            icon = <CreateIcon />;
          } else if (text === "Dashboard") {
            icon = <HomeIcon />;
          }

          let show = true;
          if (text === "New meeting" && path === "/create") {
            show = false;
          } else if (text === "Dashboard" && path === "/") {
            show = false;
          }

          const handleClick = () => {
            if (text === "New meeting") {
              props.routeHome();
              props.routeToCreator();
            } else if (text === "Dashboard") {
              props.routeHome();
            }
          };

          return (
            <div>
              {show && (
                <ListItem button key={text} onClick={handleClick}>
                  <ListItemIcon>{icon}</ListItemIcon>
                  <ListItemText primary={text} />
                </ListItem>
              )}
            </div>
          );
        })}
      </List>
      <Divider />
      <List>
        {["Request feature", "Report bug", "Video guide"].map((text, index) => {
          let icon;
          if (text === "Request feature") {
            icon = <RateReviewIcon />;
          } else if (text === "Report bug") {
            icon = <BugReportIcon />;
          } else if (text === "Video guide") {
            icon = <VideoLibraryIcon />;
          }

          const handleClick = () => {
            let url;
            if (text === "Request feature") {
              url = "https://y6owmmpa1i1.typeform.com/to/BVKeJE24";
            } else if (text === "Report bug") {
              url = "https://y6owmmpa1i1.typeform.com/to/z4Iy0pFC";
            }

            window.open(url, "_blank").focus();
          };

          return (
            <div>
              <ListItem
                button
                key={text}
                onClick={text === "Video guide" ? () => {history.push('/video-guide')} : handleClick}
              >
                <ListItemIcon>{icon}</ListItemIcon>
                <ListItemText primary={text} />
              </ListItem>
            </div>
          );
        })}
      </List>
      <Divider />
      <List>
        {["Buy Prompt", "Settings"].map((text, index) => {
          let icon;
          if (text === "Buy Prompt") {
            icon = <CreditCardIcon />;
          } else if (text === "Settings") {
            icon = <SettingsIcon />;
          }

          let show = true;
          if (text === "Manage subscription" && !props.hasSubscription) {
            show = false;
          } else if (text === "Buy Prompt" && props.hasSubscription) {
            show = false;
          }

          const handleClick = () => {
            props.createStripeCheckout();
          };

          return (
            <div>
              {show && (
                <ListItem
                  button
                  key={text}
                  onClick={text === "Settings" ? routeToSettings : handleClick}
                >
                  <ListItemIcon>{icon}</ListItemIcon>
                  <ListItemText primary={text} />
                </ListItem>
              )}
            </div>
          );
        })}
      </List>
      <Divider />
      <List>
        {["Log out"].map((text, index) => {
          let icon;
          if (text === "Log out") {
            icon = <ExitToAppIcon style={{ color: red[500] }} />;
          }
          return (
            <ListItem button key={text} onClick={props.signOut}>
              <ListItemIcon>{icon}</ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          );
        })}
      </List>
    </div>
  );

  return (
    <div>
      <Drawer anchor="left" open={props.open} onClose={props.handleClose}>
        {list("left")}
      </Drawer>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    signOut: () => dispatch(signOut()),
    createStripeCheckout: () => dispatch(createStripeCheckout()),
  };
};

export default connect(null, mapDispatchToProps)(TemporaryDrawer);
