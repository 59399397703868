export const breakDownPath = (path) => {
    const pathArr = path.split('/');
    return {
        meetingID: pathArr[0],
        topicID: pathArr[1],
        updateTimestamp: pathArr[2],
    }
}

export const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }