import React, { useState } from "react";
import { connect } from "react-redux";
//import components
import FullButton from "../../../components/buttons/FullButton";
//import styling
import "./BillingSettings.css";
import "../SettingsContentContainer.css";
//import assets
import externalLinkIcon from "../../../assets/icons/external_link.png";
//import actions
//import actions
import {
  createStripeCheckout,
  createPortalSession,
} from "../../../actions/paymentsActions";

function BillingSettings(props) {
  const [buttonLoading, setButtonLoading] = useState(false);

  const handleClick = () => {
    setButtonLoading(true);
    if (props.hasSubscription) {
      props.createPortalSession();
    } else {
      props.createStripeCheckout();
    }
  };
  return (
    <div className="settingsTab">
      <div id="billing">
        <h2>Billing</h2>
        {props.hasSubscription && (
          <p>
            Prompt partners with Stripe for simplified billing. Click the button
            below to manage your billing settings.
          </p>
        )}
        {!props.hasSubscription && (
          <p>
            It looks like you don't have a Prompt subscription at this moment.
            Click the button below to buy one!
          </p>
        )}
        <FullButton
          srcBefore={externalLinkIcon}
          txt={props.hasSubscription ? "Manage billing" : "Buy Prompt"}
          handleClick={handleClick}
          loading={buttonLoading}
        />
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    createStripeCheckout: () => dispatch(createStripeCheckout()),
    createPortalSession: () => dispatch(createPortalSession()),
  };
};

export default connect(null, mapDispatchToProps)(BillingSettings);
