import React from "react";
import { useHistory } from "react-router-dom";
import "./NoMeetingsTile.css";
import LightButton from "../buttons/LightButton";

import paperPlaneImg from "../../assets/other/paperplane.png";

function NoMeetingsTile() {
  const history = useHistory();

  const routeChange = () => {
    let path = `create`;
    history.push(path);
  };
  return (
    <div className="noMeetingsTile">
      <div id="leftDiv">
        <p id="noMeetingsParagraph">
          It looks like you don't have any meetings yet.
        </p>
        <LightButton txt="Create meeting" handleClick={routeChange} />
      </div>
      <div id="paperPlane">
        <img src={paperPlaneImg} />
      </div>
    </div>
  );
}

export default NoMeetingsTile;
