import { returnFirstName } from "../../utils/MeetingUtils/meetingUtils";

const initialState = [];

const commentReducer = (state = initialState, action) => {
  switch (action.type) {
    // expects a userID, displayName, and updateTimestamp
    case "COMMENT_ADD":
      return [
        ...state,
        {
          userID: action.payload.userID,
          comment: "",
          firstName: returnFirstName(action.payload.displayName),
          commentTimestamp: Date.now().toString(),
          updateTimestamp: action.payload.updateTimestamp,
        },
      ];
    case "COMMENT_MODIFY":
      // expects an updateTimestamp and a comment
      return state.map((commentObj, index) => {
        if (commentObj.updateTimestamp === action.payload.updateTimestamp) {
          return {
            ...commentObj,
            comment: action.payload.comment,
          };
        } else {
          return commentObj;
        }
      });
    case "COMMENT_REMOVE":
      // expects a commentTimestamp
      return state.filter((c) => {
        return c.commentTimestamp !== action.payload;
      });
    case "COMMENTS_RESET":
      return initialState;
    default:
      return state;
  }
};

export default commentReducer;
