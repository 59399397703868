import React, { useState } from "react";
//import styling
import "./FixedToolBar.css";
//import components
import IconButton from "../../buttons/IconButton";
import MeetingInputField from "../../Input/MeetingInputField";
//import assets
import newFile from "../../../assets/icons/file_new.png";
import arrowUp from "../../../assets/icons/short_up.png";

function ToolBarInputField(props) {
  const [comment, setComment] = useState("");
  const handleChange = (val) => {
    setComment(val);
  };

  return (
    <div id="toolBarInputField">
      <IconButton src={newFile} />
      <MeetingInputField
        placeHolder="Write any last-minute updates, questions, or points here..."
        handleChange={handleChange}
        val={comment}
      />
    </div>
  );
}

export default ToolBarInputField;
