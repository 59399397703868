import React from "react";
import { useHistory, Redirect } from "react-router-dom";
import { connect } from "react-redux";
//import components
import NavBar from "../../components/NavBar/NavBar";
import Footer from "../../components/Footer/Footer";
import ForgotPassword from "../../containers/Login/ForgotPasswordContainer";
//import styling
import "./LoginPage.css";

function LoginPage({ auth }) {
  const history = useHistory();

  const routeChange = () => {
    let path = `signup`;
    history.push(path);
  };

  //redirect user if they're not logged in
  if(auth.uid) return <Redirect to='/' />

  return (
    <div className="loginPage">
      <NavBar />
      <div id="loginBlock">
        <ForgotPassword />
        <p id="dontHaveAccountYet">
          Don't have an account yet? <a onClick={routeChange}>Sign up</a>
        </p>
      </div>
      <Footer />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebaseReducer.auth,
  }
}  

export default connect(mapStateToProps)(LoginPage);
