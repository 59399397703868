import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import "./FloatingMeetingSummary.css";
//import components
import FullButton from "../../components/buttons/FullButton";
import FlatButton from "../../components/buttons/FlatButton";
import MeetingFact from "../../components/MeetingFact/MeetingFact";
import LinkDiv from "../../components/LinkDiv/LinkDiv";
import SuccessSnackbar from "../../components/Snackbar/SuccessSnackbar";
//import assets
import clockIcon from "../../assets/icons/clock.png";
import topicIcon from "../../assets/icons/user_voice.png";
import refreshIcon from "../../assets/icons/refresh.png";
import refreshIconDisabled from "../../assets/icons/refresh_disabled.png";
//import actions
import { resetUpdates } from "../../actions/updatesActions";
import { editMeetingIdReset } from "../../actions/editAgendaAction";
import {
  deleteFileTagsReset,
  editUpdatesReset,
  editUpdateTagsReset,
} from "../../actions/editUpdatesActions";

function FloatingMeetingSummary(props) {
  const agendaList = useSelector((state) => state.newAgenda);
  const meetingToEditID = useSelector((state) => state.editMeetingId);
  const agendaListIsEmpty =
    agendaList.length === 1 && agendaList[0].title === "";

  const history = useHistory();
  const dispatch = useDispatch();
  const [copied, setCopied] = useState(false);

  const routeBackToAgendaEdit = () => {
    let path = `/agenda/${meetingToEditID}`;
    history.push(path);
  };

  const handleCancel = () => {
    routeBackToAgendaEdit();
    dispatch(editMeetingIdReset());
  };

  const handleResetUpdates = () => {
    dispatch(resetUpdates());
    dispatch(deleteFileTagsReset());
    dispatch(editUpdatesReset());
    dispatch(editUpdateTagsReset());
  };

  const getFullButtonText = () => {
    if (props.isForUpdates) {
      return "Make read-only";
    } else if (meetingToEditID) {
      return "Confirm changes";
    } else {
      return "Create meeting";
    }
  };

  const getFlatButtonValues = () => {
    if (props.isForUpdates) {
      return {
        srcBefore: refreshIcon,
        srcBeforeDisabled: refreshIconDisabled,
        txt: "Reset",
        handleClick: handleResetUpdates,
        disabled: props.buttonDisabled,
      };
    } else if (meetingToEditID) {
      return {
        srcBefore: null,
        srcBeforeDisabled: null,
        txt: "Cancel",
        handleClick: handleCancel,
        disabled: false,
      };
    } else {
      return {
        srcBefore: refreshIcon,
        srcBeforeDisabled: refreshIconDisabled,
        txt: "Reset",
        handleClick: props.handleReset,
        disabled: agendaListIsEmpty,
      };
    }
  };

  const calculateMeetingTime = () => {
    if (agendaList.length === 0) {
      return 0;
    }

    const meetingTimesArray = agendaList.map((obj) => {
      if (obj.title !== "" && obj.duration !== "") {
        return obj.duration;
      } else {
        return 0;
      }
    });

    return meetingTimesArray.reduce((curr, prev) => curr + prev);
  };

  let meetingTime;
  if (props.isForUpdates) {
    meetingTime = props.meetingDuration;
  } else {
    meetingTime = calculateMeetingTime();
  }

  const calculateTopicNumber = () => {
    let topicNumber = 0;

    agendaList.forEach((topic) => {
      let isEmpty = topic.title === "" && topic.description === "";

      if (!isEmpty) {
        topicNumber++;
      }
    });

    return topicNumber;
  };

  let topicNumber;
  if (props.isForUpdates) {
    topicNumber = props.meetingLength;
  } else {
    topicNumber = calculateTopicNumber();
  }

  const returnShareLink = () => {
    const mId = props.meetingID || meetingToEditID;
    const shareLink =
      "https://app.getprompt.io/j/" + mId + "/" + props.pwd;
    return shareLink;
  };

  const showShareLink = props.isForUpdates || meetingToEditID;
  const link = returnShareLink();

  const handleSnackbarClose = (e, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setCopied(false);
  };

  const dontShowButtons = !props.isAdmin && props.isForUpdates;

  return (
    <div className="meetingSummaryDiv">
      <div className="meetingSummary">
        <h3>Meeting Summary</h3>
        <MeetingFact
          fact={
            meetingTime !== 1
              ? `${meetingTime} minutes`
              : `${meetingTime} minute`
          }
          title="Duration"
          src={clockIcon}
        />
        <MeetingFact
          fact={
            topicNumber !== 1 ? `${topicNumber} topics` : `${topicNumber} topic`
          }
          title="Length"
          src={topicIcon}
        />
      </div>
      <div className="shareDiv">
        <h3>Share</h3>
        <p>
          {showShareLink
            ? "Everybody with the link can enter updates and view the entire agenda."
            : "You can share your agenda with others after creating it."}
        </p>
        {showShareLink && (
          <div className="linkAndButtonDiv">
            <LinkDiv link={link} />
            <FlatButton
              txt="Copy"
              disabled={!props.pwd}
              handleClick={() => {
                navigator.clipboard
                  .writeText(link)
                  .catch((err) => console.log(err));
                setCopied(true);
              }}
            />
            <SuccessSnackbar
              msg="Link copied!"
              open={copied}
              handleClose={handleSnackbarClose}
            />
          </div>
        )}

        {!showShareLink && (
          <div className="linkAndButtonDiv">
            <LinkDiv link={`Your sharing link will appear here`} />
            <FlatButton txt="Copy" disabled={true} handleClick={() => {}} />
          </div>
        )}
      </div>
      <br />
      {!dontShowButtons && <div className="buttonDiv">
        {!props.isForUpdates && <FlatButton
          srcBefore={getFlatButtonValues().srcBefore}
          srcBeforeDisabled={getFlatButtonValues().srcBeforeDisabled}
          txt={getFlatButtonValues().txt}
          handleClick={getFlatButtonValues().handleClick}
          disabled={getFlatButtonValues().disabled}
        />}
        <FullButton
          txt={getFullButtonText()}
          handleClick={props.handleSubmit}
          disabled={
            props.isForUpdates ? props.isFinished : agendaListIsEmpty
          }
        />
      </div>}
    </div>
  );
}

FloatingMeetingSummary.propTypes = {
  // this boolean determines whether the functionality is for creating a meeting or for
  // adding updates
  isForUpdates: PropTypes.bool,
  // in case isForUpdates is true, we need to supply the durations and length of the meeting
  meetingDuration: PropTypes.number,
  meetingLength: PropTypes.number,
  // disable button
  buttonDisabled: PropTypes.bool,
};

export default FloatingMeetingSummary;
